import sessionStorage from '../utils/sessionStorage'
import IUser from './../models/user'
const { getObject, setObject, removeItem } = sessionStorage

const states = {
  User:
    Object.keys(getObject('user')).length === 0
      ? ({ role: {} } as IUser)
      : (getObject('user') as IUser)
}

const User = (state = states.User, action) => {
  switch (action.type) {
    case 'LOGIN':
      setObject('user', action.data)
      return action.data
    case 'LOGOUT':
      removeItem('user')
      return {
        role: {}
      }
    default:
      return state
  }
}

export default {
  User
}
