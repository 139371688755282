import React, { PureComponent } from 'react'
import { Form as AntdForm, Input, Select, Switch } from 'antd'
import { FormComponentProps } from 'antd/es/form'
import api from './../../api'
import styles from './style.module.less'
import ICheckout from './../../models/checkout'
import IUser from '../../models/user'

interface IReciveProps extends FormComponentProps {
  onRef: (form: Form) => void
  complete: (checkout: ICheckout) => void
  validateFail: () => void
  data: ICheckout
  user: IUser
}

class Form extends PureComponent<IReciveProps, any> {
  submit = () => {
    const { user } = this.props
    this.props.form.validateFields((err: any, values: any) => {
      if (err) {
        this.props.validateFail()
      } else {
        api.checkout
          .createOrUpdate({ ...values, user })
          .then(res => {
            if (res.status === 200) {
              this.props.complete(res.data)
            }
          })
          .catch(() => {
            this.props.validateFail()
          })
      }
    })
  }
  componentDidMount() {
    this.props.onRef(this)
  }
  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <AntdForm
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        labelAlign="right"
        className={styles.form}
      >
        <AntdForm.Item label="" className={styles.displayNone}>
          {getFieldDecorator('id', {
            rules: [{ required: false, message: '' }],
            initialValue: this.props.data.id
          })(<Input placeholder="" />)}
        </AntdForm.Item>
        <AntdForm.Item label="结账金额" className={styles.displayNormal}>
          {getFieldDecorator('money', {
            rules: [{ required: true, message: '结账金额' }],
            initialValue: this.props.data.money
          })(<Input placeholder="结账金额" />)}
        </AntdForm.Item>
        {/* <AntdForm.Item label="" className={styles.displayNormal}>
          {getFieldDecorator('userId', {
            rules: [{ required: false, message: '' }],
            initialValue: this.props.data.userId
          })(
            <Select placeholder="">
              {this.state.userList.map((item, index) => {
                return (
                  <Select.Option value={item.id} key={index}>
                    {item.name}
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </AntdForm.Item> */}
      </AntdForm>
    )
  }
}

export default AntdForm.create<IReciveProps>()(Form)
