import React from 'react'
import ReactDOM from 'react-dom'
import { ConfigProvider } from 'antd'
import { Provider } from 'react-redux'
import zhCN from 'antd/es/locale/zh_CN'
import App from './App'
import './assets/stylesheets/reset.less'
import LoginPage from './pages/auth/login'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import { createStore } from 'redux'
import reducers from './reducers'
import Print from './pages/express/print'
import YG from './pages/express/yg'
import DesignPage from './pages/utils/design'
import BankReceiptPrint from './pages/utils/receipt/print'

const store = createStore(reducers)

ReactDOM.render(
  <ConfigProvider locale={zhCN}>
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/print/:id/:childId?" component={Print} />
          <Route exact path="/yg" component={YG} />
          <Route exact path="/design" component={DesignPage} />
          <Route exact path="/login" component={LoginPage} />
          <Route
            exact
            path="/util/receipt/print"
            component={BankReceiptPrint}
          />
          <Route path="/" component={App} />
        </Switch>
      </Router>
    </Provider>
  </ConfigProvider>,
  document.getElementById('root')
)
