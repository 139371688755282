import { Backend, IApi } from './backend'
import { AxiosResponse } from 'axios'
import { IRequest } from './index'
import IUser from './../models/user'
import ISessionStorage from './../models/sessionStore'

export default class Auth extends Backend {
  static login(
    data: any
  ): Promise<AxiosResponse<{ user: IUser; session: ISessionStorage }>> {
    const api: IApi = {
      url: '/api/auth/login',
      method: 'post',
      data
    }
    return this.request<{ user: IUser; session: ISessionStorage }>(api)
  }
}
