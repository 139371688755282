import { Backend, IApi } from './backend'
import { AxiosResponse } from 'axios'
import { IRequest } from './index'
import ICompany from './../models/company'

export default class Company extends Backend {
  static getTableData(request: IRequest): Promise<AxiosResponse<{ rows: ICompany[], count: number }>> {
    const api: IApi = {
      url: '/api/company/tableData',
      method: 'get',
      data: { request }
    }
    return this.request<{ rows: ICompany[], count: number }>(api)
  }
  static createOrUpdate(data: any): Promise<AxiosResponse<ICompany>> {
    const api: IApi = {
      url: '/api/company/createOrUpdate',
      method: 'post',
      data
    }
    return this.request<ICompany>(api)
  }
  static delete(id: number): Promise<AxiosResponse> {
    const api: IApi = {
      url: `/api/company/${id}`,
      method: 'delete'
    }
    return this.request(api)
  }
}
