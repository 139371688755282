import React, { PureComponent } from 'react'
import {
  Table,
  Divider,
  Form,
  Input,
  Col,
  Button,
  message,
  Select,
  Row,
  DatePicker
} from 'antd'
import numeral from 'numeral'
import moment from 'moment'
import DataModal from './modal'
import ImageView from './../../components/imageView'
import api from './../../api'
import styles from './style.module.less'
import IInInvoice from './../../models/inInvoice'
import ICompany from './../../models/company'
import IUser from './../../models/user'

class InvoicePage extends PureComponent {
  state = {
    loading: true,
    list: [],
    form: {
      pageSize: 15,
      page: 1,
      companyId: 0,
      startTime: moment()
        .startOf('month')
        .toDate(),
      endTime: moment()
        .endOf('month')
        .toDate()
    },
    total: 0,
    modalOpen: false,
    modalTitle: '',
    updateItem: {} as IInInvoice,
    companyList: [] as ICompany[],
    customerList: [] as IUser[],
    totalSum: 0,
    taxSum: 0
  }
  columns = [
    {
      title: '进项公司',
      key: 'company',
      align: 'center' as 'center',
      render(text, item: IInInvoice) {
        return <div>{item.company.name}</div>
      }
    },
    {
      title: '开票信息',
      key: 'titleInfo',
      align: 'center' as 'center',
      render(text, item: IInInvoice) {
        return (
          <div>
            <p>{item.buyer}</p>
            <p>{item.buyerNum}</p>
            <p>{moment(item.publishDate).format('YYYY-MM-DD')}</p>
          </div>
        )
      }
    },
    {
      title: '金额信息',
      key: 'money',
      align: 'center' as 'center',
      render(text, item: IInInvoice) {
        return (
          <div>
            <p>金额：{item.amount}</p>
            <p>税金：{item.tax}</p>
            <p>合计：{item.totalAmount}</p>
          </div>
        )
      }
    },
    {
      title: '发票信息',
      key: 'invoiceInfo',
      align: 'center' as 'center',
      render(text, item: IInInvoice) {
        return (
          <div>
            <p>代码：{item.code}</p>
            <p>号码：{item.num}</p>
          </div>
        )
      }
    },
    {
      title: '图片',
      key: 'pic',
      align: 'center' as 'center',
      render(text, item: IInInvoice) {
        if (!!item.pic) {
          return (
            <Button
              type="primary"
              icon="file-image"
              onClick={() => {
                ImageView.show([{ src: item.pic, title: item.buyer }])
              }}
            >
              查看图片
            </Button>
          )
        } else {
          return <span>无图片</span>
        }
      }
    },
    {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark',
      align: 'center' as 'center'
    },
    {
      title: '操作',
      key: 'action',
      align: 'center' as 'center',
      width: 200,
      render: (text: any, record: any) => (
        <span>
          <a
            onClick={() => {
              this.update(record)
            }}
          >
            编辑
          </a>
          <Divider type="vertical" />
          <a
            onClick={() => {
              this.delete(record)
            }}
          >
            删除
          </a>
        </span>
      )
    }
  ]
  update = (record: any) => {
    this.setState({
      updateItem: record,
      modalOpen: true
    })
  }
  delete = (record: any) => {
    api.inInvoice.delete(Number(record.id)).then(res => {
      if (res.status === 200) {
        message.success('删除成功')
        this.getList()
      }
    })
  }
  getList() {
    this.setState({
      loading: true
    })
    api.inInvoice.getTableData(this.state.form).then(res => {
      if (res.status === 200) {
        this.setState({
          list: res.data.rows,
          total: res.data.count,
          loading: false,
          totalSum: res.data.rows
            .map(item => item.totalAmount)
            .reduce(
              (a, b) =>
                numeral(a)
                  .add(b)
                  .value(),
              0
            ),
          taxSum: res.data.rows
            .map(item => item.tax)
            .reduce(
              (a, b) =>
                numeral(a)
                  .add(b)
                  .value(),
              0
            )
        })
      }
    })
  }
  createClick = () => {
    this.setState({
      updateItem: {},
      modalTitle: '新建',
      modalOpen: true
    })
  }
  searchClick = () => {
    this.refresh()
  }
  refresh() {
    this.getList()
  }
  getCustomerList() {
    api.meta.getCustomerList().then(res => {
      this.setState({
        customerList: res.data
      })
    })
  }
  getCompanyList() {
    api.meta.getCompanyList().then(res => {
      this.setState({
        companyList: res.data
      })
    })
  }
  componentWillMount() {
    this.getList()
    this.getCompanyList()
    this.getCustomerList()
  }
  render() {
    const { totalSum, taxSum } = this.state
    return (
      <div className={styles.index}>
        <Button
          type="primary"
          loading={this.state.loading}
          icon="sync"
          className={styles.refreshBtn}
          onClick={() => {
            this.refresh()
          }}
        >
          刷新
        </Button>
        <Form
          className={styles.form}
          labelCol={{
            span: 6
          }}
          wrapperCol={{
            span: 16
          }}
          layout="horizontal"
        >
          <Row>
            <Col span={6}>
              <Form.Item label="进项公司">
                <Select
                  showSearch={true}
                  optionFilterProp="children"
                  onChange={value => {
                    this.setState({
                      form: {
                        ...this.state.form,
                        companyId: value
                      }
                    })
                  }}
                  allowClear={true}
                >
                  {this.state.companyList.map(item => (
                    <Select.Option value={item.id} key={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="开票日期">
                <DatePicker.RangePicker
                  ranges={{
                    上月: [
                      moment()
                        .add(-1, 'months')
                        .startOf('month'),
                      moment()
                        .add(-1, 'months')
                        .endOf('month')
                    ],
                    本月: [moment().startOf('month'), moment().endOf('month')],
                    本年: [moment().startOf('year'), moment().endOf('year')]
                  }}
                  defaultValue={[
                    moment()
                      .add(-1, 'months')
                      .startOf('month'),
                    moment()
                      .add(-1, 'months')
                      .endOf('month')
                  ]}
                  onChange={dates => {
                    this.setState({
                      form: {
                        ...this.state.form,
                        startTime: dates[0].toDate(),
                        endTime: dates[1].toDate()
                      }
                    })
                  }}
                  format="YYYY-MM-DD"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Col span={24} className={styles.actionBtn}>
          <Button
            type="primary"
            htmlType="submit"
            icon="search"
            onClick={this.searchClick}
          >
            搜索
          </Button>
        </Col>
        <Col span={24} className={styles.addBtn}>
          <Button type="primary" icon="plus" onClick={this.createClick}>
            新建
          </Button>
          <div
            className={styles.desc}
          >{`该页总金额${totalSum}元，总税金${taxSum}元`}</div>
        </Col>
        <Table
          bordered
          className={styles.table}
          columns={this.columns}
          dataSource={this.state.list}
          size="middle"
          rowKey="id"
          loading={this.state.loading}
          pagination={{
            position: 'bottom',
            pageSize: this.state.form.pageSize,
            total: this.state.total,
            pageSizeOptions: ['15', '30', '50'],
            current: this.state.form.page,
            showSizeChanger: true,
            onChange: (page, pageSize) => {
              const { form } = this.state
              form.page = page
              form.pageSize = pageSize ? pageSize : 1
              this.setState({
                form
              })
              this.getList()
            },
            showTotal: (total, range) => {
              return `共${total}条数据，当前显示${range}`
            },
            onShowSizeChange: (current, size) => {
              const { form } = this.state
              form.pageSize = size
              this.setState({
                form
              })
              this.getList()
            }
          }}
        />
        {this.state.modalOpen && (
          <DataModal
            title={this.state.modalTitle}
            onClose={() => {
              this.setState({ modalOpen: false })
            }}
            complete={() => {
              this.setState({ modalOpen: false })
              this.getList()
            }}
            updateItem={this.state.updateItem}
          />
        )}
      </div>
    )
  }
}

export default InvoicePage
