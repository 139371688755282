import React, { PureComponent } from 'react'
import styles from './style.module.less'
import { Modal, Tree } from 'antd'
import api from './../../api'
import ILink from './../../models/link'

const { TreeNode } = Tree

interface IReciveProps {
  onClose: () => void
  complete: () => void
  checkedKeys: string[]
  role: any
}
interface IState {
  loading: boolean
  data: object
  linkList: ILink[]
  checkedKeys: string[]
}
class MenuSelect extends PureComponent<IReciveProps> {
  constructor(props: IReciveProps) {
    super(props)
    this.state.checkedKeys = props.checkedKeys
  }
  state: IState = {
    loading: false,
    data: {},
    linkList: [],
    checkedKeys: []
  }
  close = () => {
    this.props.onClose()
  }
  submit = () => {
    this.setState({
      loading: true
    })
    api.role.setLink(this.state.checkedKeys, this.props.role).then(res => {
      if (res.status === 200) {
        this.props.complete()
      }
    })
  }
  componentDidMount() {
    this.getLinkList()
  }
  getLinkList() {
    api.meta.getLinkList().then(res => {
      if (res.status === 200) {
        this.setState({
          menuList: res.data
        })
      }
    })
  }
  onCheck = (checkedKeys, e) => {
    this.setState({
      checkedKeys: checkedKeys.checked
    })
  }
  render() {
    return (
      <Modal
        title="链接选择"
        visible={true}
        onCancel={this.close}
        width={600}
        okText="提交"
        cancelText="取消"
        confirmLoading={this.state.loading}
        onOk={this.submit}
        bodyStyle={{
          height: 'calc(100vh - 300px)',
          overflow: 'auto'
        }}
      >
        <Tree
          checkable={true}
          selectable={false}
          checkedKeys={this.state.checkedKeys}
          checkStrictly={true}
          autoExpandParent={true}
          expandedKeys={this.state.linkList.map(item => item.id.toString())}
          onCheck={(checkedKeys, e) => {
            this.onCheck(checkedKeys, e)
          }}
        >
          {this.state.linkList.map(item => {
            return <TreeNode title={item.name} key={item.id.toString()} />
          })}
        </Tree>
      </Modal>
    )
  }
}

export default MenuSelect
