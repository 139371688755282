import React, { PureComponent } from 'react'
import { Modal, Form, Select, Input, Radio, message } from 'antd'
import api from './../../api'
import styles from './style.module.less'
import ISender from './../../models/sender'
import IAddress from './../../models/address'

interface IProps {
  onClose: () => void
  complete: () => void
  address: IAddress
}

interface IState {
  loading: boolean
  senderList: ISender[]
  sender: ISender
  address: IAddress
  addressList: IAddress[]
  PayType: number // 1 现付   2 到付
  ExpType: number // 1 顺丰标快   2 顺丰特惠
  GoodsName: string
  Remark: string
}

export default class PrintModal extends PureComponent<IProps> {
  state: IState = {
    loading: false,
    senderList: [],
    sender: {} as ISender,
    address: this.props.address,
    addressList: [],
    PayType: 1,
    ExpType: 2,
    GoodsName: '标牌',
    Remark: ''
  }
  componentDidMount() {
    this.getSenderList()
    this.getAddressList()
  }
  getSenderList() {
    api.sender.list().then(res => {
      this.setState({ senderList: res.data })
    })
  }
  getAddressList() {
    api.address.list().then(res => {
      this.setState({ addressList: res.data })
    })
  }
  submit = () => {
    const { sender, address, PayType, ExpType, GoodsName, Remark } = this.state
    if (!sender.id) {
      message.error('请选择发货人')
      return
    }
    if (!address.id) {
      message.error('请选择收货人')
      return
    }
    if (GoodsName === '') {
      message.error('请输入商品名称')
      return
    }
    api.order
      .printExpress(sender, address, PayType, ExpType, GoodsName, Remark)
      .then(res => {
        window.open(`/#/print/${res.data.id}`, '_blank')
        this.props.complete()
      })
  }
  render() {
    const {
      sender,
      senderList,
      address,
      addressList,
      PayType,
      ExpType,
      GoodsName,
      Remark
    } = this.state
    return (
      <Modal
        title="打印快递单"
        visible={true}
        onCancel={this.props.onClose}
        width={600}
        okText="提交"
        cancelText="取消"
        confirmLoading={this.state.loading}
        onOk={this.submit}
      >
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          labelAlign="right"
          className={styles.form}
        >
          <Form.Item label="发货人">
            <Select
              allowClear={true}
              showSearch={true}
              optionFilterProp="children"
              value={sender.id}
              onChange={value => {
                this.setState({
                  sender: senderList.find(x => x.id === value) || {
                    id: 0
                  }
                })
              }}
              style={{
                width: '300px'
              }}
              placeholder="请选择发货人"
            >
              {senderList.map(item => (
                <Select.Option value={item.id} key={item.id}>
                  {item.name} - {item.province} - {item.detail}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="收货人">
            <Select
              allowClear={true}
              showSearch={true}
              optionFilterProp="children"
              value={address.id}
              onChange={value => {
                this.setState({
                  address: addressList.find(x => x.id === value) || {
                    id: 0
                  }
                })
              }}
              style={{
                width: '300px'
              }}
              placeholder="请选择发货人"
            >
              {addressList.map(item => (
                <Select.Option value={item.id} key={item.id}>
                  {item.name} - {item.province} - {item.detail}
                </Select.Option>
              ))}
            </Select>
            {address.id !== 0 && (
              <p>
                {address.name +
                  '-' +
                  address.phone +
                  '-' +
                  address.province +
                  address.city +
                  address.area +
                  address.detail}
              </p>
            )}
          </Form.Item>
          <Form.Item label="运输方式">
            <Radio.Group
              value={ExpType}
              onChange={e => {
                this.setState({
                  ExpType: e.target.value
                })
              }}
            >
              <Radio value={1}>空运</Radio>
              <Radio value={2}>陆运</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="付款方式">
            <Radio.Group
              value={PayType}
              onChange={e => {
                this.setState({
                  PayType: e.target.value
                })
              }}
            >
              <Radio value={1}>寄付月结</Radio>
              <Radio value={2}>到付</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="包裹内容">
            <Input
              placeholder="包裹内容"
              value={GoodsName}
              onChange={e => {
                this.setState({
                  GoodsName: e.target.value
                })
              }}
            />
          </Form.Item>
          <Form.Item label="备注">
            <Input
              placeholder="备注"
              value={Remark}
              onChange={e => {
                this.setState({
                  Remark: e.target.value
                })
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}
