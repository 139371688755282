import React, { PureComponent } from 'react'
import {
  Table,
  Divider,
  Form,
  Input,
  Col,
  Button,
  message,
  DatePicker,
  Modal,
  Switch
} from 'antd'
import DataModal from './modal'
import api from './../../api'
import styles from './style.module.less'
import IExpress from './../../models/express'
import ImageView from './../../components/imageView'
import moment from 'moment'
import numeral from 'numeral'
import TrackModal from './track'
import Prompt from './../../components/prompt'
const { confirm } = Modal

const { RangePicker } = DatePicker

class ExpressPage extends PureComponent {
  state = {
    loading: true,
    list: [],
    form: {
      pageSize: 15,
      page: 1,
      keyword: '',
      startTime: moment()
        .startOf('month')
        .toDate(),
      endTime: moment()
        .endOf('month')
        .toDate()
    },
    total: 0,
    modalOpen: false,
    modalTitle: '',
    updateItem: {} as IExpress,
    trackModalOpen: false,
    trackItem: {} as IExpress,
    trackIndex: -1
    // updatePack: {} as IPack
  }
  columns = [
    {
      title: '快递名称',
      dataIndex: 'expressName',
      key: 'expressName',
      align: 'center' as 'center'
    },
    {
      title: '快递单号',
      dataIndex: 'expressNum',
      key: 'expressNum',
      align: 'center' as 'center',
      render: (text: string, record: IExpress) => {
        return (
          <div>
            <a
              onClick={() => {
                this.setState({
                  trackModalOpen: true,
                  trackItem: record,
                  trackIndex: -1
                })
              }}
            >
              {text}
            </a>
            {record.childNum &&
              record.childNum.length > 0 &&
              record.childNum.map((item, index) => (
                <a
                  key={item}
                  style={{
                    display: 'block'
                  }}
                  onClick={() => {
                    this.setState({
                      trackModalOpen: true,
                      trackItem: record,
                      trackIndex: index
                    })
                  }}
                >
                  {item}
                </a>
              ))}
          </div>
        )
      }
    },
    {
      title: '发货时间',
      dataIndex: 'createTime',
      key: 'createTime',
      align: 'center' as 'center',
      render(text: string) {
        return <span>{moment(text).format('YYYY-MM-DD HH:mm')}</span>
      }
    },
    {
      title: '发件人',
      dataIndex: 'senderName',
      key: 'senderName',
      align: 'center' as 'center',
      render(text: string, record: IExpress) {
        return (
          <div>
            <span>{`${record.senderName} - ${record.senderPhone}`}</span>
            <br />
            <span>{record.senderAddress}</span>
          </div>
        )
      }
    },
    // {
    //   title: '发件人地址',
    //   dataIndex: 'senderAddress',
    //   key: 'senderAddress',
    //   align: 'center' as 'center'
    // },
    // {
    //   title: '发件人电话',
    //   dataIndex: 'senderPhone',
    //   key: 'senderPhone',
    //   align: 'center' as 'center'
    // },
    {
      title: '收件人',
      dataIndex: 'receiverName',
      key: 'receiverName',
      align: 'center' as 'center',
      render(text: string, record: IExpress) {
        return (
          <div>
            <span>{`${record.receiverName} - ${record.receiverPhone}`}</span>
            <br />
            <span>{record.receiverAddress}</span>
          </div>
        )
      }
    },
    {
      title: '快递费',
      dataIndex: 'money',
      key: 'money',
      align: 'center' as 'center',
      render(text: string, record: IExpress) {
        return (
          <div>
            {record.payType === 1 ? (
              record.hasBilled ? (
                <div>
                  <span style={{ color: 'red' }}>寄付</span>
                  <span>{record.money}元</span>
                  <br />
                  <span>{record.moneyRemark}</span>
                </div>
              ) : (
                <span>
                  <span style={{ color: 'red' }}>寄付</span>快递费未更新
                </span>
              )
            ) : (
              <span style={{ color: 'green' }}>到付</span>
            )}
          </div>
        )
      }
    },
    {
      title: '是否一格订单',
      dataIndex: 'isYG',
      key: 'isYG',
      align: 'center' as 'center',
      render: (text: string, record: IExpress) => {
        return (
          <Switch
            defaultChecked={record.isYG}
            onChange={() => {
              api.express.updateYG(record).then(() => {
                this.getList()
              })
            }}
          />
        )
      }
    },
    {
      title: '订单号',
      dataIndex: 'order',
      key: 'order',
      align: 'center' as 'center',
      render(text: string, record: IExpress) {
        let status = <span />
        if (record.order) {
          if (record.payType === 1) {
            if (record.hasBilled) {
              status = <span style={{ color: 'green' }}>已自动记账</span>
            } else {
              status = <span style={{ color: 'red' }}>尚未记账</span>
            }
          } else {
            status = <span style={{ color: 'green' }}>到付，无需记账</span>
          }
        }
        return (
          <div>
            <span>{record.order ? record.order.orderId.toString() : '无'}</span>
            <br />
            {status}
          </div>
        )
      }
    },
    // {
    //   title: '收件人地址',
    //   dataIndex: 'receiverAddress',
    //   key: 'receiverAddress',
    //   align: 'center' as 'center'
    // },
    // {
    //   title: '收件人电话',
    //   dataIndex: 'receiverPhone',
    //   key: 'receiverPhone',
    //   align: 'center' as 'center'
    // },
    {
      title: '操作',
      key: 'action',
      align: 'center' as 'center',
      width: 150,
      render: (text: any, record: IExpress) => (
        <span>
          <a
            onClick={() => {
              Prompt.show('子单数量', '子单数量', '请输入子单数量').then(
                res => {
                  const count = Number(res)
                  if (count > 0) {
                    api.express
                      .getChildNum(record.id, record.expressOrderId, count)
                      .then(result => {
                        this.getList()
                        message.success('申请成功')
                      })
                  } else {
                    message.error('请输入数字')
                  }
                }
              )
            }}
          >
            申请子单号
          </a>
          <Divider type="vertical" />
          <a
            onClick={() => {
              confirm({
                title: '确定撤销？',
                content: `确定撤销${moment(record.createTime).format(
                  'MM-DD HH:mm'
                )}寄给${record.receiverName}的快递吗？`,
                onOk: () => {
                  api.express.cancel(record.id).then(res => {
                    message.success('撤销成功')
                    this.getList()
                  })
                }
              })
            }}
          >
            撤销
          </a>
        </span>
      )
    }
  ]
  update = (record: any) => {
    this.setState({
      updateItem: record,
      modalOpen: true
    })
  }
  delete = (record: any) => {
    confirm({
      title: '确定删除？',
      content: '确定删除该项目？',
      onOk: () => {
        api.express.delete(Number(record.id)).then(res => {
          if (res.status === 200) {
            message.success('删除成功')
            this.getList()
          }
        })
      },
      onCancel() {}
    })
  }
  getList() {
    this.setState({
      loading: true
    })
    api.express.getTableData(this.state.form).then(res => {
      if (res.status === 200) {
        this.setState({
          list: res.data.rows,
          total: res.data.count,
          loading: false
        })
      }
    })
  }
  createClick = () => {
    this.setState({
      updateItem: {},
      modalTitle: '新建',
      modalOpen: true
    })
  }
  searchClick = () => {
    this.refresh()
  }
  refresh() {
    this.getList()
  }
  componentWillMount() {
    this.getList()
  }
  dateChange(date) {
    this.setState({
      form: {
        pageSize: this.state.form.pageSize,
        keyword: this.state.form.keyword,
        page: this.state.form.page,
        startTime: date[0].toDate(),
        endTime: date[1].toDate()
      }
    })
  }
  render() {
    const { trackItem, trackModalOpen, trackIndex } = this.state
    return (
      <div className={styles.index}>
        <Button
          type="primary"
          loading={this.state.loading}
          icon="sync"
          className={styles.refreshBtn}
          onClick={() => {
            this.refresh()
          }}
        >
          刷新
        </Button>
        <Form
          className={styles.form}
          labelCol={{
            span: 6
          }}
          wrapperCol={{
            span: 16
          }}
          layout="horizontal"
        >
          <Col span={8}>
            <Form.Item label="发货时间">
              <RangePicker
                defaultValue={[
                  moment().startOf('month'),
                  moment().endOf('month')
                ]}
                ranges={{
                  今天: [moment().startOf('day'), moment().endOf('day')],
                  近七天: [
                    moment()
                      .add(-7, 'day')
                      .startOf('day'),
                    moment()
                  ],
                  本月: [moment().startOf('month'), moment().endOf('month')],
                  上个月: [
                    moment()
                      .add(-1, 'month')
                      .startOf('month'),
                    moment()
                      .add(-1, 'month')
                      .endOf('month')
                  ]
                }}
                format="YYYY-MM-DD"
                onChange={date => {
                  this.dateChange(date)
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="搜索词">
              <Input
                placeholder="请输入收件人或发件人姓名"
                onChange={e => {
                  this.setState({
                    form: {
                      page: this.state.form.page,
                      pageSize: this.state.form.pageSize,
                      keyword: e.target.value,
                      startTime: this.state.form.startTime,
                      endTime: this.state.form.endTime
                    }
                  })
                }}
              />
            </Form.Item>
          </Col>
        </Form>
        <Col span={24} className={styles.actionBtn}>
          该页共{this.state.list.filter(x => x.payType === 1).length}
          个寄付，寄付总金额￥
          {this.state.list
            .filter(x => x.payType === 1)
            .map(x => x.money)
            .reduce(
              (a, b) =>
                numeral(a)
                  .add(b)
                  .value(),
              0
            )}
          元
          <Button
            type="primary"
            htmlType="submit"
            icon="search"
            onClick={this.searchClick}
          >
            搜索
          </Button>
        </Col>
        <Col span={24} className={styles.addBtn}>
          {/* <Button type="primary" icon="plus" onClick={this.createClick}>
            新建
          </Button> */}
        </Col>
        <Table
          bordered
          className={styles.table}
          columns={this.columns}
          dataSource={this.state.list}
          size="middle"
          rowKey="id"
          loading={this.state.loading}
          pagination={{
            position: 'bottom',
            pageSize: this.state.form.pageSize,
            total: this.state.total,
            pageSizeOptions: ['15', '30', '50'],
            current: this.state.form.page,
            showSizeChanger: true,
            onChange: (page, pageSize) => {
              const { form } = this.state
              form.page = page
              form.pageSize = pageSize ? pageSize : 1
              this.setState({
                form
              })
              this.getList()
            },
            showTotal: (total, range) => {
              return `共${total}条数据，当前显示${range}`
            },
            onShowSizeChange: (current, size) => {
              const { form } = this.state
              form.pageSize = size
              this.setState({
                form
              })
              this.getList()
            }
          }}
        />
        {this.state.modalOpen && (
          <DataModal
            title={this.state.modalTitle}
            onClose={() => {
              this.setState({ modalOpen: false })
            }}
            complete={() => {
              this.setState({ modalOpen: false })
              this.getList()
            }}
            updateItem={this.state.updateItem}
          />
        )}
        {trackModalOpen && (
          <TrackModal
            index={trackIndex}
            express={trackItem}
            onClose={() => {
              this.setState({
                trackModalOpen: false
              })
            }}
          />
        )}
      </div>
    )
  }
}

export default ExpressPage
