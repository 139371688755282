import React, { PureComponent } from 'react'
import { Modal } from 'antd'
import Form from './form'
import ILink from '../../models/link'

interface IReciveProps {
  onClose: () => void
  complete: () => void
  title: string
  updateItem: ILink
}
let childForm
class DataModal extends PureComponent<IReciveProps> {
  constructor(props: IReciveProps) {
    super(props)
  }
  state = {
    loading: false,
    data: {}
  }
  close = () => {
    this.props.onClose()
  }
  submit = () => {
    this.setState({
      loading: true
    })
    childForm.submit()
  }
  onRef = (form: any) => {
    childForm = form
  }
  complete = () => {
    this.setState({
      loading: false
    })
    this.props.complete()
  }
  validateFail = () => {
    this.setState({
      loading: false
    })
  }
  render() {
    return (
      <Modal
        title={this.props.title}
        visible={true}
        onCancel={this.close}
        width={600}
        okText="提交"
        cancelText="取消"
        confirmLoading={this.state.loading}
        onOk={this.submit}
        bodyStyle={{
          height: 'calc(100vh - 300px)',
          overflow: 'auto'
        }}
      >
        <Form
          data={this.props.updateItem}
          onRef={this.onRef}
          complete={this.complete}
          validateFail={this.validateFail}
        />
      </Modal>
    )
  }
}

export default DataModal
