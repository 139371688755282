import { Backend, IApi } from './backend'
import { AxiosResponse } from 'axios'
import { IRequest } from './index'
import IRole from './../models/role'

export default class Role extends Backend {
  static getTableData(
    request: IRequest
  ): Promise<AxiosResponse<{ rows: IRole[]; count: number }>> {
    const api: IApi = {
      url: '/api/role/tableData',
      method: 'get',
      data: { request }
    }
    return this.request<{ rows: IRole[]; count: number }>(api)
  }
  static createOrUpdate(data: any): Promise<AxiosResponse<IRole>> {
    const api: IApi = {
      url: '/api/role/createOrUpdate',
      method: 'post',
      data
    }
    return this.request<IRole>(api)
  }
  static delete(id: number): Promise<AxiosResponse> {
    const api: IApi = {
      url: `/api/role/${id}`,
      method: 'delete'
    }
    return this.request(api)
  }

  static setMenu(ids: string[], role: any): Promise<AxiosResponse<IRole>> {
    const api: IApi = {
      url: `/api/role/setMenu`,
      method: 'post',
      data: { ids, role }
    }
    return this.request<IRole>(api)
  }

  static setLink(ids: string[], role: any): Promise<AxiosResponse<IRole>> {
    const api: IApi = {
      url: `/api/role/setLink`,
      method: 'post',
      data: { ids, role }
    }
    return this.request<IRole>(api)
  }
}
