import localStorage from './localStorage'
import { message, } from 'antd'
import axios from 'axios'

const BASE_URL = `http://localhost:12581`

const sendRequest = (url, data): Promise<{ success: boolean, msg: string, data: string }> => {
  return new Promise((resolve, reject) => {
    const path = localStorage.getString('local-file-path')
    if (path) {
      if (data instanceof FormData) {
        data.append('localPath', path)
      } else {
        data.localPath = path
      }
      axios.post<{ success: boolean, msg: string, data: string }>(`${BASE_URL}${url}`, data).then(res => {
        resolve(res.data)
      }).catch(err => {
        message.error(err.message)
        reject(err)
      })
    } else {
      message.error('请先设置本地文件路径')
    }
  })
}

export const openLocalFile = (file: string) => {
  const url = '/openFile'
  sendRequest(url, { file })
}

export const upload = (file: File) => {
  const data = new FormData()
  data.append('file', file)
  const url = '/upload'
  return sendRequest(url, data)
}

export const rename = (oldName: string, newName: string) => {
  const url = '/rename'
  const data = {
    oldName,
    newName
  }
  return sendRequest(url, data)
}
