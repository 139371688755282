import { Backend, IApi } from './backend'
import { AxiosResponse } from 'axios'
import { IRequest } from './index'
import IUser from './../models/user'

export default class User extends Backend {
  static getTableData(
    request: IRequest
  ): Promise<AxiosResponse<{ rows: IUser; count: number }>> {
    const api: IApi = {
      url: '/api/user/tableData',
      method: 'get',
      data: { request }
    }
    return this.request<{ rows: IUser; count: number }>(api)
  }
  static createOrUpdate(data: any): Promise<AxiosResponse<IUser>> {
    const api: IApi = {
      url: '/api/user/createOrUpdate',
      method: 'post',
      data
    }
    return this.request<IUser>(api)
  }
  static delete(id: number): Promise<AxiosResponse> {
    const api: IApi = {
      url: `/api/user/${id}`,
      method: 'delete'
    }
    return this.request(api)
  }
  static setPassword(data: IUser): Promise<AxiosResponse<IUser>> {
    const api: IApi = {
      url: '/api/user/setPassword',
      method: 'post',
      data
    }
    return this.request<IUser>(api)
  }
  static updatePassword(password: string): Promise<AxiosResponse<IUser>> {
    const api: IApi = {
      url: '/api/user/updatePassword',
      method: 'post',
      data: { password }
    }
    return this.request<IUser>(api)
  }
}
