import React, { PureComponent } from 'react'
import { Modal, Form, Input, message } from 'antd'
import IUser from '../../models/user'
import api from './../../api'

interface IReciveProps {
  onClose: () => void
  complete: () => void
  updateItem: IUser
}
class DataModal extends PureComponent<IReciveProps> {
  constructor(props: IReciveProps) {
    super(props)
  }
  state = {
    loading: false,
    password1: '',
    password2: ''
  }
  close = () => {
    this.props.onClose()
  }
  submit = () => {
    this.setState({
      loading: true
    })
    const { password1, password2 } = this.state
    if (password1 !== password2) {
      message.warning('两次密码输入不同')
      this.setState({
        loading: false
      })
    } else {
      const data = { ...this.props.updateItem, password: password1 }
      api.user
        .setPassword(data)
        .then(() => {
          this.props.complete()
        })
        .catch(() => {
          this.setState({
            loading: false
          })
        })
    }
  }
  render() {
    return (
      <Modal
        title="设置密码"
        visible={true}
        onCancel={this.close}
        width={600}
        okText="提交"
        cancelText="取消"
        confirmLoading={this.state.loading}
        onOk={this.submit}
        bodyStyle={{
          height: 'calc(100vh - 300px)',
          overflow: 'auto'
        }}
      >
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          labelAlign="right"
        >
          <Form.Item label="请输入密码">
            <Input
              placeholder="请输入密码"
              type="password"
              onChange={e => {
                this.setState({
                  password1: e.target.value
                })
              }}
            />
          </Form.Item>
          <Form.Item label="请再输入一遍">
            <Input
              placeholder="请再输入一遍"
              type="password"
              onChange={e => {
                this.setState({
                  password2: e.target.value
                })
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default DataModal
