import React, { useCallback, useEffect, useState } from 'react'
import { Button, Form, message, Modal, Select, Table, Tag } from 'antd'
import moment from 'moment'
import Copy from 'copy-to-clipboard'

import api from './../../../api'
import { seafileClient } from './../../../utils/seafile'
import './index.less'
import IOrder from '../../../models/order'
import Prompt from './../../../components/prompt'

const yearList = []
for (let i = 2005; i <= moment().year(); i++) {
  yearList.push(i)
}
const monthList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]

const FileFix: React.FC = () => {
  useEffect(() => {
    getList()
  }, [])
  const [year, setYear] = useState(moment().year())
  const [month, setMonth] = useState(moment().month())
  const [list, setList] = useState<IOrder[]>([])
  const [fileExist, setFileExist] = useState<
    Array<{ orderId: number; exist: boolean }>
  >([])
  const handleCopy = useCallback((text: string) => {
    Copy(text)
    message.success('文件名复制成功')
  }, [])
  const handleEditFileName = useCallback((order: IOrder) => {
    Prompt.show('修改文件名', '新文件名', '请输入新文件名', order.cdrFile).then(
      fileName => {
        Modal.confirm({
          title: '确定修改文件名',
          content: `${order.orderId}订单的文件名将被修改为${fileName},确定吗？`,
          onOk() {
            doEditFileName(order.id, fileName)
          },
          onCancel() {
            return Promise.reject()
          }
        })
      }
    )
  }, [])
  const getList = useCallback(() => {
    api.order.getOrderByMonth(year, month).then(res => {
      setList(res.data)
    })
  }, [year, month])
  const doEditFileName = useCallback(
    (id: number, fileName: string) => {
      api.order
        .editCdrFile(id, fileName)
        .then(() => {
          message.success('修改成功')
          getList()
        })
        .catch(() => {
          message.error('修改失败')
        })
    },
    [getList]
  )
  const columns = [
    {
      title: '订单号',
      dataIndex: 'orderId',
      key: 'orderId',
      align: 'center' as 'center'
    },
    {
      title: '文件名',
      dataIndex: 'cdrFile',
      key: 'cdrFile',
      align: 'left' as 'left',
      render(text: string, record: IOrder) {
        const arr = text.split('/')
        arr.shift()
        const fileName = arr.pop()
        return (
          <React.Fragment>
            {arr.map((item, index) => (
              <Tag key={index} color="blue">
                {item}
              </Tag>
            ))}
            <a
              onClick={() => {
                const temp = fileName.split('.')
                temp.pop()
                handleCopy(temp.join('.'))
              }}
            >
              {fileName}
            </a>
            <Button
              type="primary"
              size="small"
              className="edit-file-name-btn"
              onClick={() => {
                handleEditFileName(record)
              }}
            >
              修改文件名
            </Button>
          </React.Fragment>
        )
      }
    },
    {
      title: '文件是否存在',
      dataIndex: 'exist',
      key: 'exist',
      align: 'center' as 'center',
      render(text, record: IOrder) {
        const check = fileExist.find(x => x.orderId === record.orderId)
        if (check) {
          return check.exist ? (
            <Tag color="green">文件正确</Tag>
          ) : (
            <Tag color="red">文件错误</Tag>
          )
        } else {
          return <Tag color="yellow">检测中...</Tag>
        }
      }
    }
  ]

  useEffect(() => {
    const existList = []
    list.forEach((item, index) => {
      setTimeout(() => {
        seafileClient
          .checkFile(item.cdrFile)
          .then(() => {
            existList.push({ orderId: item.orderId, exist: true })
            setFileExist([...existList])
          })
          .catch(() => {
            existList.push({ orderId: item.orderId, exist: false })
            setFileExist([...existList])
          })
      }, index * 50)
    })
  }, [list])
  return (
    <div className="file-fix">
      <Form className="form" layout="inline">
        <Form.Item className="form-item">
          <Select value={year} onChange={setYear} className="form-item-select">
            {yearList.map(item => (
              <Select.Option value={item} key={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item className="form-item">
          <Select
            value={month}
            onChange={setMonth}
            className="form-item-select"
          >
            {monthList.map(item => (
              <Select.Option value={item} key={item}>
                {item + 1}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item className="form-item">
          <Button type="primary" onClick={getList}>
            查找
          </Button>
        </Form.Item>
      </Form>
      <Table
        columns={columns}
        bordered
        size="middle"
        className="table"
        dataSource={list}
        rowKey="id"
        pagination={{ pageSize: 5000 }}
      />
    </div>
  )
}

export default FileFix
