import { Backend, IApi } from './backend'
import { AxiosResponse } from 'axios'
import { IRequest } from './index'
import IOutCategory from './../models/outCategory'

export default class OutCategory extends Backend {
  static getTableData(request: IRequest): Promise<AxiosResponse<{ rows: IOutCategory[], count: number }>> {
    const api: IApi = {
      url: '/api/outCategory/tableData',
      method: 'get',
      data: { request }
    }
    return this.request<{ rows: IOutCategory[], count: number }>(api)
  }
  static createOrUpdate(data: any): Promise<AxiosResponse<IOutCategory>> {
    const api: IApi = {
      url: '/api/outCategory/createOrUpdate',
      method: 'post',
      data
    }
    return this.request<IOutCategory>(api)
  }
  static delete(id: number): Promise<AxiosResponse> {
    const api: IApi = {
      url: `/api/outCategory/${id}`,
      method: 'delete'
    }
    return this.request(api)
  }
}
