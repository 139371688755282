import React, { PureComponent } from 'react'
import { Form as AntdForm, Input, Select } from 'antd'
import { FormComponentProps } from 'antd/es/form'
import api from './../../api'
import styles from './style.module.less'
import IRole from './../../models/role'
import IUser from './../../models/user'

interface IReciveProps extends FormComponentProps {
  onRef: (form: Form) => void
  complete: (user: IUser) => void
  validateFail: () => void
  data: IUser
}

interface IState {
  roleList: IRole[]
}

class Form extends PureComponent<IReciveProps, any> {
  constructor(props: IReciveProps) {
    super(props)
    this.state = {
      roleList: []
    }
  }
  state: IState
  submit = () => {
    this.props.form.validateFields((err: any, values: any) => {
      if (err) {
        this.props.validateFail()
      } else {
        api.user
          .createOrUpdate(values)
          .then(res => {
            if (res.status === 200) {
              this.props.complete(res.data)
            }
          })
          .catch(() => {
            this.props.validateFail()
          })
      }
    })
  }
  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillMount() {
    this.getRoleList()
  }
  getRoleList() {
    api.meta.getRoleList().then(result => {
      if (result.status === 200) {
        this.setState({
          roleList: result.data
        })
      }
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <AntdForm
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        labelAlign="right"
        className={styles.form}
      >
        <AntdForm.Item label="" className={styles.displayNone}>
          {getFieldDecorator('id', {
            rules: [{ required: false, message: 'id' }],
            initialValue: this.props.data['id']
          })(<Input placeholder="" />)}
        </AntdForm.Item>
        <AntdForm.Item label="用户名" className={styles.displayNormal}>
          {getFieldDecorator('userName', {
            rules: [{ required: true, message: '用户名' }],
            initialValue: this.props.data['userName']
          })(<Input placeholder="用户名" />)}
        </AntdForm.Item>
        <AntdForm.Item label="真实姓名" className={styles.displayNormal}>
          {getFieldDecorator('realName', {
            rules: [{ required: true, message: 'realName' }],
            initialValue: this.props.data['realName']
          })(<Input placeholder="真实姓名" />)}
        </AntdForm.Item>
        <AntdForm.Item label="公司" className={styles.displayNone}>
          {getFieldDecorator('company', {
            rules: [{ required: false, message: 'company' }],
            initialValue: this.props.data['company']
          })(<Input placeholder="公司" />)}
        </AntdForm.Item>
        <AntdForm.Item label="手机" className={styles.displayNormal}>
          {getFieldDecorator('phone', {
            rules: [{ required: true, message: 'phone' }],
            initialValue: this.props.data['phone']
          })(<Input placeholder="手机" />)}
        </AntdForm.Item>
        <AntdForm.Item label="用户类型" className={styles.displayNone}>
          {getFieldDecorator('type', {
            rules: [{ required: true, message: 'type' }],
            initialValue: this.props.data['type'] || '客户'
          })(
            <Select placeholder="用户类型">
              <Select.Option value="客户">客户</Select.Option>
              <Select.Option value="员工">员工</Select.Option>
              <Select.Option value="老板">老板</Select.Option>
              <Select.Option value="管理员">管理员</Select.Option>
            </Select>
          )}
        </AntdForm.Item>
        <AntdForm.Item label="小程序openid" className={styles.displayNone}>
          {getFieldDecorator('weappOpenid', {
            rules: [{ required: false, message: 'weappOpenid' }],
            initialValue: this.props.data['weappOpenid']
          })(<Input placeholder="小程序openid" />)}
        </AntdForm.Item>
        <AntdForm.Item label="公众号openid" className={styles.displayNone}>
          {getFieldDecorator('mpOpenid', {
            rules: [{ required: false, message: 'mpOpenid' }],
            initialValue: this.props.data['mpOpenid']
          })(<Input placeholder="公众号openid" />)}
        </AntdForm.Item>
        <AntdForm.Item label="nickname" className={styles.displayNone}>
          {getFieldDecorator('nickname', {
            rules: [{ required: false, message: 'nickname' }],
            initialValue: this.props.data['nickname']
          })(<Input placeholder="nickname" />)}
        </AntdForm.Item>
        <AntdForm.Item label="headimgurl" className={styles.displayNone}>
          {getFieldDecorator('headimgurl', {
            rules: [{ required: false, message: 'headimgurl' }],
            initialValue: this.props.data['headimgurl']
          })(<Input placeholder="headimgurl" />)}
        </AntdForm.Item>
        <AntdForm.Item
          label="公众号二维码ticket"
          className={styles.displayNone}
        >
          {getFieldDecorator('qrimgTicket', {
            rules: [{ required: false, message: 'qrimgTicket' }],
            initialValue: this.props.data['qrimgTicket']
          })(<Input placeholder="公众号二维码ticket" />)}
        </AntdForm.Item>
        <AntdForm.Item label="角色" className={styles.displayNone}>
          {getFieldDecorator('roleId', {
            rules: [{ required: true, message: 'roleId' }],
            initialValue: this.props.data['roleId'] || 4
          })(
            <Select placeholder="角色">
              {this.state.roleList.map((item, index) => {
                return (
                  <Select.Option value={item.id} key={index}>
                    {item.name}
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </AntdForm.Item>
      </AntdForm>
    )
  }
}

export default AntdForm.create<IReciveProps>()(Form)
