import React, { PureComponent } from 'react'
import {
  Form as AntdForm,
  Input,
  Select,
  Table,
  message,
  DatePicker
} from 'antd'
import { FormComponentProps } from 'antd/es/form'
import moment from 'moment'
import api from './../../api'
import styles from './style.module.less'
import IInvoice from './../../models/invoice'
import IUser from '../../models/user'
import ICompany from '../../models/company'
import MultipleImgUpload from './../../components/upload/multipleImgUpload'

interface IReciveProps extends FormComponentProps {
  onRef: (form: Form) => void
  complete: (invoice: IInvoice) => void
  validateFail: () => void
  data: IInvoice
}

interface IState {
  customerList: IUser[]
  companyList: ICompany[]
  goods: []
}

class Form extends PureComponent<IReciveProps, any> {
  state: IState = {
    customerList: [],
    companyList: [],
    goods: []
  }
  columns = [
    {
      title: '名称',
      align: 'center' as 'center',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: '规格',
      align: 'center' as 'center',
      key: 'type',
      dataIndex: 'type'
    },
    {
      title: '单位',
      align: 'center' as 'center',
      key: 'unit',
      dataIndex: 'unit'
    },
    {
      title: '数量',
      align: 'center' as 'center',
      key: 'count',
      dataIndex: 'count'
    },
    {
      title: '单价',
      align: 'center' as 'center',
      key: 'price',
      dataIndex: 'price'
    },
    {
      title: '金额',
      align: 'center' as 'center',
      key: 'amount',
      dataIndex: 'amount'
    },
    {
      title: '税率',
      align: 'center' as 'center',
      key: 'taxRate',
      dataIndex: 'taxRate'
    },
    {
      title: '税金',
      align: 'center' as 'center',
      key: 'tax',
      dataIndex: 'tax'
    }
  ]
  submit = () => {
    this.props.form.validateFields((err: any, values: any) => {
      if (err) {
        this.props.validateFail()
      } else {
        api.invoice
          .createOrUpdate(values)
          .then(res => {
            if (res.status === 200) {
              this.props.complete(res.data)
            }
          })
          .catch(() => {
            this.props.validateFail()
          })
      }
    })
  }
  getCustomerList() {
    api.meta.getCustomerList().then(res => {
      this.setState({
        customerList: res.data
      })
    })
  }
  getCompanyList() {
    api.meta.getCompanyList().then(res => {
      this.setState({
        companyList: res.data
      })
    })
  }
  handlePicUpload: (file) => Promise<string> = file => {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('file', file)
      api.invoice
        .picUpload(formData)
        .then(res => {
          const { url, invoiceData } = res.data
          if (invoiceData.words_result_num > 0) {
            resolve(url)
            const invoice = invoiceData.words_result
            const goods = []
            invoice.CommodityName.forEach((item, index) => {
              const row = (index + 1).toString()
              goods.push({
                row,
                name: item.word,
                type: invoice.CommodityType.find(x => x.row === row)
                  ? invoice.CommodityType.find(x => x.row === row).word
                  : '',
                unit: invoice.CommodityUnit.find(x => x.row === row)
                  ? invoice.CommodityUnit.find(x => x.row === row).word
                  : '',
                count: invoice.CommodityNum.find(x => x.row === row)
                  ? invoice.CommodityNum.find(x => x.row === row).word
                  : '',
                price: invoice.CommodityPrice.find(x => x.row === row)
                  ? invoice.CommodityPrice.find(x => x.row === row).word
                  : '',
                amount: invoice.CommodityAmount.find(x => x.row === row)
                  ? invoice.CommodityAmount.find(x => x.row === row).word
                  : '',
                taxRate: invoice.CommodityTaxRate.find(x => x.row === row)
                  ? invoice.CommodityTaxRate.find(x => x.row === row).word
                  : '',
                tax: invoice.CommodityTax.find(x => x.row === row)
                  ? invoice.CommodityTax.find(x => x.row === row).word
                  : ''
              })
            })
            this.props.form.setFieldsValue({
              data: invoice,
              buyer: invoice.PurchaserName,
              buyerNum: invoice.PurchaserRegisterNum,
              publishDate: moment(invoice.InvoiceDate, 'YYYY年MM月DD日'),
              amount: invoice.TotalAmount,
              tax: invoice.TotalTax,
              totalAmount: invoice.AmountInFiguers,
              code: invoice.InvoiceCode,
              num: invoice.InvoiceNum,
              goods
            })
            this.setState({
              goods
            })
          } else {
            reject()
          }
        })
        .catch(err => {
          reject(err)
        })
    })
  }
  componentDidMount() {
    const {
      data: { goods }
    } = this.props
    this.setState({
      goods
    })
    this.props.onRef(this)
    this.getCustomerList()
    this.getCompanyList()
  }
  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <AntdForm
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        labelAlign="right"
        className={styles.form}
      >
        <AntdForm.Item label="" className={styles.displayNone}>
          {getFieldDecorator('id', {
            rules: [{ required: false, message: '' }],
            initialValue: this.props.data.id
          })(<Input placeholder="" />)}
        </AntdForm.Item>

        <AntdForm.Item label="开票公司" className={styles.displayNormal}>
          {getFieldDecorator('companyId', {
            rules: [{ required: true, message: '请选择开票公司' }],
            initialValue: this.props.data.companyId
          })(
            <Select placeholder="">
              {this.state.companyList.map((item, index) => {
                return (
                  <Select.Option value={item.id} key={index}>
                    {item.name}
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </AntdForm.Item>

        <AntdForm.Item label="客户" className={styles.displayNormal}>
          {getFieldDecorator('customerId', {
            rules: [{ required: true, message: '请选择客户' }],
            initialValue: this.props.data.customerId
          })(
            <Select
              placeholder=""
              showSearch={true}
              optionFilterProp="children"
            >
              {this.state.customerList.map((item, index) => {
                return (
                  <Select.Option value={item.id} key={index}>
                    {item.realName} - {item.company}
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </AntdForm.Item>

        <hr />

        <AntdForm.Item label="发票代码" className={styles.displayNormal}>
          {getFieldDecorator('code', {
            rules: [{ required: false, message: '发票代码' }],
            initialValue: this.props.data.code
          })(<Input placeholder="发票代码" />)}
        </AntdForm.Item>
        <AntdForm.Item label="发票号码" className={styles.displayNormal}>
          {getFieldDecorator('num', {
            rules: [{ required: false, message: '发票号码' }],
            initialValue: this.props.data.num
          })(<Input placeholder="发票号码" />)}
        </AntdForm.Item>
        <AntdForm.Item label="开票日期" className={styles.displayNormal}>
          {getFieldDecorator('publishDate', {
            rules: [{ required: false, message: '开票日期' }],
            initialValue: !!this.props.data.publishDate
              ? moment(this.props.data.publishDate)
              : moment()
          })(<DatePicker placeholder="开票日期" />)}
        </AntdForm.Item>
        <hr />

        <AntdForm.Item label="购买人名称" className={styles.displayNormal}>
          {getFieldDecorator('buyer', {
            rules: [{ required: false, message: '购买人名称' }],
            initialValue: this.props.data.buyer
          })(<Input placeholder="购买人名称" />)}
        </AntdForm.Item>
        <AntdForm.Item label="纳税人识别号" className={styles.displayNormal}>
          {getFieldDecorator('buyerNum', {
            rules: [{ required: false, message: '纳税人识别号' }],
            initialValue: this.props.data.buyerNum
          })(<Input placeholder="纳税人识别号" />)}
        </AntdForm.Item>
        <hr />

        <AntdForm.Item label="商品" className={styles.displayNormal}>
          {getFieldDecorator('goods', {
            rules: [{ required: false, message: '商品' }],
            initialValue: this.props.data.goods
          })(<Input placeholder="商品" className={styles.displayNone} />)}
          <Table
            dataSource={this.state.goods}
            size="small"
            columns={this.columns}
            rowKey="row"
          />
        </AntdForm.Item>
        <hr />

        <AntdForm.Item label="金额" className={styles.displayNormal}>
          {getFieldDecorator('amount', {
            rules: [{ required: false, message: '金额' }],
            initialValue: this.props.data.amount
          })(<Input placeholder="金额" />)}
        </AntdForm.Item>
        <AntdForm.Item label="税金" className={styles.displayNormal}>
          {getFieldDecorator('tax', {
            rules: [{ required: false, message: '税金' }],
            initialValue: this.props.data.tax
          })(<Input placeholder="税金" />)}
        </AntdForm.Item>
        <AntdForm.Item label="价税合计" className={styles.displayNormal}>
          {getFieldDecorator('totalAmount', {
            rules: [{ required: false, message: '价税合计' }],
            initialValue: this.props.data.totalAmount
          })(<Input placeholder="价税合计" />)}
        </AntdForm.Item>
        <hr />

        <AntdForm.Item label="备注" className={styles.displayNormal}>
          {getFieldDecorator('remark', {
            rules: [{ required: false, message: '备注' }],
            initialValue: this.props.data.remark
          })(<Input placeholder="备注" />)}
        </AntdForm.Item>
        <AntdForm.Item label="原始数据" className={styles.displayNone}>
          {getFieldDecorator('data', {
            rules: [{ required: false, message: '原始数据' }],
            initialValue: this.props.data.data
          })(<Input placeholder="原始数据" />)}
        </AntdForm.Item>

        <AntdForm.Item label="照片" className={styles.displayNormal}>
          {getFieldDecorator('pic', {
            rules: [{ required: false, message: '照片' }],
            initialValue: this.props.data.pic
          })(<Input placeholder="照片" disabled={true} />)}
          <MultipleImgUpload
            path="invoice"
            fail={msg => {
              message.warning(msg)
            }}
            success={url => {
              this.props.form.setFieldsValue({
                pic: url[0]
              })
            }}
            count={1}
            upload={this.handlePicUpload}
            defaultUrls={this.props.data.pic && [this.props.data.pic]}
          />
        </AntdForm.Item>
      </AntdForm>
    )
  }
}

export default AntdForm.create<IReciveProps>()(Form)
