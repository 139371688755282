import React, { PureComponent } from 'react'
import {
  Table,
  Divider,
  Form,
  Input,
  Col,
  Button,
  message,
  Select,
  Modal
} from 'antd'
import DataModal from './modal'
import api from './../../api'
import styles from './style.module.less'
import IPayment from '../../models/payment'
import moment from 'moment'
import IUser from './../../models/user'
import ImageView from './../../components/imageView'
import classNames from 'classnames'
const { confirm } = Modal

class PaymentPage extends PureComponent {
  state = {
    loading: true,
    list: [],
    form: {
      pageSize: 15,
      page: 1,
      keyword: '',
      customerId: 0
    },
    total: 0,
    modalOpen: false,
    modalTitle: '',
    updateItem: {} as IPayment,
    customerList: [] as IUser[]
  }
  columns = [
    {
      title: '客户',
      dataIndex: 'customer.realName',
      key: 'customer',
      align: 'center' as 'center'
    },
    {
      title: '提交人',
      dataIndex: 'clerk.realName',
      key: 'clerk',
      align: 'center' as 'center'
    },
    {
      title: '时间',
      dataIndex: 'createTime',
      key: 'createTime',
      align: 'center' as 'center',
      render(text: string) {
        return <span>{moment(text).format('YYYY-MM-DD HH:mm')}</span>
      }
    },
    {
      title: '记账金额',
      dataIndex: 'money',
      key: 'money',
      align: 'center' as 'center'
    },
    {
      title: '付款金额',
      dataIndex: 'realMoney',
      key: 'realMoney',
      align: 'center' as 'center'
    },
    {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark',
      align: 'center' as 'center'
    },
    {
      title: '图片',
      dataIndex: 'pic',
      key: 'pic',
      align: 'center' as 'center',
      render(text, record: IPayment) {
        if (record.pic && record.pic.length > 0) {
          return (
            <Button
              type="primary"
              icon="file-image"
              onClick={() => {
                ImageView.show(
                  record.pic.map(item => {
                    return {
                      src: item,
                      title: `${record.customer.realName}发票`
                    }
                  })
                )
              }}
            >
              查看图片
            </Button>
          )
        } else {
          return <span>无图片</span>
        }
      }
    },
    {
      title: '审核状态',
      align: 'center' as 'center',
      width: 200,
      render(text: string, record: IPayment) {
        if (record.verifies.every(x => x.status === '审核通过')) {
          return <span className={styles.green}>审核通过</span>
        } else {
          return record.verifies.map(item => {
            return (
              <div className={styles.verifyItem} key={item.id}>
                <div className={styles.verifyName}>{item.user.realName}</div>
                <div
                  className={classNames(styles.verifyStatus, {
                    [styles.green]: item.status === '审核通过',
                    [styles.yellow]: item.status === '待审核',
                    [styles.red]: item.status === '审核未通过'
                  })}
                >
                  {item.status}
                  {item.status === '审核未通过' ? `(${item.remark})` : ''}
                </div>
              </div>
            )
          })
        }
      }
    }
    // {
    //   title: '操作',
    //   key: 'action',
    //   align: 'center' as 'center',
    //   width: 200,
    //   render: (text: any, record: any) => (
    //     <span>
    //       <a
    //         onClick={() => {
    //           this.update(record)
    //         }}
    //       >
    //         编辑
    //       </a>
    //       <Divider type="vertical" />
    //       <a
    //         onClick={() => {
    //           this.delete(record)
    //         }}
    //       >
    //         删除
    //       </a>
    //     </span>
    //   )
    // }
  ]
  update = (record: any) => {
    this.setState({
      updateItem: record,
      modalOpen: true
    })
  }
  delete = (record: any) => {
    confirm({
      title: '确定删除？',
      content: '确定删除该项目？',
      onOk: () => {
        api.payment.delete(Number(record.id)).then(res => {
          if (res.status === 200) {
            message.success('删除成功')
            this.getList()
          }
        })
      },
      onCancel() {}
    })
  }
  getList() {
    this.setState({
      loading: true
    })
    api.payment.getTableData(this.state.form).then(res => {
      if (res.status === 200) {
        this.setState({
          list: res.data.rows,
          total: res.data.count,
          loading: false
        })
      }
    })
  }
  createClick = () => {
    this.setState({
      updateItem: {},
      modalTitle: '新建',
      modalOpen: true
    })
  }
  searchClick = () => {
    this.refresh()
  }
  refresh() {
    this.getList()
  }
  getCustomerList() {
    api.meta.getCustomerList().then(res => {
      this.setState({
        customerList: res.data
      })
    })
  }
  componentDidMount() {
    this.getList()
    this.getCustomerList()
  }
  render() {
    const { customerList } = this.state
    return (
      <div className={styles.index}>
        <Button
          type="primary"
          loading={this.state.loading}
          icon="sync"
          className={styles.refreshBtn}
          onClick={() => {
            this.refresh()
          }}
        >
          刷新
        </Button>
        <Form
          className={styles.form}
          labelCol={{
            span: 6
          }}
          wrapperCol={{
            span: 16
          }}
          layout="horizontal"
        >
          <Col span={8}>
            <Form.Item label="客户">
              {/* <Input
                placeholder="请输入搜索词"
                onChange={e => {
                  this.setState({
                    form: {
                      page: this.state.form.page,
                      pageSize: this.state.form.pageSize,
                      keyword: e.target.value
                    }
                  })
                }}
              /> */}
              <Select
                showSearch={true}
                optionFilterProp="children"
                onSelect={value => {
                  this.setState({
                    form: {
                      pageSize: 15,
                      page: 1,
                      keyword: '',
                      customerId: value
                    }
                  })
                }}
              >
                {customerList.map(item => (
                  <Select.Option value={item.id} key={item.id}>
                    {item.realName} - {item.company}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Form>
        <Col span={24} className={styles.actionBtn}>
          <Button
            type="primary"
            htmlType="submit"
            icon="search"
            onClick={this.searchClick}
          >
            搜索
          </Button>
        </Col>
        {/* <Col span={24} className={styles.addBtn}>
          <Button type="primary" icon="plus" onClick={this.createClick}>
            新建
          </Button>
        </Col> */}
        <Table
          bordered
          className={styles.table}
          columns={this.columns}
          dataSource={this.state.list}
          size="middle"
          rowKey="id"
          loading={this.state.loading}
          pagination={{
            position: 'bottom',
            pageSize: this.state.form.pageSize,
            total: this.state.total,
            pageSizeOptions: ['15', '30', '50'],
            current: this.state.form.page,
            showSizeChanger: true,
            onChange: (page, pageSize) => {
              const { form } = this.state
              form.page = page
              form.pageSize = pageSize ? pageSize : 1
              this.setState({
                form
              })
              this.getList()
            },
            showTotal: (total, range) => {
              return `共${total}条数据，当前显示${range}`
            },
            onShowSizeChange: (current, size) => {
              const { form } = this.state
              form.pageSize = size
              this.setState({
                form
              })
              this.getList()
            }
          }}
        />
        {this.state.modalOpen && (
          <DataModal
            title={this.state.modalTitle}
            onClose={() => {
              this.setState({ modalOpen: false })
            }}
            complete={() => {
              this.setState({ modalOpen: false })
              this.getList()
            }}
            updateItem={this.state.updateItem}
            totalSum={0}
          />
        )}
      </div>
    )
  }
}

export default PaymentPage
