import React, { PureComponent } from 'react'
import { Modal, Descriptions, Timeline, message, Divider } from 'antd'
import moment from 'moment'
import 'moment/locale/zh-cn'
import IExpress from '../../models/express'
import api from '../../api'
import { ITrack } from './../../api/express'
import copy from 'copy-to-clipboard'

interface IState {
  loading: boolean
  trackList: ITrack[]
}

interface IProps {
  onClose: () => void
  express: IExpress
  // 子单号
  index?: number
}

class Track extends PureComponent<IProps> {
  state: IState = {
    loading: false,
    trackList: []
  }
  close = () => {
    this.props.onClose()
  }
  submit = () => {
    this.props.onClose()
  }
  track() {
    this.setState({
      loading: true
    })
    const { express, index } = this.props
    const expressNum =
      index !== undefined && index >= 0
        ? express.childNum[index]
        : express.expressNum
    api.express
      .track(expressNum, express.senderPhone)
      .then(res => {
        res.data = res.data.sort((a, b) =>
          moment(a.accept_time).isBefore(moment(b.accept_time)) ? 1 : -1
        )
        this.setState({
          trackList: res.data,
          loading: false
        })
      })
      .catch(() => {
        this.setState({
          loading: false
        })
      })
  }
  componentDidMount() {
    this.track()
  }
  render() {
    const { express, index } = this.props
    const { trackList } = this.state
    const expressNum =
      index !== undefined && index >= 0
        ? express.childNum[index]
        : express.expressNum
    return (
      <Modal
        title="查看快递单"
        visible={true}
        onCancel={this.close}
        width={600}
        okText="提交"
        cancelText="取消"
        confirmLoading={this.state.loading}
        onOk={this.submit}
        bodyStyle={{
          height: 'calc(100vh - 300px)',
          overflow: 'auto'
        }}
      >
        <Descriptions title="快递追踪" bordered={true}>
          <Descriptions.Item label="快递名称" span={3}>
            {express.expressName}
          </Descriptions.Item>
          <Descriptions.Item label="发货时间" span={3}>
            {moment(express.createTime).format('YYYY-MM-DD HH:mm:ss')}
          </Descriptions.Item>
          <Descriptions.Item label="快递单号" span={3}>
            {expressNum}
            &nbsp;&nbsp;
            <a
              onClick={() => {
                copy(expressNum)
                message.success('复制成功')
              }}
            >
              点击复制
            </a>
            <Divider type="vertical" />
            <a
              onClick={() => {
                const url =
                  index !== undefined && index >= 0
                    ? `/#/print/${express.id}/${index}`
                    : `/#/print/${express.id}`
                window.open(url, '_blank')
              }}
            >
              再次打印
            </a>
          </Descriptions.Item>
          <Descriptions.Item label="最新状态" span={3}>
            {trackList.length > 0 && (
              <div>
                <p>{trackList[0].remark}</p>
                <p>{`${moment(trackList[0].accept_time).format(
                  'YYYY-MM-DD HH:mm'
                )} - ${moment(trackList[0].accept_time).fromNow()}`}</p>
              </div>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="发件人" span={3}>
            {express.senderName} - {express.senderPhone}
            <br />
            {express.senderAddress}
          </Descriptions.Item>
          <Descriptions.Item label="收件人" span={3}>
            {express.receiverName} - {express.receiverPhone}
            <br />
            {express.receiverAddress}
          </Descriptions.Item>
          <Descriptions.Item label="快递详情" span={3}>
            <Timeline style={{ marginTop: '20px' }}>
              {trackList.length > 0 &&
                trackList.map((item, i) => {
                  return (
                    <Timeline.Item key={i}>
                      <p style={{ fontWeight: 700 }}>
                        {`${moment(item.accept_time).format(
                          'YYYY-MM-DD HH:mm'
                        )} - ${moment(item.accept_time).fromNow()}`}
                      </p>
                      <p>{item.remark}</p>
                    </Timeline.Item>
                  )
                })}
            </Timeline>
          </Descriptions.Item>
        </Descriptions>
      </Modal>
    )
  }
}

export default Track
