import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Modal, Tree } from 'antd'
import api from '../../api'
import IUser from '../../models/user'
import IPermission from '../../models/permission'

const { TreeNode } = Tree

const UserModal: React.FC<{
  visible: boolean
  onClose: () => void
  onSubmit: (users: IUser[]) => void
  permission: IPermission
}> = props => {
  const checked = useRef<IUser[]>([])
  const { visible, onClose, onSubmit, permission } = props
  const [list, setList] = useState<IUser[]>([])
  const [checkedKeys, setCheckedKeys] = useState<string[]>([])
  useEffect(() => {
    if (visible) {
      api.meta.getClerk().then(res => {
        setList(res.data)
      })
    }
  }, [visible])
  useEffect(() => {
    if (permission && permission.users) {
      setCheckedKeys(permission.users.map(x => `${x.id}`))
    }
  }, [permission])
  const handleCancel = useCallback(() => {
    onClose()
  }, [onClose])
  const handleCheck = useCallback(
    keys => {
      setCheckedKeys(keys)
      checked.current = list.filter(x => keys.includes(x.id.toString()))
    },
    [list]
  )
  const handleOk = useCallback(() => {
    onSubmit(checked.current)
  }, [onSubmit])
  return (
    <Modal
      title="设置用户"
      bodyStyle={{
        height: 'calc(100vh - 300px)',
        overflow: 'auto'
      }}
      visible={visible}
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Tree
        checkable={true}
        selectable={false}
        onCheck={handleCheck}
        checkedKeys={checkedKeys}
      >
        {list.map(item => {
          return <TreeNode title={item.realName} key={item.id.toString()} />
        })}
      </Tree>
    </Modal>
  )
}

export default UserModal
