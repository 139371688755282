import React, { PureComponent } from 'react'
import styles from './style.module.less'
import { Spin } from 'antd'
import api from './../../api'

interface IProps {
  fail: (msg: string) => void
  success: (data: { url: string }) => void
  path: string
}

class ImgUpload extends PureComponent<IProps> {
  state = {
    isUploading: false,
    isUploaded: false,
    url: ''
  }
  upload = e => {
    e.preventDefault()
    if (e.clipboardData.types[0] === 'Files') {
      const file = e.clipboardData.items[0].getAsFile()
      if (file.type.indexOf('image') < 0) {
        this.props.fail('文件选择错误')
        return
      }
      this.fileUpload(file)
    }
  }
  onDrop(e) {
    e.preventDefault()
    const file = e.dataTransfer.files[0]
    if (file.type.indexOf('image') < 0) {
      this.props.fail('文件选择错误')
      return
    }
    this.fileUpload(file)
  }
  fileUpload(file) {
    this.setState({
      isUploading: true
    })
    const formData = new FormData()
    formData.append('file', file)
    formData.append('path', this.props.path)
    api.file
      .imgUpload(formData)
      .then(res => {
        this.props.success(res.data)
        this.setState({
          isUploaded: true,
          url: res.data.url,
          isUploading: false
        })
      })
      .catch(err => {
        this.setState({
          isUploading: false
        })
      })
  }
  render() {
    const { isUploaded, url, isUploading } = this.state
    return (
      <div
        className={styles.imgUpload}
        onPaste={this.upload}
        contentEditable={true}
        suppressContentEditableWarning={true}
        onDragOver={e => {
          e.preventDefault()
        }}
        onDrop={e => {
          this.onDrop(e)
        }}
      >
        {isUploaded && (
          <div className={styles.imgWrapper}>
            <img src={url} className={styles.img} />
            <span
              className={styles.close}
              onClick={() => {
                this.setState({
                  isUploaded: false,
                  url: ''
                })
                this.props.success({ url: '' })
              }}
            >
              &times;
            </span>
          </div>
        )}
        {isUploading && <Spin size="large" />}
        {!isUploaded && <span>请将图片粘贴或拖动在此处</span>}
      </div>
    )
  }
}

export default ImgUpload
