import axios from 'axios'
import moment from 'moment'
import uuid from 'uuid/v4'

const host = 'https://cloud.timefile.cn'

export class SeafileClient {
  token = null
  repoId = null
  repoName = 'tdty'
  constructor() {
    this.token = '0487938fc5906400a38e295591507c1b7762850c'
    this.repoId = 'a2bb93f0-2916-459a-9467-bb1ffec36d8f'
  }

  private getUploadLink(p: string): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!this.repoId) {
        setTimeout(() => {
          this.getUploadLink(p)
            .then(res => {
              resolve(res)
            })
            .catch(err => {
              reject(err)
            })
        })
      } else {
        const path = `/api2/repos/${this.repoId}/upload-link/?p=${p}`
        this.createDirectory(p).then(() => {
          this.getRequest<string>(path)
            .then(res => {
              resolve(res)
            })
            .catch(err => {
              reject(err)
            })
        })
      }
    })
  }

  public uploadFile(
    file: File,
    onUploadProgress: (percent: number) => void = null
  ): Promise<{ url: string }> {
    const newFile = new File([file], `temp.cdr`)
    return new Promise((resolve, reject) => {
      const path = moment().format('/YYYY/MM/DD')
      this.getUploadLink(path).then(url => {
        const data = new FormData()
        data.append('file', newFile)
        data.append('parent_dir', path)
        data.append('relative_path', '')
        data.append('replace', '1')
        this.postRequest(url, data, true, e => {
          if (onUploadProgress) {
            onUploadProgress(Math.ceil((e.loaded / e.total) * 10000) / 100)
          }
        }).then(res => {
          this.rename(`${path}/${newFile.name}`, file.name).then((fileUrl) => {
            resolve({
              url: fileUrl
            })
          }).catch(() => {
            resolve({
              url: `${path}/${file.name}`
            })
          })
        })
      })
    })
  }

  public downloadFile(file: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const path = `/api2/repos/${this.repoId}/file/?p=${file}`
      this.getRequest<string>(path).then(res => {
        resolve(res)
      }).catch(e => {
        reject(e)
      })
    })
  }

  public rename(file: string, newname: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const path = `/api/v2.1/repos/${this.repoId}/file/?p=${file}`
      const data = new FormData()
      data.append('operation', 'rename')
      data.append('newname', newname)
      this.postRequest<{ parent_dir: string; obj_name: string }>(path, data)
        .then(res => {
          resolve(`${res.parent_dir}/${res.obj_name}`)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  private createSingleDirectory(p: string) {
    return new Promise((resolve, reject) => {
      const path = `/api2/repos/${this.repoId}/dir/?p=${p}`
      const data = new FormData()
      data.append('operation', 'mkdir')
      this.postRequest(path, data)
        .then(() => {
          resolve(true)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  private createDirectory(path: string, index: number = 0) {
    return new Promise((resolve, reject) => {
      const arr = path.split('/')
      const newPath = arr.slice(0, arr.length - index).join('/')
      if (newPath === '') {
        const newIndex = index - 1
        this.createSingleDirectory(
          arr.slice(0, arr.length - newIndex).join('/')
        ).then(() => {
          this.createDirectory(path, newIndex).then(res => {
            resolve(true)
          })
        })
      } else {
        this.checkDirectory(newPath)
          .then(() => {
            if (index === 0) {
              resolve(true)
            } else {
              const newIndex = index - 1
              this.createSingleDirectory(
                arr.slice(0, arr.length - newIndex).join('/')
              ).then(() => {
                this.createDirectory(path, newIndex).then(res => {
                  resolve(true)
                })
              })
            }
          })
          .catch(() => {
            this.createDirectory(path, index + 1).then(() => {
              resolve(true)
            })
          })
      }
    })
  }

  public checkFile(p: string) {
    const path = `/api2/repos/${this.repoId}/file/detail/?p=${p}`
    return this.getRequest(path)
  }

  private checkDirectory(p: string) {
    return new Promise((resolve, reject) => {
      const path = `/api/v2.1/repos/${this.repoId}/dir/detail/?path=${p}`
      this.getRequest(path)
        .then(() => {
          resolve(true)
        })
        .catch(() => {
          reject()
        })
    })
  }

  private postRequest<T>(
    path: string,
    data: any,
    isDirectPath: boolean = false,
    onUploadProgress: (e: any) => void = null
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      const url = isDirectPath ? path : `${host}${path}`
      const options = onUploadProgress
        ? {
          onUploadProgress: (e: any) => {
            onUploadProgress(e)
          }
        }
        : null
      axios
        .post<T>(url, data, {
          headers: { Authorization: `Token ${this.token}` },
          ...options
        })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  private getRequest<T>(path: string): Promise<T> {
    return new Promise((resolve, reject) => {
      axios
        .get<T>(`${host}${path}`, {
          headers: { Authorization: `Token ${this.token}` }
        })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}

export const seafileClient = new SeafileClient()
