import { Backend, IApi } from './backend'
import { AxiosResponse } from 'axios'
import IAddress from './../models/address'
import IExpress from './../models/express'

export default class Auth extends Backend {
  static imgUpload(data: FormData): Promise<AxiosResponse<{ url: string }>> {
    const api: IApi = {
      url: '/api/file/imgUpload',
      method: 'post',
      data
    }
    return this.request<{ url: string }>(api)
  }

  static cdrUpload(
    data: FormData
  ): Promise<AxiosResponse<{ url: string; fileName: string }>> {
    const api: IApi = {
      url: '/api/file/cdrUpload',
      method: 'post',
      data
    }
    return this.request<{ url: string; fileName: string }>(api)
  }

  static addressAnalysis(data: FormData): Promise<AxiosResponse<IAddress>> {
    const api: IApi = {
      url: '/api/file/addressAnalysis',
      method: 'post',
      data
    }
    return this.request<IAddress>(api)
  }

  static expressUpload(data: FormData): Promise<AxiosResponse<IExpress>> {
    const api: IApi = {
      url: '/api/file/expressUpload',
      method: 'post',
      data
    }
    return this.request<IExpress>(api)
  }

  static getSeafileToken(): Promise<AxiosResponse<string>> {
    const api: IApi = {
      url: '/api/file/getSeafileToken',
      method: 'get'
    }
    return this.request<string>(api)
  }
}
