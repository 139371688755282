import user from './user'
import role from './role'
import meta from './meta'
import menu from './menu'
import link from './link'
import auth from './auth'
import order from './order'
import payment from './payment'
import paymentDetail from './paymentDetail'
import product from './product'
import address from './address'
import file from './file'
import sessionStore from './sessionStore'
import express from './express'
import wechat from './wechat'
import sender from './sender'
import out from './out'
import outCategory from './outCategory'
import design from './design'
import invoice from './invoice'
import company from './company'
import inInvoice from './inInvoice'
import permission from './permission'
import checkout from './checkout'

export default {
  user,
  role,
  meta,
  menu,
  link,
  auth,
  order,
  payment,
  paymentDetail,
  product,
  address,
  file,
  sessionStore,
  express,
  wechat,
  sender,
  out,
  outCategory,
  design,
  invoice,
  company,
  inInvoice,
  permission,
  checkout
}

export interface IRequest {
  pageSize: number
  page: number
  keyword?: string
  type?: string
}

export interface IAddressRequest extends IRequest {
  province?: string
  city?: string
  area?: string
}
