import { Backend, IApi } from './backend'
import { AxiosResponse } from 'axios'
import { IRequest } from './index'
import IOrder from './../models/order'
import ISender from './../models/sender'
import IAddress from './../models/address'
import IExpress from './../models/express'

export default class Order extends Backend {
  static getTableData(
    request: IRequest
  ): Promise<AxiosResponse<{ rows: IOrder[]; count: number }>> {
    const api: IApi = {
      url: '/api/order/tableData',
      method: 'get',
      data: { request }
    }
    return this.request<{ rows: IOrder[]; count: number }>(api)
  }
  static createOrUpdate(data: any): Promise<AxiosResponse<IOrder>> {
    const api: IApi = {
      url: '/api/order/createOrUpdate',
      method: 'post',
      data
    }
    return this.request<IOrder>(api)
  }
  static delete(id: number): Promise<AxiosResponse> {
    const api: IApi = {
      url: `/api/order/${id}`,
      method: 'delete'
    }
    return this.request(api)
  }
  static getViewList(
    data: any
  ): Promise<
    AxiosResponse<{
      list: Array<{ date: string; orders: Order[] }>
      sum: number
      count: number
      paid: number
    }>
  > {
    const api: IApi = {
      url: '/api/order/getViewList',
      method: 'get',
      data
    }
    return this.request<{
      list: Array<{ date: string; orders: IOrder[] }>
      sum: number
      count: number
      paid: number
    }>(api)
  }

  static getCustomerUnpaidOrderList(
    customerId: number
  ): Promise<AxiosResponse<IOrder[]>> {
    const api: IApi = {
      url: '/api/order/getCustomerUnpaidOrderList',
      method: 'get',
      data: { customerId }
    }
    return this.request<IOrder[]>(api)
  }

  static detail(id: number): Promise<AxiosResponse<IOrder>> {
    const api: IApi = {
      url: '/api/order/detail',
      method: 'get',
      data: { id }
    }
    return this.request<IOrder>(api)
  }

  static getUnpaidOrder(): Promise<AxiosResponse<IOrder[]>> {
    const api: IApi = {
      url: '/api/order/getUnpaidOrder',
      method: 'get'
    }
    return this.request<IOrder[]>(api)
  }

  static getBill(
    customerId: number,
    startTime: Date,
    endTime: Date
  ): Promise<AxiosResponse<{ pdf: string; excel: string }>> {
    const api: IApi = {
      url: '/api/order/getBill',
      method: 'get',
      data: { customerId, startTime, endTime }
    }
    return this.request<{ pdf: string; excel: string }>(api)
  }

  static analysis(
    startTime: Date,
    endTime: Date
  ): Promise<AxiosResponse<IOrder[]>> {
    const api: IApi = {
      url: '/api/order/analysis',
      method: 'get',
      data: { startTime, endTime }
    }
    return this.request<IOrder[]>(api)
  }

  static printExpress(
    sender: ISender,
    address: IAddress,
    PayType: number,
    ExpType: number,
    GoodsName: string,
    Remark: string,
    order?: Order
  ): Promise<AxiosResponse<IExpress>> {
    const api: IApi = {
      url: '/api/order/printExpress',
      method: 'post',
      data: { order, sender, address, PayType, ExpType, GoodsName, Remark }
    }
    console.log('printExpress', api)
    return this.request<IExpress>(api)
  }

  static getPrintContent(id: string) {
    const api: IApi = {
      url: '/api/order/getPrintContent',
      method: 'get',
      data: { id }
    }
    return this.request(api)
  }

  static getSvg(path: string): Promise<AxiosResponse<string>> {
    const api: IApi = {
      url: '/api/order/getSvg',
      method: 'get',
      data: { path }
    }
    return this.request(api)
  }

  static getOrderByMonth(
    year: number,
    month: number
  ): Promise<AxiosResponse<IOrder[]>> {
    const api: IApi = {
      url: '/api/order/getOrderByMonth',
      method: 'get',
      data: { year, month }
    }
    return this.request(api)
  }

  static editCdrFile(
    id: number,
    fileName: string
  ): Promise<AxiosResponse<IOrder>> {
    const api: IApi = {
      url: '/api/order/editCdrFile',
      method: 'put',
      data: { id, fileName }
    }
    return this.request(api)
  }
}
