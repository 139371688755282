class XingCai {
  constructor() {
    this.color = '0,0,0,10'
  }
  type = 0
  name = ''
  height = 0
  width = 0
  color = ''
}

export const XCType = {
  面板: 1,
  槽板: 2,
  边轨: 3,
  中边轨: 4
}

export class MianBan extends XingCai {
  constructor(height: number, width: number = 0) {
    super()
    this.height = height
    this.name = `${height}面板`
    this.type = XCType.面板
    this.width = width
  }
}

export class BianGui extends XingCai {
  constructor(width: number, name: string, height: number = 0) {
    super()
    this.width = width
    this.name = name
    this.type = XCType.边轨
    this.height = height
  }
}

export class ZhongBianGui extends XingCai {
  constructor(width: number, name: string) {
    super()
    this.width = width
    this.name = name
    this.type = XCType.中边轨
  }
}

export class CaoBan extends XingCai {
  constructor(height: number, width: number = 0) {
    super()
    this.height = height
    this.name = `${height}槽板`
    this.type = XCType.槽板
    this.width = width
  }
}

export const MianBanList = [
  7,
  10,
  15,
  20,
  25,
  30,
  40,
  45,
  50,
  60,
  75,
  80,
  90,
  100,
  120,
  135,
  150,
  180,
  210,
  240
].map(item => {
  return new MianBan(item)
})

export const BianGuiList = [
  {
    name: 'L边轨',
    width: 1
  },
  {
    name: '小方轨',
    width: 6
  },
  {
    name: '小圆轨',
    width: 15
  },
  {
    name: '小椭圆轨',
    width: 30
  }
].map(item => {
  return new BianGui(item.width, item.name)
})

export const CaoBanList = [25, 30, 45, 50, 75].map(item => {
  return new CaoBan(item)
})

export const ZhongBianGuiList = [
  { width: 2, name: '1号中边轨' },
  { width: 6, name: '2号中边轨' }
].map(item => {
  return new ZhongBianGui(item.width, item.name)
})
