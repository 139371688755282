import React, { PureComponent } from 'react'
import { Form as AntdForm, Input, Select, message } from 'antd'
import { FormComponentProps } from 'antd/es/form'
import api from './../../api'
import styles from './style.module.less'
import IExpress from './../../models/express'
import ExpressUpload from './../../components/upload/expressUpload'

interface IReciveProps extends FormComponentProps {
  onRef: (form: Form) => void
  complete: (express: IExpress) => void
  validateFail: () => void
  data: IExpress
}

class Form extends PureComponent<IReciveProps, any> {
  constructor(props: IReciveProps) {
    super(props)
  }
  submit = () => {
    this.props.form.validateFields((err: any, values: any) => {
      if (err) {
        this.props.validateFail()
      } else {
        api.express
          .createOrUpdate(values)
          .then(res => {
            if (res.status === 200) {
              this.props.complete(res.data)
            }
          })
          .catch(() => {
            this.props.validateFail()
          })
      }
    })
  }
  componentDidMount() {
    this.props.onRef(this)
  }
  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <AntdForm
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        labelAlign="right"
        className={styles.form}
      >
        <AntdForm.Item label="" className={styles.displayNone}>
          {getFieldDecorator('id', {
            rules: [{ required: false, message: '' }],
            initialValue: this.props.data.id
          })(<Input placeholder="" />)}
        </AntdForm.Item>
        <AntdForm.Item label="快递名称" className={styles.displayNormal}>
          {getFieldDecorator('expressName', {
            rules: [{ required: true, message: '快递名称' }],
            initialValue: this.props.data.expressName
          })(
            <Select placeholder="快递名称">
              <Select.Option value="中通">中通</Select.Option>
              <Select.Option value="顺丰">顺丰</Select.Option>
              <Select.Option value="德邦">德邦</Select.Option>
              <Select.Option value="圆通">圆通</Select.Option>
              <Select.Option value="其他">其他</Select.Option>
            </Select>
          )}
        </AntdForm.Item>
        <AntdForm.Item label="快递单号" className={styles.displayNormal}>
          {getFieldDecorator('expressNum', {
            rules: [{ required: true, message: '快递单号' }],
            initialValue: this.props.data.expressNum
          })(<Input placeholder="快递单号" />)}
        </AntdForm.Item>
        <AntdForm.Item label="发件人姓名" className={styles.displayNormal}>
          {getFieldDecorator('senderName', {
            rules: [{ required: false, message: '发件人姓名' }],
            initialValue: this.props.data.senderName
          })(<Input placeholder="发件人姓名" />)}
        </AntdForm.Item>
        <AntdForm.Item label="发件人地址" className={styles.displayNormal}>
          {getFieldDecorator('senderAddress', {
            rules: [{ required: false, message: '发件人地址' }],
            initialValue: this.props.data.senderAddress
          })(<Input placeholder="发件人地址" />)}
        </AntdForm.Item>
        <AntdForm.Item label="发件人电话" className={styles.displayNormal}>
          {getFieldDecorator('senderPhone', {
            rules: [{ required: false, message: '发件人电话' }],
            initialValue: this.props.data.senderPhone
          })(<Input placeholder="发件人电话" />)}
        </AntdForm.Item>
        <AntdForm.Item label="收件人姓名" className={styles.displayNormal}>
          {getFieldDecorator('receiverName', {
            rules: [{ required: false, message: '收件人姓名' }],
            initialValue: this.props.data.receiverName
          })(<Input placeholder="收件人姓名" />)}
        </AntdForm.Item>
        <AntdForm.Item label="收件人地址" className={styles.displayNormal}>
          {getFieldDecorator('receiverAddress', {
            rules: [{ required: false, message: '收件人地址' }],
            initialValue: this.props.data.receiverAddress
          })(<Input placeholder="收件人地址" />)}
        </AntdForm.Item>
        <AntdForm.Item label="收件人电话" className={styles.displayNormal}>
          {getFieldDecorator('receiverPhone', {
            rules: [{ required: false, message: '收件人电话' }],
            initialValue: this.props.data.receiverPhone
          })(<Input placeholder="收件人电话" />)}
        </AntdForm.Item>
        <AntdForm.Item label="图片" className={styles.displayNormal}>
          {getFieldDecorator('pic', {
            rules: [{ required: false, message: '图片' }],
            initialValue: this.props.data.pic
          })(<Input placeholder="图片" disabled={true} />)}
        </AntdForm.Item>
        <AntdForm.Item label="上传图片" className={styles.displayNormal}>
          <ExpressUpload
            fail={msg => {
              message.warning(msg)
            }}
            success={express => {
              this.props.form.setFieldsValue({
                senderName: express.senderName,
                senderPhone: express.senderPhone,
                senderAddress: express.senderAddress,
                receiverName: express.receiverName,
                receiverPhone: express.receiverPhone,
                receiverAddress: express.receiverAddress,
                pic: express.pic,
                expressNum: express.expressNum,
                expressName: express.expressName
              })
            }}
          />
        </AntdForm.Item>
      </AntdForm>
    )
  }
}

export default AntdForm.create<IReciveProps>()(Form)
