import React, { PureComponent } from 'react'
import { Button } from 'antd'
import * as qrCode from 'qrcode'
import jsBarCode from 'jsbarcode'
import moment from 'moment'
import IExpress, { ISFData } from './../../models/express'
import api from '../../api'
import styles from './print.module.less'

interface IProps {
  match: {
    params: { id: number; childId: number }
  }
}

export default class PrintExpress extends PureComponent<IProps> {
  state = {
    qrCodeUrl: '',
    detail: {} as IExpress,
    expressNum: ''
  }
  componentDidMount() {
    this.getDetail()
  }
  getDetail() {
    const childId = this.props.match.params.childId
    api.express.detail(this.props.match.params.id).then(res => {
      const { data } = res
      this.setState({ detail: data }, () => {
        // 是否子单号
        const expressNum = childId ? data.childNum[childId] : data.expressNum
        this.setState({ expressNum })
        this.getBarCode(expressNum.slice(2))
        if (childId) {
          this.getQrCode(
            data.sfData.twoDimensionCode.replace(/SF\d{13}/, expressNum)
          )
        } else {
          this.getQrCode(data.sfData.twoDimensionCode)
        }
      })
    })
  }
  getBarCode(expressNum: string) {
    jsBarCode('#barCode', expressNum, {
      width: 2,
      height: 54,
      displayValue: false,
      margin: 0,
      marginLeft: 10,
      marginRight: 10
    })
  }
  getQrCode(text: string) {
    qrCode.toDataURL(text, { margin: 0, scale: 2.5 }).then(url => {
      this.setState({ qrCodeUrl: url })
    })
  }
  expressNumFormat(text: string) {
    return `SF ${text.slice(2, 5)} ${text.slice(5, 8)} ${text.slice(
      8,
      11
    )} ${text.slice(11)}`
  }
  render() {
    const { detail, qrCodeUrl, expressNum } = this.state
    const childId = this.props.match.params.childId
    const printProduct =
      detail.order && detail.order.products.length > 0 && detail.remark === ''
    return (
      <div className={styles.printExpress}>
        {detail.id && (
          <div className={styles.sfExpress}>
            <div className={styles.logoLine}>
              <img
                src={require('./../../assets/imgs/sf/sflogo.jpg')}
                alt="sfLogo"
                className={styles.logo}
              />
              <img
                src={require('./../../assets/imgs/sf/qiao.jpg')}
                alt="sfLogo"
                className={styles.phone}
              />
              <div className={styles.printInfo}>
                ZJ 第1次打印 打印时间 {moment().format('YYYY-MM-DD HH:mm')}
              </div>
            </div>
            <div className={styles.numLine}>
              <div className={styles.barCodeWrapper}>
                <canvas id="barCode" className={styles.barCode} />
                {childId && (
                  <div className={styles.expressNum}>
                    <div>
                      母单号: {this.expressNumFormat(detail.expressNum)}
                    </div>
                    <div>子单号: {this.expressNumFormat(expressNum)}</div>
                  </div>
                )}
                {!childId && (
                  <div className={styles.expressNum}>
                    {this.expressNumFormat(expressNum)}
                  </div>
                )}
              </div>

              {/* {detail.sfData.proCode && (
                <img
                  src={require(`./../../assets/imgs/sf/${detail.sfData.proCode}.jpg`)}
                  alt="expressWay"
                  className={styles.expressWay}
                />
              )} */}
              <div className={styles.proCode}>{detail.sfData.proCode}</div>
            </div>
            <div className={styles.receiveBlock}>
              <div className={styles.dest}>{detail.sfData.destRouteLabel}</div>
              <div className={styles.receiveInfo}>
                <img
                  src={require('./../../assets/imgs/sf/so.jpg')}
                  alt=""
                  className={styles.receiveImg}
                />
                <div className={styles.detail}>
                  <p>
                    {detail.receiverName}{' '}
                    {detail.receiverPhone.replace(
                      /^(\d{3})\d{4}(\d{4})$/,
                      '$1****$2'
                    )}
                  </p>
                  <p>{detail.receiverAddress}</p>
                </div>
              </div>
            </div>
            <div className={styles.otherBlock}>
              <div className={styles.block1}>
                <div className={styles.payType}>
                  {detail.payType === 1 ? '寄付月结' : '到付'}
                </div>
                <div className={styles.codingMapping}>
                  {detail.sfData.codingMapping}
                </div>
              </div>
              <div className={styles.block2}>
                <img src={qrCodeUrl} alt="" className={styles.qrCode} />
              </div>
              <div className={styles.block3}>已验视</div>
              <div className={styles.block4}>
                <div className={styles.abFlag}>
                  {detail.sfData.abFlag && (
                    <img
                      src={require(`./../../assets/imgs/sf/${detail.sfData.abFlag}.jpg`)}
                      alt=""
                      className={styles.img}
                    />
                  )}
                </div>
                <div className={styles.codingMappingOut}>
                  {detail.sfData.codingMappingOut}
                </div>
              </div>
            </div>
            <div className={styles.sendLine}>
              <img
                src={require('./../../assets/imgs/sf/ji.jpg')}
                alt=""
                className={styles.img}
              />
              <div className={styles.detail}>
                <p>
                  {detail.senderName}{' '}
                  {detail.senderPhone.replace(
                    /^(\d{3})\d{4}(\d{4})$/,
                    '$1****$2'
                  )}
                </p>
                <p>{detail.senderAddress}</p>
              </div>
            </div>
            <div className={styles.orderIdLine}>
              {detail.order
                ? `${detail.order.customer.realName}-
                ${detail.order.orderId.toString().slice(4, 6)}-
                ${detail.order.orderId.toString().slice(6)}-${
                    detail.receiverName
                  }-
                ${detail.expType === 1 ? '空运' : '陆运'}`
                : `${detail.receiverName}-${
                    detail.expType === 1 ? '空运' : '陆运'
                  }`}
            </div>
            <div className={styles.productInfo}>
              {printProduct &&
                detail.order.products.slice(0, 14).map(product => (
                  <p key={product.id}>
                    {product.specification} {product.name} * {product.count}
                  </p>
                ))}
              {!printProduct && <p>{detail.remark}</p>}
            </div>
          </div>
        )}
        <Button
          type="primary"
          icon="printer"
          onClick={() => {
            window.print()
          }}
          id="printBtn"
          className={styles.printBtn}
        >
          打印
        </Button>
      </div>
    )
  }
}
