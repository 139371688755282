import React, { PureComponent } from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { Table, Divider, Form, Input, Col, Button, message, Modal } from 'antd'
import moment from 'moment'
import DataModal from './modal'
import api from './../../api'
import styles from './style.module.less'
import IOut from './../../models/out'
import IUser from '../../models/user'
import ImageView from './../../components/imageView'
const { confirm } = Modal

class OutPage extends PureComponent<{ user: IUser }> {
  state = {
    loading: true,
    list: [],
    form: {
      pageSize: 15,
      page: 1,
      keyword: ''
    },
    total: 0,
    modalOpen: false,
    modalTitle: '',
    updateItem: {} as IOut
  }
  columns = [
    {
      title: '时间',
      dataIndex: 'createTime',
      key: 'createTime',
      align: 'center' as 'center',
      width: 140,
      render(text) {
        return moment(text).format('YYYY-MM-DD HH:mm')
      }
    },
    {
      title: '提交人',
      dataIndex: 'user.realName',
      key: 'user',
      align: 'center' as 'center',
      width: 100
    },
    {
      title: '名称/分类',
      width: '200px',
      align: 'center' as 'center',
      render(text: string, record: IOut) {
        return (
          <div>
            <div>{record.name}</div>
            <div>{record.outCategory.name}</div>
          </div>
        )
      }
    },
    // {
    //   title: '单价',
    //   dataIndex: 'price',
    //   key: 'price',
    //   align: 'center' as 'center'
    // },
    // {
    //   title: '数量',
    //   dataIndex: 'count',
    //   key: 'count',
    //   align: 'center' as 'center'
    // },
    {
      title: '价格',
      dataIndex: 'sum',
      key: 'sum',
      align: 'center' as 'center',
      width: '200px',
      render(text: string, record: IOut) {
        return (
          <span>{`${record.price} * ${record.count} = ${record.sum}`}</span>
        )
      }
    },
    {
      title: '图片',
      dataIndex: 'pic',
      key: 'pic',
      width: '150px',
      align: 'center' as 'center',
      render(text: string, record: IOut) {
        if (record.pic && record.pic.length > 0) {
          return (
            <Button
              type="primary"
              icon="file-image"
              onClick={() => {
                ImageView.show(
                  record.pic.map(item => {
                    return { src: item, title: record.name }
                  })
                )
              }}
            >
              查看图片
            </Button>
          )
        } else {
          return <span>无图片</span>
        }
      }
    },
    // {
    //   title: '语音',
    //   dataIndex: 'voice',
    //   key: 'voice',
    //   width: '150px',
    //   align: 'center' as 'center',
    //   render(text: string, record: IOut) {
    //     if (record.voice) {
    //       return (
    //         <Button type="primary" icon="audio" onClick={() => {}}>
    //           播放音频
    //         </Button>
    //       )
    //     } else {
    //       return <span>无录音</span>
    //     }
    //   }
    // },
    {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark',
      align: 'center' as 'center'
    },
    {
      title: '审核状态',
      align: 'center' as 'center',
      render(text: string, record: IOut) {
        if (record.verifies.every(x => x.status === '审核通过')) {
          return <span className={styles.green}>审核通过</span>
        } else {
          return record.verifies.map(item => {
            return (
              <div className={styles.verifyItem} key={item.id}>
                <div className={styles.verifyName}>{item.user.realName}</div>
                <div
                  className={classNames(styles.verifyStatus, {
                    [styles.green]: item.status === '审核通过',
                    [styles.yellow]: item.status === '待审核',
                    [styles.red]: item.status === '审核未通过'
                  })}
                >
                  {item.status}
                  {item.status === '审核未通过' ? `(${item.remark})` : ''}
                </div>
              </div>
            )
          })
        }
      }
    },
    {
      title: '操作',
      key: 'action',
      align: 'center' as 'center',
      width: 200,
      render: (text: any, record: IOut) => {
        const { user } = this.props
        if (record.user.id === user.id) {
          return (
            <span>
              {!record.verifies.some(
                x => x.status !== '待审核' && x.user.id !== user.id
              ) && (
                <>
                  <a
                    onClick={() => {
                      this.update(record)
                    }}
                  >
                    编辑
                  </a>
                  <Divider type="vertical" />
                  <a
                    onClick={() => {
                      this.delete(record)
                    }}
                  >
                    删除
                  </a>
                </>
              )}
              {record.status === '审核通过' && (
                <>
                  <Divider type="vertical" />
                  <a
                    onClick={() => {
                      this.revoke(record)
                    }}
                  >
                    撤销
                  </a>
                </>
              )}
            </span>
          )
        } else {
          return <span>无权操作</span>
        }
      }
    }
  ]
  revoke = (record: IOut) => {
    confirm({
      title: '确定撤销？',
      content: `确定要撤销${record.user.realName}提交的总金额为${record.sum}元的购买${record.name}的支出吗？`,
      onOk: () => {
        const out = {
          ...record,
          id: null,
          count: -1 * record.count,
          sum: -1 * record.sum,
          verifies: [],
          name: `【撤销】${record.name}`
        }
        api.out.createOrUpdate(out).then(() => {
          message.success('撤销成功')
          this.getList()
        })
      }
    })
  }
  update = (record: IOut) => {
    const { user } = this.props
    if (
      record.verifies.some(x => x.status !== '待审核' && x.user.id !== user.id)
    ) {
      message.error('该支出已进入审核阶段，不能修改')
      return
    }
    this.setState({
      updateItem: record,
      modalOpen: true
    })
  }
  delete = (record: IOut) => {
    const { user } = this.props
    if (
      record.verifies.some(x => x.status === '审核未通过') ||
      record.verifies.every(
        x =>
          x.status === '待审核' ||
          (x.status === '审核通过' && x.user.id === user.id)
      )
    ) {
      confirm({
        title: '确定删除？',
        content: '确定删除该项目？',
        onOk: () => {
          api.out.delete(Number(record.id)).then(res => {
            if (res.status === 200) {
              message.success('删除成功')
              this.getList()
            }
          })
        },
        onCancel() {}
      })
    } else {
      message.error('该支出已进入审核阶段，不能删除')
      return
    }
  }
  getList() {
    this.setState({
      loading: true
    })
    api.out.getTableData(this.state.form).then(res => {
      if (res.status === 200) {
        this.setState({
          list: res.data.rows,
          total: res.data.count,
          loading: false
        })
      }
    })
  }
  createClick = () => {
    this.setState({
      updateItem: {},
      modalTitle: '新建',
      modalOpen: true
    })
  }
  searchClick = () => {
    this.refresh()
  }
  refresh() {
    this.getList()
  }
  componentWillMount() {
    this.getList()
  }
  render() {
    return (
      <div className={styles.index}>
        <Button
          type="primary"
          loading={this.state.loading}
          icon="sync"
          className={styles.refreshBtn}
          onClick={() => {
            this.refresh()
          }}
        >
          刷新
        </Button>
        <Form
          className={styles.form}
          labelCol={{
            span: 6
          }}
          wrapperCol={{
            span: 16
          }}
          layout="horizontal"
        >
          <Col span={8}>
            <Form.Item label="搜索词">
              <Input
                placeholder="请输入搜索词"
                onChange={e => {
                  this.setState({
                    form: {
                      page: this.state.form.page,
                      pageSize: this.state.form.pageSize,
                      keyword: e.target.value
                    }
                  })
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <div>1. 其他人未审核的，可编辑、删除</div>
            <div>2. 已通过审核的，可撤销</div>
            <div>3. 未通过审核的，可删除</div>
            <div>4. 仅能操作自己提交的</div>
          </Col>
        </Form>
        <Col span={24} className={styles.actionBtn}>
          <Button
            type="primary"
            htmlType="submit"
            icon="search"
            onClick={this.searchClick}
          >
            搜索
          </Button>
        </Col>
        <Col span={24} className={styles.addBtn}>
          <Button type="primary" icon="plus" onClick={this.createClick}>
            新建
          </Button>
        </Col>
        <Table
          bordered
          className={styles.table}
          columns={this.columns}
          dataSource={this.state.list}
          size="middle"
          rowKey="id"
          loading={this.state.loading}
          pagination={{
            position: 'bottom',
            pageSize: this.state.form.pageSize,
            total: this.state.total,
            pageSizeOptions: ['15', '30', '50'],
            current: this.state.form.page,
            showSizeChanger: true,
            onChange: (page, pageSize) => {
              const { form } = this.state
              form.page = page
              form.pageSize = pageSize ? pageSize : 1
              this.setState({
                form
              })
              this.getList()
            },
            showTotal: (total, range) => {
              return `共${total}条数据，当前显示${range}`
            },
            onShowSizeChange: (current, size) => {
              const { form } = this.state
              form.pageSize = size
              this.setState({
                form
              })
              this.getList()
            }
          }}
        />
        {this.state.modalOpen && (
          <DataModal
            title={this.state.modalTitle}
            onClose={() => {
              this.setState({ modalOpen: false })
            }}
            complete={() => {
              this.setState({ modalOpen: false })
              this.getList()
            }}
            updateItem={this.state.updateItem}
          />
        )}
      </div>
    )
  }
}

export default connect((state: { User: IUser }) => ({ user: state.User }))(
  OutPage
)
