import React, { useCallback, useEffect, useRef, useState } from 'react'
import './index.less'

interface IProps {
  onClick?: (e) => void
  src: string
  scrollDom: HTMLElement
  className?: string
  alt?: string
}

const DEFAULT_IMG = 'https://storage.bjtdty.com/order/default.jpg'

export const LazyImage: React.FC<IProps> = (props: IProps) => {
  const dom = useRef<HTMLImageElement>()
  const [url, setUrl] = useState<string>(DEFAULT_IMG)
  const { onClick, src, scrollDom, className = '', alt = '' } = props
  const handleClick = useCallback(
    e => {
      if (!!onClick && typeof onClick === 'function') {
        onClick(e)
      }
    },
    [onClick]
  )
  const handleScroll = useCallback(() => {
    const windowHeight = window.innerHeight
    const rect = dom.current.getBoundingClientRect()
    if (rect.top <= windowHeight) {
      setUrl(src)
      scrollDom.removeEventListener('scroll', handleScroll)
    }
  }, [dom, src, scrollDom])
  useEffect(() => {
    handleScroll()
    scrollDom.addEventListener('scroll', handleScroll)
    return () => {
      scrollDom.removeEventListener('scroll', handleScroll)
    }
  }, [scrollDom, handleScroll])
  return (
    <img
      ref={dom}
      alt={alt}
      onClick={handleClick}
      className={`lazy-img ${className}`}
      src={url}
    />
  )
}
