import React, { useEffect, useCallback, useRef, useState } from 'react'
import { Form, Select, Button, message, Pagination } from 'antd'
import copy from 'copy-to-clipboard'
import api from '../../../api'
import './index.less'
import IAddress from '../../../models/address'

const KEY = 'f06c21eb7f2041739a7e884b9172afd7'
const PLUGIN = ['AMap.Scale', 'AMap.AdvancedInfoWindow', 'AMap.Geocoder']
const URL = `https://webapi.amap.com/maps?v=1.4.15&key=${KEY}&plugin=${PLUGIN.join(
  ','
)}&callback=mapLoad`

const PROVINCE = ['北京市']
const CITY = ['北京市']
const AREA = [
  '朝阳区',
  '东城区',
  '西城区',
  '丰台区',
  '海淀区',
  '通州区',
  '大兴区',
  '石景山区',
  '顺义区',
  '昌平区',
  '门头沟区',
  '密云区',
  '延庆区',
  '房山区',
  '怀柔区',
  '平谷区'
]

export default () => {
  const [province, setProvince] = useState('北京市')
  const [city, setCity] = useState('北京市')
  const [area, setArea] = useState('朝阳区')
  const [list, setList] = useState<IAddress[]>([])
  const map = useRef<AMap.Map>()
  const infoWindow = useRef<AMap.InfoWindow>()
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(15)
  const [total, setTotal] = useState(0)
  const getLocation = useCallback(
    (
      address: string,
      title: string
    ): Promise<{
      lng: number
      lat: number
      title: string
      address: string
    }> => {
      return new Promise((resolve, reject) => {
        const geocoder = new AMap.Geocoder({
          city: `${province}${city}`
        })
        geocoder.getLocation(address, (status, result) => {
          if (status === 'complete' && result.info === 'OK') {
            resolve({
              lng: result.geocodes[0].location.lng,
              lat: result.geocodes[0].location.lat,
              title,
              address
            })
          } else {
            reject(title)
          }
        })
      })
    },
    [province, city]
  )
  const makePoint = useCallback(
    (lng: number, lat: number, content: string, address: string) => {
      const point = new AMap.Marker({
        position: new AMap.LngLat(lng, lat),
        label: { content, offset: new AMap.Pixel(-16, -10) }
      })
      point.on('click', e => {
        infoWindow.current.setContent(address)
        infoWindow.current.open(map.current, e.target.getPosition())
      })
      return point
    },
    []
  )
  const getList = useCallback(
    (p: number, size: number) => {
      api.address
        .getTableData({ page: p, pageSize: size, province, city, area })
        .then(res => {
          setList(res.data.rows)
          setTotal(res.data.count)
          Promise.allSettled(
            res.data.rows.map(item => {
              const address = `${item.province}${item.city}${item.area}${item.detail}`
              return getLocation(address, item.name)
            })
          ).then(result => {
            console.log(result)
            const points = []
            const fails = []
            result.forEach(item => {
              if (item.status === 'fulfilled') {
                points.push(
                  makePoint(
                    item.value.lng,
                    item.value.lat,
                    item.value.title,
                    item.value.address
                  )
                )
              } else {
                fails.push(item.reason)
              }
            })
            if (fails.length > 0) {
              message.error(`${fails.join(',')}标记失败`)
            }
            map.current.clearMap()
            map.current.add(points)
            map.current.setFitView()
          })
        })
    },
    [province, city, area, getLocation, makePoint]
  )
  const mapLoad = useCallback(() => {
    map.current = new AMap.Map('map')
    const scale = new AMap.Scale()
    map.current.addControl(scale)
    infoWindow.current = new AMap.InfoWindow({ offset: new AMap.Pixel(0, -30) })
    getList(page, pageSize)
  }, [getList, page, pageSize])
  useEffect(() => {
    window.mapLoad = mapLoad
    const jsapi = document.createElement('script')
    jsapi.src = URL
    document.head.appendChild(jsapi)
  }, [mapLoad])
  const handleSubmit = useCallback(() => {
    getList(page, pageSize)
  }, [getList, page, pageSize])
  useEffect(() => {
    getList(page, pageSize)
    // eslint-disable-next-line
  }, [page, pageSize])
  const handleCopy = useCallback((item: IAddress) => {
    copy(`${item.province}${item.city}${item.area}${item.detail}`)
    message.success('复制成功')
  }, [])

  return (
    <div className="customer-address">
      <div className="left">
        <Form className="form">
          <Form.Item>
            <Select value={province} onChange={setProvince}>
              {PROVINCE.map((item, index) => (
                <Select.Option value={item} key={index}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Select value={city} onChange={setCity}>
              {CITY.map((item, index) => (
                <Select.Option value={item} key={index}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Select value={area} onChange={setArea}>
              {AREA.map((item, index) => (
                <Select.Option value={item} key={index}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" className="btn" onClick={handleSubmit}>
              查找
            </Button>
          </Form.Item>
        </Form>
        <div className="list">
          {list.map(item => (
            <div className="item" key={item.id}>
              <div className="title">
                <span className="name">{item.name}</span>
                <span className="phone">{item.phone}</span>
              </div>
              <div className="address">{`${item.province} ${item.city} ${item.area}`}</div>
              <div className="address">{item.detail}</div>
              <Button
                className="btn"
                type="primary"
                size="small"
                onClick={() => {
                  handleCopy(item)
                }}
              >
                复制
              </Button>
            </div>
          ))}
        </div>
        <div className="page">
          <Pagination
            size="small"
            total={total}
            showSizeChanger
            current={page}
            pageSize={pageSize}
            onChange={p => {
              setPage(p)
            }}
            onShowSizeChange={(p, size) => {
              setPageSize(size)
            }}
            pageSizeOptions={['15', '30', '100', '200', '500']}
          />
          <span>共{total}条</span>
        </div>
      </div>
      <div className="right">
        <div id="map" />
      </div>
    </div>
  )
}
