import { Backend, IApi } from './backend'
import { AxiosResponse } from 'axios'
import { IRequest } from './index'
import IExpress from './../models/express'

export interface ITrack {
  accept_time: string
  accept_address: string
  remark: string
  opcode: string
}

export default class Express extends Backend {
  static getTableData(
    request: IRequest
  ): Promise<AxiosResponse<{ rows: IExpress; count: number }>> {
    const api: IApi = {
      url: '/api/express/tableData',
      method: 'get',
      data: { request }
    }
    return this.request<{ rows: IExpress; count: number }>(api)
  }

  static getYGTableData(
    request: IRequest
  ): Promise<AxiosResponse<{ rows: IExpress; count: number }>> {
    const api: IApi = {
      url: '/api/express/YGTableData',
      method: 'get',
      data: { request }
    }
    return this.request<{ rows: IExpress; count: number }>(api)
  }

  static createOrUpdate(data: any): Promise<AxiosResponse<IExpress>> {
    const api: IApi = {
      url: '/api/express/createOrUpdate',
      method: 'post',
      data
    }
    return this.request<IExpress>(api)
  }
  static delete(id: number): Promise<AxiosResponse> {
    const api: IApi = {
      url: `/api/express/${id}`,
      method: 'delete'
    }
    return this.request(api)
  }
  // 追踪快递信息
  static track(
    expressNum: string,
    phoneNum: string
  ): Promise<AxiosResponse<ITrack[]>> {
    const api: IApi = {
      url: '/api/express/track',
      method: 'get',
      data: { expressNum, phoneNum }
    }
    return this.request<ITrack[]>(api)
  }
  // 快递详情
  static detail(id: number): Promise<AxiosResponse<IExpress>> {
    const api: IApi = {
      url: `/api/express/detail`,
      data: { id },
      method: 'get'
    }
    return this.request<IExpress>(api)
  }
  // 更新是否一格快递
  static updateYG(express: IExpress): Promise<AxiosResponse<IExpress>> {
    const api: IApi = {
      url: `/api/express/updateYG`,
      data: express,
      method: 'post'
    }
    return this.request<IExpress>(api)
  }
  // 撤销快递单
  static cancel(id: number): Promise<AxiosResponse<boolean>> {
    const api: IApi = {
      url: `/api/express/cancel`,
      data: { id },
      method: 'post'
    }
    return this.request<boolean>(api)
  }
  // 申请子单号
  static getChildNum(
    id: number,
    expressOrderId: string,
    count: number
  ): Promise<AxiosResponse<IExpress>> {
    const api: IApi = {
      url: `/api/express/getChildNum`,
      data: { id, expressOrderId, count },
      method: 'get'
    }
    return this.request<IExpress>(api)
  }
}
