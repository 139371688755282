import sessionStorage from '../utils/sessionStorage'
const { getArray, setObject, removeItem } = sessionStorage

export interface INav {
  title: string
  path: string
}

const states = {
  NavList: [{ title: '首页', path: '/' }] as INav[],
  ActiveKey: '/'
}

const NavList = (state = states.NavList, action) => {
  switch (action.type) {
    case 'TEST':
      return action.data
    case 'SET_NAV_LIST':
      setObject('navList', action.data)
      return state
    case 'CLEAR_NAV_LIST':
      removeItem('navList')
      return {}
    case 'PUSH_NAV':
      const exist = state.find(x => x.path === action.data)
      if (exist === undefined) {
        const list = getArray('navList') as INav[]
        const nav = list.find(x => x.path === action.data)
        if (nav) {
          state.push(nav)
        }
      }
      return state
    case 'PUSH_NAV_2':
      const exist2 = state.find(x => x.path === action.data.path)
      if (exist2 === undefined) {
        const list = getArray('navList') as INav[]
        const nav = list.find(x => x.path === action.data.path)
        if (nav) {
          state.push(nav)
        } else {
          setObject('navList', [...list, action.data])
          state.push(action.data)
        }
      }
      return state
    case 'DELETE_NAV':
      const index = state.findIndex(x => x.path === action.data)
      if (index > 0) {
        state.splice(index, 1)
      }
      return state
    default:
      return state
  }
}

const ActiveKey = (state = states.ActiveKey, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_KEY':
      return action.data
    default:
      return state
  }
}

export default {
  NavList,
  ActiveKey
}
