import { Backend, IApi } from './backend'
import { AxiosResponse } from 'axios'
import { IRequest } from './index'
import ISender from './../models/sender'

export default class Sender extends Backend {
  static getTableData(
    request: IRequest
  ): Promise<AxiosResponse<{ rows: ISender; count: number }>> {
    const api: IApi = {
      url: '/api/sender/tableData',
      method: 'get',
      data: { request }
    }
    return this.request<{ rows: ISender; count: number }>(api)
  }
  static createOrUpdate(data: any): Promise<AxiosResponse<ISender>> {
    const api: IApi = {
      url: '/api/sender/createOrUpdate',
      method: 'post',
      data
    }
    return this.request<ISender>(api)
  }
  static delete(id: number): Promise<AxiosResponse> {
    const api: IApi = {
      url: `/api/sender/${id}`,
      method: 'delete'
    }
    return this.request(api)
  }
  static list(): Promise<AxiosResponse<ISender[]>> {
    const api: IApi = {
      url: '/api/sender/list',
      method: 'get'
    }
    return this.request<ISender[]>(api)
  }
}
