import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import { Modal, Input, Form } from 'antd'

interface IProps {
  title: string
  label: string
  placeholder: string
  defaultValue?: string
  onComplete: (data: string) => void
  onClose: () => void
}

class Prompt extends PureComponent<IProps> {
  constructor(props: IProps) {
    super(props)
  }
  state = {
    visible: true,
    value: ''
  }
  static show(
    title: string = '输入',
    label: string = '请输入',
    placeholder: string = '请输入',
    defaultValue: string = ''
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let imgViewContainer = document.getElementById('prompt-container')
      if (!imgViewContainer) {
        imgViewContainer = document.createElement('div')
        imgViewContainer.id = 'prompt-container'
        document.body.appendChild(imgViewContainer)
      }
      const reactElement = ReactDOM.createPortal(
        <Prompt
          title={title}
          label={label}
          placeholder={placeholder}
          onComplete={data => {
            resolve(data)
          }}
          onClose={() => {
            reject(null)
          }}
          defaultValue={defaultValue}
        />,
        document.body,
        new Date().getTime().toString()
      )
      ReactDOM.render(reactElement, imgViewContainer)
    })
  }
  render() {
    const { visible } = this.state
    const { title, label, placeholder, defaultValue } = this.props
    return (
      <Modal
        title={title}
        visible={visible}
        onCancel={() => {
          this.setState({
            visible: false
          })
          this.props.onClose()
        }}
        width="600px"
        okText="提交"
        cancelText="取消"
        onOk={() => {
          this.setState({
            visible: false
          })
          this.props.onComplete(this.state.value)
        }}
      >
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          labelAlign="right"
        >
          <Form.Item label={label}>
            <Input
              placeholder={placeholder}
              onChange={e => {
                this.setState({
                  value: e.target.value
                })
              }}
              defaultValue={defaultValue}
            />
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Prompt
