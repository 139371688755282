import { Backend, IApi } from './backend'
import { AxiosResponse } from 'axios'
import { IAddressRequest } from './index'
import IAddress from './../models/address'

export default class Address extends Backend {
  static getTableData(
    request: IAddressRequest
  ): Promise<AxiosResponse<{ rows: IAddress[]; count: number }>> {
    const api: IApi = {
      url: '/api/address/tableData',
      method: 'get',
      data: { request }
    }
    return this.request<{ rows: IAddress[]; count: number }>(api)
  }
  static createOrUpdate(data: any): Promise<AxiosResponse<IAddress>> {
    const api: IApi = {
      url: '/api/address/createOrUpdate',
      method: 'post',
      data
    }
    return this.request<IAddress>(api)
  }
  static delete(id: number): Promise<AxiosResponse> {
    const api: IApi = {
      url: `/api/address/${id}`,
      method: 'delete'
    }
    return this.request(api)
  }

  static analysis(address: string): Promise<AxiosResponse<IAddress>> {
    const api: IApi = {
      url: `/api/address/analysis`,
      method: 'post',
      data: { address }
    }
    return this.request<IAddress>(api)
  }
  static list(): Promise<AxiosResponse<IAddress[]>> {
    const api: IApi = {
      url: '/api/address/list',
      method: 'get'
    }
    return this.request<IAddress[]>(api)
  }
}
