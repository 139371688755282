import React, { PureComponent } from 'react'
import styles from './style.module.less'
import { Spin } from 'antd'
import api from './../../api'
import IAddress from './../../models/address'

interface IProps {
  fail: (msg: string) => void
  success: (data: IAddress) => void
}

class ImgUpload extends PureComponent<IProps> {
  constructor(props: IProps) {
    super(props)
  }
  state = {
    isUploading: false
  }
  upload = e => {
    e.preventDefault()
    if (e.clipboardData.types[0] === 'Files') {
      const file = e.clipboardData.items[0].getAsFile()
      if (file.type.indexOf('image') < 0) {
        this.props.fail('文件选择错误')
        return
      }
      this.fileUpload(file)
    }
  }
  onDrop(e) {
    e.preventDefault()
    const file = e.dataTransfer.files[0]
    if (file.type.indexOf('image') < 0) {
      this.props.fail('文件选择错误')
      return
    }
    this.fileUpload(file)
  }
  fileUpload(file) {
    this.setState({
      isUploading: true
    })
    const formData = new FormData()
    formData.append('file', file)
    api.file
      .addressAnalysis(formData)
      .then(res => {
        this.props.success(res.data)
        this.setState({
          isUploading: false
        })
      })
      .catch(err => {
        this.setState({
          isUploading: false
        })
      })
  }
  render() {
    const { isUploading } = this.state
    return (
      <div
        className={styles.imgUpload}
        style={{ height: '120px' }}
        onPaste={this.upload}
        contentEditable={true}
        suppressContentEditableWarning={true}
        onDragOver={e => {
          e.preventDefault()
        }}
        onDrop={e => {
          this.onDrop(e)
        }}
      >
        {isUploading && <Spin size="large" />}
        {!isUploading && <span>请将含有收货地址的图片粘贴或拖动到此处</span>}
      </div>
    )
  }
}

export default ImgUpload
