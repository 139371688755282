import React, { PureComponent } from 'react'
import { Form as AntdForm, Input, Select, AutoComplete, message } from 'antd'
import { FormComponentProps } from 'antd/es/form'
import numeral from 'numeral'
import api from './../../api'
import styles from './style.module.less'
import IProduct from './../../models/product'
import ImgUpload from './../../components/upload/imgUpload'

interface IReciveProps extends FormComponentProps {
  onRef: (form: Form) => void
  complete: (product: IProduct) => void
  validateFail: () => void
  data: IProduct
}

class Form extends PureComponent<IReciveProps, any> {
  constructor(props: IReciveProps) {
    super(props)
  }
  state = {
    dataSource: [
      '平面科室牌',
      '弧形科室牌',
      '平面水牌',
      '弧形水牌',
      '平面面板',
      '弧形面板',
      '3mm亚克力',
      '5mm亚克力',
      '8mm亚克力',
      '10mm亚克力',
      '不锈钢',
      '铝板'
    ]
  }
  submit = () => {
    this.props.form.validateFields((err: any, values: any) => {
      if (err) {
        this.props.validateFail()
      } else {
        api.product
          .createOrUpdate(values)
          .then(res => {
            if (res.status === 200) {
              this.props.complete(res.data)
            }
          })
          .catch(() => {
            this.props.validateFail()
          })
      }
    })
  }
  componentDidMount() {
    this.props.onRef(this)
  }
  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <AntdForm
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        labelAlign="right"
        className={styles.form}
      >
        <AntdForm.Item label="" className={styles.displayNone}>
          {getFieldDecorator('id', {
            rules: [{ required: false, message: '' }],
            initialValue: this.props.data['id']
          })(<Input placeholder="" />)}
        </AntdForm.Item>
        <AntdForm.Item label="名称" className={styles.displayNormal}>
          {getFieldDecorator('name', {
            rules: [{ required: true, message: '名称' }],
            initialValue: this.props.data['name']
          })(
            <AutoComplete
              placeholder="名称"
              dataSource={this.state.dataSource}
              filterOption={(value, option) => {
                if (
                  option &&
                  typeof option.key === 'string' &&
                  option.key.indexOf(value) > -1
                ) {
                  return true
                }
                return false
              }}
            />
          )}
        </AntdForm.Item>
        <AntdForm.Item label="规格" className={styles.displayNormal}>
          {getFieldDecorator('specification', {
            rules: [{ required: true, message: '规格' }],
            initialValue: this.props.data['specification']
          })(<Input placeholder="规格" />)}
        </AntdForm.Item>
        <AntdForm.Item label="平米单价" className={styles.displayNormal}>
          {getFieldDecorator('pricepm2', {
            rules: [{ required: false, message: '平米单价' }],
            initialValue: 550
          })(<Input placeholder="平米单价" />)}
        </AntdForm.Item>
        <AntdForm.Item label="光泽度" className={styles.displayNormal}>
          {getFieldDecorator('gloss', {
            rules: [{ required: true, message: '光泽度' }],
            initialValue: this.props.data['gloss'] || '半光'
          })(
            <Select placeholder="光泽度">
              <Select.Option value="半光">半光</Select.Option>
              <Select.Option value="亮光">亮光</Select.Option>
              <Select.Option value="哑光">哑光</Select.Option>
              <Select.Option value="按实样">按实样</Select.Option>
              <Select.Option value="半光偏亮">半光偏亮</Select.Option>
              <Select.Option value="半光偏哑">半光偏哑</Select.Option>
            </Select>
          )}
        </AntdForm.Item>
        <AntdForm.Item label="单价" className={styles.displayNormal}>
          {getFieldDecorator('price', {
            rules: [{ required: true, message: '单价' }],
            initialValue: this.props.data['price']
          })(<Input placeholder="单价" addonBefore="￥" />)}
        </AntdForm.Item>
        <AntdForm.Item label="单位" className={styles.displayNormal}>
          {getFieldDecorator('unit', {
            rules: [{ required: true, message: '单位' }],
            initialValue: this.props.data['unit'] || '面'
          })(
            <Select placeholder="单位">
              <Select.Option value="面">面</Select.Option>
              <Select.Option value="套">套</Select.Option>
              <Select.Option value="平米">平米</Select.Option>
              <Select.Option value="个">个</Select.Option>
              <Select.Option value="千克">千克</Select.Option>
            </Select>
          )}
        </AntdForm.Item>
        <AntdForm.Item label="数量" className={styles.displayNormal}>
          {getFieldDecorator('count', {
            rules: [{ required: true, message: '数量' }],
            initialValue: this.props.data['count'] || '1'
          })(<Input placeholder="数量" />)}
        </AntdForm.Item>
        <AntdForm.Item label="总价" className={styles.displayNormal}>
          {getFieldDecorator('sum', {
            rules: [{ required: true, message: '总价' }],
            initialValue: this.props.data['sum']
          })(<Input placeholder="总价" disabled={true} addonBefore="￥" />)}
        </AntdForm.Item>
        <AntdForm.Item label="图片" className={styles.displayNormal}>
          {getFieldDecorator('pic', {
            rules: [{ required: true, message: '图片' }],
            initialValue: this.props.data['pic']
          })(<Input placeholder="图片" disabled={true} />)}
        </AntdForm.Item>
        <AntdForm.Item label="上传图片">
          <ImgUpload
            fail={msg => {
              message.warning(msg)
            }}
            success={data => {
              this.props.form.setFieldsValue({
                pic: data.url
              })
            }}
            path="product"
          />
        </AntdForm.Item>
      </AntdForm>
    )
  }
}

export default AntdForm.create<IReciveProps>({
  onValuesChange(props, changedValues, allValues) {
    if (changedValues.price || changedValues.count) {
      props.form.setFieldsValue({
        sum: numeral(allValues.price)
          .multiply(allValues.count)
          .value()
      })
    }
    if (changedValues.pricepm2 || changedValues.specification) {
      const arr = allValues.specification.split('*')
      if (arr.length === 2) {
        props.form.setFieldsValue({
          price: numeral(arr[0])
            .multiply(arr[1])
            .divide(1000)
            .divide(1000)
            .multiply(allValues.pricepm2)
            .value()
        })
      }
    }
  }
})(Form)
