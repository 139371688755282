import React, { PureComponent } from 'react'
import { Form as AntdForm, Input, Select, Switch } from 'antd'
import { FormComponentProps } from 'antd/es/form'
import api from './../../api'
import styles from './style.module.less'
import IOrder from './../../models/order'

interface IReciveProps extends FormComponentProps {
  onRef: (form: Form) => void
  complete: (order: IOrder) => void
  validateFail: () => void
  data: IOrder
}

class Form extends PureComponent<IReciveProps, any> {
  constructor(props: IReciveProps) {
    super(props)
  }
  submit = () => {
    this.props.form.validateFields((err: any, values: any) => {
      if (err) {
        this.props.validateFail()
      } else {
        api.order
          .createOrUpdate(values)
          .then(res => {
            if (res.status === 200) {
              this.props.complete(res.data)
            }
          })
          .catch(() => {
            this.props.validateFail()
          })
      }
    })
  }
  componentDidMount() {
    this.props.onRef(this)
  }
  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <AntdForm
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        labelAlign="right"
        className={styles.form}
      >
        <AntdForm.Item label="" className={styles.displayNone}>
          {getFieldDecorator('id', {
            rules: [{ required: false, message: '' }],
            initialValue: this.props.data['id']
          })(<Input placeholder="" />)}
        </AntdForm.Item>
        <AntdForm.Item label="订单号" className={styles.displayNormal}>
          {getFieldDecorator('orderId', {
            rules: [{ required: true, message: '订单号' }],
            initialValue: this.props.data['orderId']
          })(<Input placeholder="订单号" />)}
        </AntdForm.Item>
        <AntdForm.Item label="总金额" className={styles.displayNormal}>
          {getFieldDecorator('sum', {
            rules: [{ required: true, message: '总金额' }],
            initialValue: this.props.data['sum']
          })(<Input placeholder="总金额" />)}
        </AntdForm.Item>
        <AntdForm.Item label="已付金额" className={styles.displayNormal}>
          {getFieldDecorator('paid', {
            rules: [{ required: true, message: '已付金额' }],
            initialValue: this.props.data['paid']
          })(<Input placeholder="已付金额" />)}
        </AntdForm.Item>
        <AntdForm.Item label="是否完成" className={styles.displayNormal}>
          {getFieldDecorator('isComplete', {
            rules: [{ required: true, message: '是否完成' }],
            initialValue: this.props.data['isComplete']
          })(<Switch />)}
        </AntdForm.Item>
        <AntdForm.Item label="cdr文件路径" className={styles.displayNormal}>
          {getFieldDecorator('cdrFile', {
            rules: [{ required: true, message: 'cdr文件路径' }],
            initialValue: this.props.data['cdrFile']
          })(<Input placeholder="cdr文件路径" />)}
        </AntdForm.Item>
        <AntdForm.Item label="pdf文件" className={styles.displayNormal}>
          {getFieldDecorator('pdfFile', {
            rules: [{ required: true, message: 'pdf文件' }],
            initialValue: this.props.data['pdfFile']
          })(<Input placeholder="pdf文件" />)}
        </AntdForm.Item>
        <AntdForm.Item label="备注" className={styles.displayNormal}>
          {getFieldDecorator('remark', {
            rules: [{ required: true, message: '备注' }],
            initialValue: this.props.data['remark']
          })(<Input placeholder="备注" />)}
        </AntdForm.Item>
        <AntdForm.Item label="图片数据" className={styles.displayNormal}>
          {getFieldDecorator('imgData', {
            rules: [{ required: true, message: '图片数据' }],
            initialValue: this.props.data['imgData']
          })(<Input placeholder="图片数据" />)}
        </AntdForm.Item>
      </AntdForm>
    )
  }
}

export default AntdForm.create<IReciveProps>()(Form)
