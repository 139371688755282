import { Backend, IApi } from './backend'
import { AxiosResponse } from 'axios'
import { IRequest } from './index'
import IPayment from './../models/payment'
import IPaymentVerify from '../models/paymentVerify'

export default class Payment extends Backend {
  static getTableData(
    request: IRequest
  ): Promise<AxiosResponse<{ rows: IPayment[]; count: number }>> {
    const api: IApi = {
      url: '/api/payment/tableData',
      method: 'get',
      data: { request }
    }
    return this.request<{ rows: IPayment[]; count: number }>(api)
  }
  static createOrUpdate(data: any): Promise<AxiosResponse<IPayment>> {
    const api: IApi = {
      url: '/api/payment/createOrUpdate',
      method: 'post',
      data
    }
    return this.request<IPayment>(api)
  }
  static delete(id: number): Promise<AxiosResponse> {
    const api: IApi = {
      url: `/api/payment/${id}`,
      method: 'delete'
    }
    return this.request(api)
  }

  static getCustomerPaymentList(
    customerId: number
  ): Promise<AxiosResponse<IPayment[]>> {
    const api: IApi = {
      url: '/api/payment/getCustomerPaymentList',
      method: 'get',
      data: { customerId }
    }
    return this.request<IPayment[]>(api)
  }

  static revoke(paymentId: number): Promise<AxiosResponse<IPayment[]>> {
    const api: IApi = {
      url: '/api/payment/revoke',
      method: 'post',
      data: { paymentId }
    }
    return this.request<IPayment[]>(api)
  }

  static getMyVerifies(): Promise<AxiosResponse<IPaymentVerify[]>> {
    const api: IApi = {
      url: `/api/payment/getMyVerifies`,
      method: 'get'
    }
    return this.request<IPaymentVerify[]>(api)
  }

  static verifyAccept(data: IPaymentVerify): Promise<AxiosResponse<IPaymentVerify>> {
    const api: IApi = {
      url: `/api/payment/verifyAccept`,
      method: 'post',
      data
    }
    return this.request<IPaymentVerify>(api)
  }

  static verifyRefuse(data: IPaymentVerify): Promise<AxiosResponse<IPaymentVerify>> {
    const api: IApi = {
      url: `/api/payment/verifyRefuse`,
      method: 'post',
      data
    }
    return this.request<IPaymentVerify>(api)
  }
}
