import { Backend, IApi } from './backend'
import { AxiosResponse } from 'axios'
import { IRequest } from './index'
import ICheckout from './../models/checkout'

export default class Checkout extends Backend {
  static getTableData(request: IRequest): Promise<AxiosResponse<{ rows: ICheckout[], count: number }>> {
    const api: IApi = {
      url: '/api/checkout/tableData',
      method: 'get',
      data: { request }
    }
    return this.request<{ rows: ICheckout[], count: number }>(api)
  }
  static createOrUpdate(data: any): Promise<AxiosResponse<ICheckout>> {
    const api: IApi = {
      url: '/api/checkout/createOrUpdate',
      method: 'post',
      data
    }
    return this.request<ICheckout>(api)
  }
  static delete(id: number): Promise<AxiosResponse> {
    const api: IApi = {
      url: `/api/checkout/${id}`,
      method: 'delete'
    }
    return this.request(api)
  }
}
