import React, { useState, useEffect } from 'react'
import { Button } from 'antd'
import moment from 'moment'
import './print.less'
import zhang from './../../../assets/imgs/receipt-zhang.jpg'

export default () => {
  const [data, setData] = useState([])
  useEffect(() => {
    const local = localStorage.getItem('BANK_RECEIPT')
    const localData = JSON.parse(local.replace(/\s+/g, ''))
    console.log(
      '🚀 ~ useEffect ~ localData',
      JSON.parse(JSON.stringify(localData))
    )
    localData.forEach(item => {
      item['交易日期'] = `${item['交易日期'].slice(0, 10)} ${item[
        '交易日期'
      ].slice(10)}`
      item['交易流水号'] = Math.random()
        .toString()
        .slice(-8)
      if (item['用途'] === '扣税') {
        item['对方户名'] = '武汉市财税库行归集户'
        item['对方账号'] = '200755058110013'
      }
    })
    setData(localData)
  }, [])
  return (
    <div className="bank-receipt-print">
      {data.length > 0 &&
        data.map((item, index) => (
          <div className="receipt-item" key={index}>
            <div className="receipt-wrapper">
              <div className="title">网上银行电子回单</div>
              <div className="table">
                <div className="header">
                  <div className="column width-1">
                    <div className="cell">付款人</div>
                  </div>
                  <div className="column width-2">
                    <div className="cell">户名</div>
                    <div className="cell">帐号</div>
                  </div>
                  <div className="column width-3">
                    <div className="cell">
                      {item['贷方发生额/收'] > 0
                        ? item['对方户名']
                        : '武汉天德艺创标识设计制作有限公司'}
                    </div>
                    <div className="cell">
                      {item['贷方发生额/收'] > 0
                        ? item['对方账号']
                        : '210320323210011'}
                    </div>
                  </div>
                  <div className="column width-1">
                    <div className="cell">收款人</div>
                  </div>
                  <div className="column width-2">
                    <div className="cell">户名</div>
                    <div className="cell">帐号</div>
                  </div>
                  <div className="column width-3">
                    <div className="cell no-border-right">
                      {item['借方发生额/支'] > 0
                        ? item['对方户名']
                        : '武汉天德艺创标识设计制作有限公司'}
                    </div>
                    <div className="cell no-border-right">
                      {item['借方发生额/支'] > 0
                        ? item['对方账号']
                        : '210320323210011'}
                    </div>
                  </div>
                </div>
                <div className="table-body">
                  <div className="cell cell-1 yellow height-1">交易金额</div>
                  <div className="cell cell-2 yellow height-1">
                    ￥{item['借方发生额/支'] || item['贷方发生额/收']}元
                  </div>
                  <div className="cell cell-1 yellow height-1">手续费</div>
                  <div className="cell cell-2 yellow height-1 no-border-right">
                    ￥0.00元
                  </div>
                  <div className="cell cell-1 yellow height-1">利息</div>
                  <div className="cell cell-2 yellow height-1">￥0.00元</div>
                  <div className="cell cell-1 yellow height-1">邮电费</div>
                  <div className="cell cell-2 yellow height-1 no-border-right">
                    ￥0.00元
                  </div>
                  <div className="cell cell-1 yellow height-1">工本费</div>
                  <div className="cell cell-2 yellow height-1">￥0.00元</div>
                  <div className="cell cell-1 yellow height-1">其他费用</div>
                  <div className="cell cell-2 yellow height-1 no-border-right">
                    ￥0.00元
                  </div>
                  <div className="cell cell-1 height-1">业务渠道</div>
                  <div className="cell cell-2 height-1">系统接收</div>
                  <div className="cell cell-1 height-1">支付渠道</div>
                  <div className="cell cell-2 height-1 no-border-right">
                    {item['备注']}
                  </div>
                  <div className="cell cell-1 height-1">摘要</div>
                  <div className="cell cell-5 height-1 no-border-right">
                    汇总
                  </div>
                  <div className="cell cell-1 height-1">附言</div>
                  <div className="cell cell-5 height-1 no-border-right">无</div>
                  <div className="cell cell-3 height-5">
                    <img src={zhang} alt="zhang" className="zhang" />
                  </div>
                  <div className="cell cell-3 height-5 sub-table no-border-right">
                    <div className="cell sub-cell sub-cell-1 height-1">
                      交易状态
                    </div>
                    <div className="cell sub-cell sub-cell-2 height-1 no-border-right">
                      成功
                    </div>
                    <div className="cell sub-cell sub-cell-1 height-2">
                      交易时间
                    </div>
                    <div className="cell sub-cell sub-cell-2 height-2 no-border-right time">
                      <div>
                        {moment(item['交易日期']).format('YYYY年MM月DD日')}
                      </div>
                      <div>{moment(item['交易日期']).format('HH:mm:ss')}</div>
                    </div>
                    <div className="cell sub-cell sub-cell-1 height-1">
                      交易编号
                    </div>
                    <div className="cell sub-cell sub-cell-2 height-1 no-border-right">
                      {`${moment(item['交易日期']).format('YYYYMMDD')}${
                        item['交易流水']
                      }`}
                    </div>
                    <div className="cell sub-cell sub-cell-1 height-1">
                      打印日期
                    </div>
                    <div className="cell sub-cell sub-cell-2 height-1 no-border-right">
                      {moment().format('YYYY年MM月DD日')}
                    </div>
                  </div>
                  <div className="cell cell-1 height-1">交易机构</div>
                  <div className="cell cell-2 height-1">01159</div>
                  <div className="cell cell-1 height-1">交易柜员</div>
                  <div className="cell cell-2 height-1 no-border-right">
                    webtel
                  </div>
                  <div className="cell cell-6 height-1 no-border-right no-border-bottom">
                    重要提示：本回单非正式的记账凭证，不作为收款方的发货依据。
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      <div className="analysis">
        <div className="print-btn">
          <div>共有回单{data.length}页</div>
          <Button
            type="primary"
            onClick={() => {
              window.print()
            }}
          >
            点击打印
          </Button>
        </div>
      </div>
    </div>
  )
}
