import { Backend, IApi } from './backend'
import { AxiosResponse } from 'axios'
import { IRequest } from './index'
import IPermission from './../models/permission'

export default class Permission extends Backend {
  static getTableData(request: IRequest): Promise<AxiosResponse<{ rows: IPermission[], count: number }>> {
    const api: IApi = {
      url: '/api/permission/tableData',
      method: 'get',
      data: { request }
    }
    return this.request<{ rows: IPermission[], count: number }>(api)
  }
  static createOrUpdate(data: any): Promise<AxiosResponse<IPermission>> {
    const api: IApi = {
      url: '/api/permission/createOrUpdate',
      method: 'post',
      data
    }
    return this.request<IPermission>(api)
  }
  static delete(id: number): Promise<AxiosResponse> {
    const api: IApi = {
      url: `/api/permission/${id}`,
      method: 'delete'
    }
    return this.request(api)
  }
}
