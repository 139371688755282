import { Backend, IApi } from './backend'
import { AxiosResponse } from 'axios'

export default class User extends Backend {
  static getQrCode(scene: string, page: string): Promise<AxiosResponse> {
    const api: IApi = {
      url: '/api/wechat/getQrCode',
      method: 'get',
      data: { scene, page }
    }
    return this.request(api)
  }

  static getLoginQrCode(id: string): Promise<AxiosResponse> {
    const api: IApi = {
      url: '/api/weapp/getLoginQrCode',
      method: 'get',
      data: { id }
    }
    return this.request(api)
  }
}
