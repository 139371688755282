import React, { PureComponent } from 'react'
import { Form as AntdForm, Input, Select, Switch, message } from 'antd'
import { FormComponentProps } from 'antd/es/form'
import api from './../../api'
import styles from './style.module.less'
import ISender from './../../models/sender'
import AddressAnalysis from './../../components/upload/addressAnalysis'

interface IReciveProps extends FormComponentProps {
  onRef: (form: Form) => void
  complete: (sender: ISender) => void
  validateFail: () => void
  data: ISender
}

class Form extends PureComponent<IReciveProps, any> {
  constructor(props: IReciveProps) {
    super(props)
  }
  submit = () => {
    this.props.form.validateFields((err: any, values: any) => {
      if (err) {
        this.props.validateFail()
      } else {
        api.sender
          .createOrUpdate(values)
          .then(res => {
            if (res.status === 200) {
              this.props.complete(res.data)
            }
          })
          .catch(() => {
            this.props.validateFail()
          })
      }
    })
  }
  // 地址解析
  analysis = address => {
    api.address.analysis(address).then(result => {
      const { data } = result
      this.props.form.setFieldsValue({
        province: data.province,
        city: data.city,
        area: data.area,
        detail: data.detail,
        name: data.name,
        phone: data.phone
      })
    })
  }
  componentDidMount() {
    this.props.onRef(this)
  }
  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <AntdForm
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        labelAlign="right"
        className={styles.form}
      >
        <AntdForm.Item label="" className={styles.displayNone}>
          {getFieldDecorator('id', {
            rules: [{ required: false, message: '' }],
            initialValue: this.props.data.id
          })(<Input placeholder="" />)}
        </AntdForm.Item>
        <AntdForm.Item label="姓名" className={styles.displayNormal}>
          {getFieldDecorator('name', {
            rules: [{ required: false, message: '姓名' }],
            initialValue: this.props.data.name
          })(<Input placeholder="姓名" />)}
        </AntdForm.Item>
        <AntdForm.Item label="排序" className={styles.displayNormal}>
          {getFieldDecorator('sort', {
            rules: [{ required: true, message: '排序' }],
            initialValue: this.props.data.sort
          })(<Input placeholder="排序" type="number" />)}
        </AntdForm.Item>
        <AntdForm.Item label="电话" className={styles.displayNormal}>
          {getFieldDecorator('phone', {
            rules: [{ required: false, message: '电话' }],
            initialValue: this.props.data.phone
          })(<Input placeholder="电话" />)}
        </AntdForm.Item>
        <AntdForm.Item label="省" className={styles.displayNormal}>
          {getFieldDecorator('province', {
            rules: [{ required: false, message: '省' }],
            initialValue: this.props.data.province
          })(<Input placeholder="省" />)}
        </AntdForm.Item>
        <AntdForm.Item label="市" className={styles.displayNormal}>
          {getFieldDecorator('city', {
            rules: [{ required: false, message: '市' }],
            initialValue: this.props.data.city
          })(<Input placeholder="市" />)}
        </AntdForm.Item>
        <AntdForm.Item label="区" className={styles.displayNormal}>
          {getFieldDecorator('area', {
            rules: [{ required: false, message: '区' }],
            initialValue: this.props.data.area
          })(<Input placeholder="区" />)}
        </AntdForm.Item>
        <AntdForm.Item label="地址" className={styles.displayNormal}>
          {getFieldDecorator('detail', {
            rules: [{ required: false, message: '地址' }],
            initialValue: this.props.data.detail
          })(<Input placeholder="地址" />)}
        </AntdForm.Item>
        <AntdForm.Item wrapperCol={{ span: 22 }}>
          <Input.Search
            placeholder="输入完整地址解析"
            enterButton="解析"
            onSearch={this.analysis}
          />
        </AntdForm.Item>
        <AntdForm.Item label="图片解析">
          <AddressAnalysis
            fail={msg => {
              message.warning(msg)
            }}
            success={address => {
              this.props.form.setFieldsValue({
                province: address.province,
                city: address.city,
                area: address.area,
                detail: address.detail,
                name: address.name,
                phone: address.phone
              })
            }}
          />
        </AntdForm.Item>
      </AntdForm>
    )
  }
}

export default AntdForm.create<IReciveProps>()(Form)
