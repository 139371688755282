import React, { useState, useMemo, useEffect, useCallback } from 'react'
import {
  PageHeader,
  Button,
  Descriptions,
  Form,
  Slider,
  Tag,
  Popover,
  InputNumber,
  message
} from 'antd'
import numeral from 'numeral'
import copy from 'copy-to-clipboard'

import './index.less'
import { MianBan, BianGui } from './config'
import ColumnItem, { IColumnProps } from './components/ColumnItem'
import BianguiItem from './components/BianguiItem'
import BianguiDisplay from './components/BianguiDisplay'
import api from './../../../api'

const DEFAULT_HEIGHT = 30
const DEFAULT_WIDTH = 288
const PRICE = 700

const Index: React.FC = () => {
  const [internalScale, setInternalScale] = useState(1)
  const [bianguiLeft, setBianguiLeft] = useState<BianGui>(
    new BianGui(6, '小方轨', DEFAULT_HEIGHT)
  )
  const [bianguiRight, setBianguiRight] = useState<BianGui>(
    new BianGui(6, '小方轨', DEFAULT_HEIGHT)
  )
  const [internalWidth, setInternalWidth] = useState(DEFAULT_WIDTH)
  const firstChild = new MianBan(DEFAULT_HEIGHT, DEFAULT_WIDTH)
  const [column, setColumn] = useState<IColumnProps[]>([
    {
      firstChild,
      children: [firstChild]
    }
  ])
  const totalWidth = useMemo(() => {
    return bianguiLeft.width + bianguiRight.width + internalWidth
  }, [bianguiLeft, bianguiRight, internalWidth])
  const totalHeight = useMemo(() => {
    const height = column
      .map(item => item.firstChild.height)
      .reduce((a, b) => a + b)
    setBianguiLeft({ ...bianguiLeft, height })
    setBianguiRight({ ...bianguiRight, height })
    return height
  }, [column])
  const totalSize = useMemo(() => {
    return numeral(totalHeight)
      .multiply(totalWidth)
      .divide(1000)
      .divide(1000)
      .value()
      .toFixed(2)
  }, [totalWidth, totalHeight])
  const sum = useMemo(() => {
    return numeral(totalSize)
      .multiply(PRICE)
      .value()
      .toFixed(1)
  }, [totalSize])
  useEffect(() => {
    column.forEach(item => {
      if (item.children.length === 1) {
        item.firstChild.width = internalWidth
      }
    })
    setColumn([...column])
  }, [internalWidth])
  const handleInternalScale = e => {
    setInternalScale(Number(e))
  }
  const handleBianguiLeftChange = (item: BianGui) => {
    setBianguiLeft({ ...item })
  }
  const handleBianguiRightChange = (item: BianGui) => {
    setBianguiRight({ ...item })
  }
  const handleColumnItemChange = (
    changed: IColumnProps,
    item: IColumnProps
  ) => {
    item = { ...changed }
    setColumn([...column])
  }
  const handleCopy = (index: number, arr: IColumnProps[]) => {
    setColumn([...column.slice(0, index), ...arr, ...column.slice(-1 * index)])
  }
  const handleAddSingle = () => {
    const child = new MianBan(DEFAULT_HEIGHT, internalWidth)
    column.push({
      firstChild: child,
      children: [child]
    })
    setColumn([...column])
  }
  const handleDelete = (index: number) => {
    column.splice(index, 1)
    setColumn([...column])
  }
  const handleSave = useCallback(() => {
    api.design
      .createOrUpdate({
        content: {
          bianguiLeft,
          bianguiRight,
          internalWidth,
          column
        }
      })
      .then(res => {
        const { data } = res
        copy(data.uuid)
        message.success('保存成功，代码已复制，请在CorelDraw中导入')
      })
  }, [bianguiLeft, bianguiRight, internalWidth, column])
  return (
    <div className="xingcai">
      <PageHeader
        title="型材科室牌"
        extra={
          <Button type="primary" onClick={handleSave}>
            保存
          </Button>
        }
        className="header"
      >
        <Descriptions size="small" column={2} bordered>
          <Descriptions.Item label="尺寸(mm)">{`${totalWidth} * ${totalHeight}`}</Descriptions.Item>
          <Descriptions.Item label="面积(㎡)">{totalSize}</Descriptions.Item>
          <Descriptions.Item label="单价(元/平方米)">{PRICE}</Descriptions.Item>
          <Descriptions.Item label="总价(元)">{sum}</Descriptions.Item>
        </Descriptions>
      </PageHeader>
      <Form>
        <Form.Item label="内径显示缩放">
          <Slider
            min={0.2}
            max={3}
            onChange={handleInternalScale}
            value={internalScale}
            step={0.1}
            marks={{ 0.2: 0.2, 0.5: 0.5, 1: 1, 1.5: 1.5, 2: 2, 2.5: 2.5, 3: 3 }}
          />
        </Form.Item>
      </Form>
      <div className="design">
        <div className="keshipai-wrapper">
          <div className="top-info">
            <div className="left">
              <BianguiItem
                bianguiItem={bianguiLeft}
                onChange={handleBianguiLeftChange}
              />
            </div>
            <div className="width">
              <Popover
                title="内径"
                content={
                  <Form>
                    <Form.Item label="内径">
                      <InputNumber
                        value={internalWidth}
                        onChange={setInternalWidth}
                      />
                    </Form.Item>
                  </Form>
                }
              >
                <Tag color="blue" className="handle-tag">
                  {internalWidth}
                </Tag>
              </Popover>
            </div>
            <div className="right">
              <BianguiItem
                bianguiItem={bianguiRight}
                onChange={handleBianguiRightChange}
              />
            </div>
          </div>
          <div className="keshipai-body">
            <BianguiDisplay item={bianguiLeft} className="biangui-left" />
            <div
              className="content"
              style={{
                width: `${internalWidth * internalScale}px`
              }}
            >
              {column.map((item, index) => {
                return (
                  <ColumnItem
                    key={index}
                    item={item}
                    internalScale={internalScale}
                    bianguiLeft={bianguiLeft}
                    bianguiRight={bianguiRight}
                    column={column}
                    internalWidth={internalWidth}
                    onChange={(changed: IColumnProps) => {
                      handleColumnItemChange(changed, item)
                    }}
                    onCopy={(arr: IColumnProps[]) => {
                      handleCopy(index, arr)
                    }}
                    onDelete={() => {
                      handleDelete(index)
                    }}
                  />
                )
              })}
              <div className="add-item">
                <Button
                  type="primary"
                  size="small"
                  block
                  onClick={handleAddSingle}
                >
                  新增
                </Button>
              </div>
            </div>
            <BianguiDisplay item={bianguiRight} className="biangui-right" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Index
