import React, { PureComponent } from 'react'
import { Table, Divider, Form, Input, Col, Button, message, Modal } from 'antd'
import DataModal from './modal'
import api from './../../api'
import styles from './style.module.less'
import IProduct from '../../models/product'
const { confirm } = Modal

class ProductPage extends PureComponent {
  constructor(props: any) {
    super(props)
  }
  state = {
    loading: true,
    list: [],
    form: {
      pageSize: 15,
      page: 1,
      keyword: ''
    },
    total: 0,
    modalOpen: false,
    modalTitle: '',
    updateItem: {} as IProduct
  }
  columns = [
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      align: 'center' as 'center'
    },
    {
      title: '规格',
      dataIndex: 'specification',
      key: 'specification',
      align: 'center' as 'center'
    },
    {
      title: '光泽度',
      dataIndex: 'gloss',
      key: 'gloss',
      align: 'center' as 'center'
    },
    {
      title: '单价',
      dataIndex: 'price',
      key: 'price',
      align: 'center' as 'center'
    },
    {
      title: '单位',
      dataIndex: 'unit',
      key: 'unit',
      align: 'center' as 'center'
    },
    {
      title: '数量',
      dataIndex: 'count',
      key: 'count',
      align: 'center' as 'center'
    },
    {
      title: '总价',
      dataIndex: 'sum',
      key: 'sum',
      align: 'center' as 'center'
    },
    {
      title: '图片',
      dataIndex: 'pic',
      key: 'pic',
      align: 'center' as 'center'
    },
    {
      title: '操作',
      key: 'action',
      align: 'center' as 'center',
      width: 200,
      render: (text: any, record: any) => (
        <span>
          <a
            onClick={() => {
              this.update(record)
            }}
          >
            编辑
          </a>
          <Divider type="vertical" />
          <a
            onClick={() => {
              this.delete(record)
            }}
          >
            删除
          </a>
        </span>
      )
    }
  ]
  update = (record: any) => {
    this.setState({
      updateItem: record,
      modalOpen: true
    })
  }
  delete = (record: any) => {
    confirm({
      title: '确定删除？',
      content: '确定删除该项目？',
      onOk: () => {
        api.product.delete(Number(record.id)).then(res => {
          if (res.status === 200) {
            message.success('删除成功')
            this.getList()
          }
        })
      },
      onCancel() {}
    })
  }
  getList() {
    this.setState({
      loading: true
    })
    api.product.getTableData(this.state.form).then(res => {
      if (res.status === 200) {
        this.setState({
          list: res.data.rows,
          total: res.data.count,
          loading: false
        })
      }
    })
  }
  createClick = () => {
    this.setState({
      updateItem: {},
      modalTitle: '新建',
      modalOpen: true
    })
  }
  searchClick = () => {
    this.refresh()
  }
  refresh() {
    this.getList()
  }
  componentWillMount() {
    this.getList()
  }
  render() {
    return (
      <div className={styles.index}>
        <Button
          type="primary"
          loading={this.state.loading}
          icon="sync"
          className={styles.refreshBtn}
          onClick={() => {
            this.refresh()
          }}
        >
          刷新
        </Button>
        <Form
          className={styles.form}
          labelCol={{
            span: 6
          }}
          wrapperCol={{
            span: 16
          }}
          layout="horizontal"
        >
          <Col span={8}>
            <Form.Item label="搜索词">
              <Input
                placeholder="请输入搜索词"
                onChange={e => {
                  this.setState({
                    form: {
                      page: this.state.form.page,
                      pageSize: this.state.form.pageSize,
                      keyword: e.target.value
                    }
                  })
                }}
              />
            </Form.Item>
          </Col>
        </Form>
        <Col span={24} className={styles.actionBtn}>
          <Button
            type="primary"
            htmlType="submit"
            icon="search"
            onClick={this.searchClick}
          >
            搜索
          </Button>
        </Col>
        <Col span={24} className={styles.addBtn}>
          <Button type="primary" icon="plus" onClick={this.createClick}>
            新建
          </Button>
        </Col>
        <Table
          bordered
          className={styles.table}
          columns={this.columns}
          dataSource={this.state.list}
          size="middle"
          rowKey="id"
          loading={this.state.loading}
          pagination={{
            position: 'bottom',
            pageSize: this.state.form.pageSize,
            total: this.state.total,
            pageSizeOptions: ['15', '30', '50'],
            current: this.state.form.page,
            showSizeChanger: true,
            onChange: (page, pageSize) => {
              const { form } = this.state
              form.page = page
              form.pageSize = pageSize ? pageSize : 1
              this.setState({
                form
              })
              this.getList()
            },
            showTotal: (total, range) => {
              return `共${total}条数据，当前显示${range}`
            },
            onShowSizeChange: (current, size) => {
              const { form } = this.state
              form.pageSize = size
              this.setState({
                form
              })
              this.getList()
            }
          }}
        />
        {this.state.modalOpen && (
          <DataModal
            title={this.state.modalTitle}
            onClose={() => {
              this.setState({ modalOpen: false })
            }}
            complete={() => {
              this.setState({ modalOpen: false })
              this.getList()
            }}
            updateItem={this.state.updateItem}
          />
        )}
      </div>
    )
  }
}

export default ProductPage
