import React, { PureComponent } from 'react'
import io from 'socket.io-client'
import { Tabs, Form, Icon, Input, Button, message } from 'antd'
import { connect } from 'react-redux'
import { FormComponentProps } from 'antd/es/form'
import { createHashHistory } from 'history'
import { Backend } from './../../api/backend'
import styles from './styles/login.module.less'
import api from './../../api'
import SessionStorage from './../../utils/sessionStorage'

import { INav } from './../../reducers/nav'

const history = createHashHistory()

const { TabPane } = Tabs

interface IReciveProps extends FormComponentProps {
  login: (data) => void
  setMenu: (data) => void
  setNavList: (data: INav[]) => void
}

class Login extends PureComponent<IReciveProps, any> {
  state = {
    loginImage: ''
  }
  submit = () => {
    this.props.form.validateFields((err: any, values: any) => {
      if (err) {
      } else {
        api.auth
          .login(values)
          .then(res => {
            if (res.status === 200) {
              this.props.login(res.data.user)
              SessionStorage.setString(
                'authorization',
                res.data.session.authorization
              )
              Backend.login(res.data.session.authorization)
              const lv1Menu = res.data.user.role.menus.filter(
                x => x.level === '一级'
              )
              lv1Menu.forEach(menu => {
                menu.children.forEach(child => {
                  if (res.data.user.role.menus.every(x => x.id !== child.id)) {
                    child.isDisplay = false
                  }
                })
              })
              this.props.setMenu(lv1Menu)
              history.push('/')
            }
          })
          .catch(() => {
            message.error('登录失败')
          })
      }
    })
  }
  getLv2MenuList() {
    api.meta.getLv2MenuList().then(res => {
      this.props.setNavList([
        ...res.data.map(item => {
          return {
            title: item.name,
            path: item.path
          }
        })
      ])
    })
  }
  componentDidMount() {
    this.getLv2MenuList()
    this.wsInit()
  }
  wsInit() {
    const socket = io()
    socket.on('connect', () => {
      api.wechat.getLoginQrCode(socket.id).then(res => {
        this.setState({ loginImage: res.data })
      })
      socket.on('loginSuccess', data => {
        this.props.login(data.user)
        SessionStorage.setString(
          'authorization',
          data.session.authorization
        )
        Backend.login(data.session.authorization)
        const lv1Menu = data.user.role.menus.filter(
          x => x.level === '一级'
        )
        lv1Menu.forEach(menu => {
          menu.children.forEach(child => {
            if (data.user.role.menus.every(x => x.id !== child.id)) {
              child.isDisplay = false
            }
          })
        })
        this.props.setMenu(lv1Menu)
        history.push('/')
      })
    })
  }
  render() {
    const { loginImage } = this.state
    const { getFieldDecorator } = this.props.form
    return (
      <div className={styles.login}>
        <div className={styles.title}>天德天远</div>
        <Tabs defaultActiveKey="1" className={styles.tab}>
          <TabPane tab="帐号密码登录" key="1">
            <Form>
              <Form.Item>
                {getFieldDecorator('userName', {
                  rules: [{ required: true, message: '请输入用户名' }]
                })(
                  <Input
                    prefix={
                      <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                    placeholder="用户名"
                    onPressEnter={() => {
                      this.submit()
                    }}
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('password', {
                  rules: [{ required: true, message: '请输入密码' }]
                })(
                  <Input
                    prefix={
                      <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                    type="password"
                    placeholder="密码"
                    onPressEnter={() => {
                      this.submit()
                    }}
                  />
                )}
              </Form.Item>
              <Form.Item>
                <Button type="primary" block={true} onClick={this.submit}>
                  登录
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane tab="扫码登录" key="2">
            {loginImage && (
              <img src={loginImage} alt="login" className={styles.loginImage} />
            )}
          </TabPane>
        </Tabs>
      </div>
    )
  }
}

const mapStateToProps = (state: { User: object }) => {
  return { user: state.User }
}

const mapDispatchToProps = dispatch => {
  return {
    login: data => dispatch({ type: 'LOGIN', data }),
    setMenu: data => dispatch({ type: 'SET_MENU_LIST', data }),
    logout: data => dispatch({ type: 'LOGOUT', data }),
    setNavList: (data: INav[]) => dispatch({ type: 'SET_NAV_LIST', data })
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Login))
