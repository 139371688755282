import sessionStorage from '../utils/sessionStorage'
import IMenu from './../models/menu'
const { getArray, setObject, removeItem } = sessionStorage

const states = {
  MenuList: getArray('menuList') as IMenu[]
}

const MenuList = (state = states.MenuList, action) => {
  switch (action.type) {
    case 'SET_MENU_LIST':
      setObject('menuList', action.data)
      return action.data
    case 'CLEAR_MENU_LIST':
      removeItem('menuList')
      return {}
    default:
      return state
  }
}

export default {
  MenuList
}
