export default {
  setObject(item: string, data: object): void {
    localStorage.setItem(item, JSON.stringify(data))
  },
  getObject(item: string): object {
    const value = localStorage.getItem(item)
    if (typeof value === 'string') {
      return JSON.parse(value)
    } else {
      return {}
    }
  },
  getArray(item: string): object {
    const value = localStorage.getItem(item)
    if (typeof value === 'string') {
      return JSON.parse(value)
    } else {
      return []
    }
  },
  getString(item: string): string | null {
    const value = localStorage.getItem(item)
    return value
  },
  setString(item: string, data: string) {
    localStorage.setItem(item, data)
  },
  removeItem(item: string) {
    localStorage.removeItem(item)
  }
}
