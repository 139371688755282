import React, { PureComponent, useState, useEffect } from 'react'
import { Descriptions, Table, Spin, Button, message } from 'antd'
import IOrder from '../../models/order'
import moment from 'moment'
import api from '../../api'
import { openLocalFile } from '../../utils/localFile'
import numeral from 'numeral'
import ImageView from './../../components/imageView'
import copy from 'copy-to-clipboard'
import { createHashHistory } from 'history'
import { INav } from './../../reducers/nav'
import { connect } from 'react-redux'
import IUser from '../../models/user'

const history = createHashHistory()

const Detail: React.FC<{
  match: { params: { id: number } }
  pushNav: (data: INav) => void
  user: IUser
}> = props => {
  const { pushNav } = props
  const [order, setOrder] = useState<IOrder>({} as IOrder)
  const getDetail = () => {
    api.order.detail(props.match.params.id).then(res => {
      setOrder(res.data)
    })
  }
  useEffect(() => {
    getDetail()
  }, [getDetail, props.match.params.id])
  const columns = [
    {
      title: '序号',
      key: 'index',
      align: 'center' as 'center',
      render(text, record, index) {
        return <span>{index + 1}</span>
      },
      width: 50
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      align: 'center' as 'center',
      width: 200
    },
    {
      title: '规格',
      dataIndex: 'specification',
      key: 'specification',
      align: 'center' as 'center',
      width: 150
    },
    {
      title: '单价',
      dataIndex: 'price',
      key: 'price',
      align: 'center' as 'center',
      render(text, record, index) {
        return <span>￥{text}</span>
      },
      width: 120
    },
    {
      title: '数量',
      dataIndex: 'count',
      key: 'count',
      align: 'center' as 'center',
      width: 120
    },
    {
      title: '总价',
      dataIndex: 'sum',
      key: 'sum',
      align: 'center' as 'center',
      render(text, record, index) {
        return <span>￥{text}</span>
      },
      width: 120
    },
    {
      title: '图片',
      dataIndex: 'pic',
      key: 'pic',
      align: 'center' as 'center',
      render: (text, record, index) => {
        return (
          <img
            src={`${text}!product`}
            style={{ cursor: 'pointer', width: '200px' }}
            onClick={() => {
              ImageView.show(
                order.products.map(item => {
                  return { src: item.pic, title: item.name }
                }),
                index
              )
            }}
          />
        )
      },
      width: 400
    }
  ]
  return (
    <div>
      {order.id && (
        <Descriptions
          title={
            <span>
              {order.orderId}
              {props.user.type !== '客户' && (
                <Button
                  type="primary"
                  size="small"
                  icon="edit"
                  style={{
                    marginLeft: '10px'
                  }}
                  onClick={() => {
                    pushNav({
                      title: `修改${order.orderId.toString()}`,
                      path: `/orderEdit/${order.id}`
                    })
                    history.push(`/orderEdit/${order.id}`)
                  }}
                >
                  修改
                </Button>
              )}
            </span>
          }
          bordered
        >
          <Descriptions.Item label="客户">
            {`${order.customer.realName} - ${order.customer.company}`}
          </Descriptions.Item>
          <Descriptions.Item label="发货时间">
            {moment(order.shipTime).format('YYYY-MM-DD')}
          </Descriptions.Item>
          <Descriptions.Item label="文件">
            <a
              onClick={() => {
                openLocalFile(order.cdrFile)
              }}
            >
              {order.cdrFile && order.cdrFile.split('/').pop()}
            </a>
          </Descriptions.Item>
          <Descriptions.Item label="收货地址" span={3}>
            {order.address.id
              ? `${order.address.name} - ${order.address.phone} - ${order.address.province} ${order.address.city} ${order.address.area} ${order.address.detail}`
              : '无收货人'}
            <Button
              icon="copy"
              type="primary"
              style={{
                marginLeft: '4px'
              }}
              size="small"
              onClick={() => {
                copy(
                  `${order.address.name},${order.address.phone},${order.address.province}${order.address.city}${order.address.area}${order.address.detail}`
                )
                message.success('复制成功')
              }}
            >
              复制
            </Button>
          </Descriptions.Item>
          <Descriptions.Item label="订购产品" span={3}>
            {/* {order.products.map(item => {
              return (
                <p
                  key={item.id}
                >{`${item.name}(${item.specification}) x ${item.count}${item.unit} = ￥${item.price} * ${item.count} = ￥${item.sum}`}</p>
              )
            })} */}
            <Table
              bordered
              columns={columns}
              dataSource={order.products}
              size="middle"
              rowKey="id"
              pagination={false}
            />
          </Descriptions.Item>
          <Descriptions.Item label="合计" span={3}>
            {`合计${order.products.length}个产品，共${order.products
              .map(x => x.count)
              .reduce(
                (a, b) =>
                  numeral(a)
                    .add(b)
                    .value(),
                0
              )}件，总价￥${order.products
              .map(x => x.sum)
              .reduce(
                (a, b) =>
                  numeral(a)
                    .add(b)
                    .value(),
                0
              )}元`}
          </Descriptions.Item>
        </Descriptions>
      )}
      {order.id === undefined && <Spin size="large" />}
    </div>
  )
}

const mapStateToProps = (state: { User: IUser }) => {
  return { user: state.User }
}

const mapDispatchToProps = dispatch => {
  return {
    pushNav: (data: INav) => {
      dispatch({ type: 'PUSH_NAV_2', data })
      dispatch({ type: 'SET_ACTIVE_KEY', data: data.path })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Detail)
