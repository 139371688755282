import { Backend, IApi } from './backend'
import { AxiosResponse } from 'axios'
import { IRequest } from './index'
import IDesign from './../models/design'

export default class Design extends Backend {
  static getTableData(request: IRequest): Promise<AxiosResponse<{ rows: IDesign[], count: number }>> {
    const api: IApi = {
      url: '/api/design/tableData',
      method: 'get',
      data: { request }
    }
    return this.request<{ rows: IDesign[], count: number }>(api)
  }
  static createOrUpdate(data: any): Promise<AxiosResponse<IDesign>> {
    const api: IApi = {
      url: '/api/design/createOrUpdate',
      method: 'post',
      data
    }
    return this.request<IDesign>(api)
  }
  static delete(id: number): Promise<AxiosResponse> {
    const api: IApi = {
      url: `/api/design/${id}`,
      method: 'delete'
    }
    return this.request(api)
  }
}
