import React, { PureComponent } from 'react'
import styles from './assets/stylesheets/app.module.less'
import { Layout, Menu, Icon, Tabs, Avatar, Dropdown, message } from 'antd'
import { HashRouter as Router, Redirect } from 'react-router-dom'
import CacheRoute, { CacheSwitch } from 'react-router-cache-route'
import { connect } from 'react-redux'
import routers from './router'
import { createHashHistory } from 'history'
import prompt from './components/prompt'
import localStorage from './utils/localStorage'
import api from './api'

import IUser from './models/user'
import IMenu from './models/menu'
import { INav } from './reducers/nav'

const { Sider, Content } = Layout
const { TabPane } = Tabs
const { SubMenu } = Menu
const history = createHashHistory()

interface IReciveProps {
  user: IUser
  menuList: IMenu[]
  logout: () => void
  pushNav: (data: string) => void
  navList: INav[]
  activeKey: string
  deleteNav: (data: string) => void
  setActiveKey: (data: string) => void
}

let currentUrl = ''

class App extends PureComponent<IReciveProps> {
  constructor(props: IReciveProps) {
    super(props)
  }
  state = {
    activeKey: '/',
    tabList: [
      {
        name: '首页',
        path: '/'
      }
    ],
    openKeys: []
  }
  // 切换tab
  tabChange = (key: string) => {
    history.push(key)
  }
  // 删除tab
  tabDelete = (targetKey: any) => {
    if (targetKey === '/') {
      return
    } else {
      const { navList, activeKey, setActiveKey, deleteNav } = this.props
      const index = navList.findIndex(x => x.path === targetKey)
      if (activeKey === targetKey) {
        const activePath = navList[index - 1].path
        setActiveKey(activePath)
        history.push(activePath)
      }
      deleteNav(targetKey)
    }
  }
  // 点击左侧导航菜单
  menuClick = ({ key, item }) => {
    history.push(key)
  }
  logout = () => {
    this.props.logout()
    history.push('/login')
  }
  onOpenChange = openKeys => {
    const latestOpenKey = openKeys.find(
      key => this.state.openKeys.indexOf(key) === -1
    )
    if (
      this.props.menuList.map(x => x.id.toString()).indexOf(latestOpenKey) ===
      -1
    ) {
      this.setState({ openKeys })
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : []
      })
    }
  }
  render() {
    const { navList, pushNav, activeKey } = this.props
    return (
      <Layout className={styles.layout}>
        <Sider trigger={null} collapsible className={styles.sidebar}>
          {/* <div className={styles.title}>天德天远</div> */}
          <div className={styles.profile}>
            <Avatar size={56} icon="user" />
            <div className={styles.realName}>{this.props.user.realName}</div>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    onClick={() => {
                      prompt
                        .show('修改密码', '密码', '请输入密码')
                        .then(res => {
                          api.user.updatePassword(res).then(() => {
                            message.success('修改成功')
                          })
                        })
                    }}
                  >
                    <Icon type="key" />
                    修改密码
                  </Menu.Item>
                  <Menu.Divider />
                  <Menu.Item
                    onClick={() => {
                      window.open('./download/tdtyerp_utils.exe')
                    }}
                  >
                    <Icon type="download" />
                    下载本地应用组件
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => {
                      prompt
                        .show(
                          '设置本地文件路径',
                          '本地文件路径',
                          '请输入本地文件路径'
                        )
                        .then(res => {
                          localStorage.setString('local-file-path', res)
                        })
                    }}
                  >
                    <Icon type="file" />
                    设置本地文件路径
                  </Menu.Item>
                  <Menu.Divider />
                  <Menu.Item onClick={this.logout}>
                    <Icon type="poweroff" />
                    注销
                  </Menu.Item>
                </Menu>
              }
            >
              <a>
                {this.props.user.role.name} <Icon type="down" />
              </a>
            </Dropdown>
          </div>

          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={['2']}
            onClick={this.menuClick}
            className={styles.menu}
            openKeys={this.state.openKeys}
            onOpenChange={this.onOpenChange}
          >
            {this.props.menuList.map(sub => (
              <SubMenu
                key={sub.id}
                title={
                  <span>
                    <Icon type={sub.icon} />
                    <span>{sub.name}</span>
                  </span>
                }
              >
                {sub.children.map(
                  item =>
                    item.isDisplay && (
                      <Menu.Item key={item.path} data-item={item}>
                        {item.name}
                      </Menu.Item>
                    )
                )}
              </SubMenu>
            ))}
          </Menu>
        </Sider>
        <Layout>
          <div className={styles.header}>
            <Tabs
              defaultActiveKey="/"
              onChange={this.tabChange}
              type="editable-card"
              hideAdd={true}
              tabBarGutter={0}
              activeKey={activeKey}
              onEdit={this.tabDelete}
            >
              {navList.map(item => {
                return <TabPane tab={item.title} key={item.path} />
              })}
            </Tabs>
          </div>
          <Content className={styles.routerArea}>
            <Router>
              <CacheSwitch>
                {routers.map((item, index) => {
                  return (
                    <CacheRoute
                      when={() => {
                        return item.cache
                      }}
                      exact
                      path={item.path}
                      key={index}
                      render={props => {
                        if (
                          item.requireAuth &&
                          this.props.user.id === undefined
                        ) {
                          return (
                            <Redirect
                              to={{
                                pathname: '/login',
                                state: { from: props.location }
                              }}
                            />
                          )
                        } else {
                          if (props.match.url !== currentUrl) {
                            currentUrl = props.match.url
                            pushNav(props.match.url)
                          }
                          return <item.component {...props} />
                        }
                      }}
                    />
                  )
                })}
              </CacheSwitch>
            </Router>
          </Content>
        </Layout>
      </Layout>
    )
  }
}

const mapStateToProps = (state: {
  User: IUser
  MenuList: IMenu[]
  NavList: INav[]
  ActiveKey: string
}) => {
  return {
    user: state.User,
    menuList: state.MenuList,
    navList: state.NavList,
    activeKey: state.ActiveKey
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch({ type: 'LOGOUT' }),
    pushNav: (data: string) => {
      dispatch({ type: 'PUSH_NAV', data })
      dispatch({ type: 'SET_ACTIVE_KEY', data })
    },
    deleteNav: (data: string) => {
      dispatch({ type: 'DELETE_NAV', data })
    },
    setActiveKey: (data: string) => dispatch({ type: 'SET_ACTIVE_KEY', data })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
