import { Backend, IApi } from './backend'
import { AxiosResponse } from 'axios'
import { IRequest } from './index'
import ISessionStore from './../models/sessionStore'

export default class SessionStore extends Backend {
  static getTableData(
    request: IRequest
  ): Promise<AxiosResponse<{ rows: ISessionStore; count: number }>> {
    const api: IApi = {
      url: '/api/sessionStore/tableData',
      method: 'get',
      data: { request }
    }
    return this.request<{ rows: ISessionStore; count: number }>(api)
  }
  static createOrUpdate(data: any): Promise<AxiosResponse<ISessionStore>> {
    const api: IApi = {
      url: '/api/sessionStore/createOrUpdate',
      method: 'post',
      data
    }
    return this.request<ISessionStore>(api)
  }
  static delete(id: number): Promise<AxiosResponse> {
    const api: IApi = {
      url: `/api/sessionStore/${id}`,
      method: 'delete'
    }
    return this.request(api)
  }
}
