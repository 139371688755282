import React from 'react'
import parseColor from 'parse-color'
import { BianGui } from './../../config'

const BianguiDisplay: React.FC<{
  item: BianGui
  className?: string
}> = props => {
  const { item, className } = props
  return (
    <div
      className={`${className}`}
      style={{
        width: `${item.width}px`,
        backgroundColor: parseColor(`cmyk(${item.color})`).hex
      }}
    />
  )
}

export default BianguiDisplay
