import React, { useState } from 'react'
import { Popover, Form, Select, Button, InputNumber, message } from 'antd'
import parseColor from 'parse-color'
import CMYK from './../../../CMYK'
import './index.less'
import {
  MianBanList,
  CaoBanList,
  MianBan,
  CaoBan,
  ZhongBianGuiList,
  ZhongBianGui,
  XCType
} from './../../../../config'

const MianbanItem: React.FC<{
  mianbanItem: MianBan
  onAdd: (
    current: MianBan | CaoBan | ZhongBianGui,
    add: MianBan | CaoBan | ZhongBianGui
  ) => void
  onChange: (changed: MianBan | CaoBan | ZhongBianGui) => void
  internalScale: number
}> = props => {
  const { mianbanItem, onAdd, onChange, internalScale } = props
  const [addWidth, setAddWidth] = useState(0)
  const [add, setAdd] = useState<CaoBan | MianBan | ZhongBianGui>(null)
  const handleAddNameChange = (name: string) => {
    const find = [...MianBanList, ...CaoBanList, ...ZhongBianGuiList].find(
      x => x.name === name
    )
    if (find) {
      if (find.type !== XCType.中边轨) {
        find.width = addWidth
      }
      if (find.type === XCType.中边轨) {
        find.height = mianbanItem.height
      }
      setAdd({ ...find })
    }
  }
  const handleAddWidthChange = (width: number) => {
    setAddWidth(width)
    if (add.name) {
      add.width = width
      setAdd({ ...add })
    }
  }
  const handleAddSubmit = () => {
    if (!add || !add.width || add.width === 0) {
      message.error('请选择型材或设置长度')
    } else {
      mianbanItem.width -= add.width
      onAdd(mianbanItem, add)
    }
  }
  const handleColorChange = (color: string) => {
    mianbanItem.color = color
    onChange(mianbanItem)
  }
  const handleWidthChange = (width: number) => {
    mianbanItem.width = width
    onChange(mianbanItem)
  }
  return (
    <Popover
      title="添加面板"
      trigger="click"
      content={
        <Form>
          <Form.Item label="面板类型">
            <Select onChange={handleAddNameChange}>
              {[...MianBanList, ...CaoBanList]
                .filter(x => x.height === mianbanItem.height)
                .concat(ZhongBianGuiList)
                .map((mianban, mianbanIndex) => {
                  return (
                    <Select.Option value={mianban.name} key={mianbanIndex}>
                      {mianban.name}
                    </Select.Option>
                  )
                })}
            </Select>
          </Form.Item>
          <Form.Item label="面板长度">
            <InputNumber
              onChange={handleAddWidthChange}
              disabled={add && add.type === XCType.中边轨}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" size="small" onClick={handleAddSubmit}>
              确定添加
            </Button>
          </Form.Item>
          <hr />
          <Form.Item label="设置颜色">
            <CMYK initial={mianbanItem.color} onChange={handleColorChange} />
          </Form.Item>
          <Form.Item label="设置长度">
            <InputNumber
              onChange={handleWidthChange}
              disabled={mianbanItem.type === XCType.中边轨}
            />
          </Form.Item>
        </Form>
      }
    >
      <div
        className="mianban-item"
        style={{
          flex: `0 0 ${mianbanItem.width * internalScale}px`,
          backgroundColor: parseColor(`cmyk(${mianbanItem.color})`).hex
        }}
      >
        {mianbanItem.type === XCType.槽板 && (
          <React.Fragment>
            <div className="mian-item-cao top" />
            <div className="mian-item-cao bottom" />
          </React.Fragment>
        )}
      </div>
    </Popover>
  )
}

export default MianbanItem
