import React, { PureComponent } from 'react'
import { Card, DatePicker, Button, Form, Row, Col, Table } from 'antd'
import moment from 'moment'
import numeral from 'numeral'
import styles from './style.module.less'
import api from './../../api'

const { RangePicker } = DatePicker

interface IState {
  date: [moment.Moment, moment.Moment]
  tableList: Array<{
    monthStr: string
    month: number
    orderSum: number
    paymentSum: number
    paymentRealSum: number
    outSum: number
  }>
  loading: boolean
}

class Reporter extends PureComponent {
  constructor(props: any) {
    super(props)
  }
  state: IState = {
    date: [moment().startOf('year'), moment().endOf('year')],
    tableList: [],
    loading: false
  }
  columns = [
    {
      title: '月份',
      dataIndex: 'monthStr',
      key: 'monthStr',
      align: 'center' as 'center'
    },
    {
      title: '订单金额',
      dataIndex: 'orderSum',
      key: 'orderSum',
      align: 'center' as 'center',
      render(text, record) {
        return <span>{record.orderSum.value()}</span>
      }
    },
    {
      title: '记账收入',
      dataIndex: 'paymentSum',
      key: 'paymentSum',
      align: 'center' as 'center',
      render(text, record) {
        return <span>{record.paymentSum.value()}</span>
      }
    },
    {
      title: '实际收入',
      dataIndex: 'realPaymentSum',
      key: 'realPaymentSum',
      align: 'center' as 'center',
      render(text, record) {
        return <span>{record.paymentRealSum.value()}</span>
      }
    },
    {
      title: '差额<实际收入 - 记账收入>',
      dataIndex: 'real',
      key: 'realPaymentSum',
      align: 'center' as 'center',
      render(text, record) {
        return <span>{(record.paymentRealSum.value() - record.paymentSum.value()).toFixed(2)}</span>
      }
    },
    {
      title: '支出',
      dataIndex: 'outSum',
      key: 'outSum',
      align: 'center' as 'center',
      render(text, record) {
        return <span>{record.outSum.value()}</span>
      }
    },
    {
      title: '盈亏<订单金额 - 支出>',
      dataIndex: 'profit',
      key: 'profit',
      align: 'center' as 'center',
      render(text, record) {
        return (
          <span
            style={{
              color:
                record.orderSum.value() - record.outSum.value() > 0
                  ? 'green'
                  : 'red'
            }}
          >
            {(record.orderSum.value() - record.outSum.value()).toFixed(2)}
          </span>
        )
      }
    },
    {
      title: '现金<实际收入 - 支出>',
      dataIndex: 'cash',
      key: 'cash',
      align: 'center' as 'center',
      render(text, record) {
        return (
          <span
            style={{
              color:
                record.paymentRealSum.value() - record.outSum.value() > 0
                  ? 'green'
                  : 'red'
            }}
          >
            {(record.paymentRealSum.value() - record.outSum.value()).toFixed(2)}
          </span>
        )
      }
    }
  ]
  getList() {
    this.setState({
      loading: true
    })
    const { date, tableList } = this.state
    api.meta.reporter(date[0].toDate(), date[1].toDate()).then(res => {
      const list = []
      const orderSum = numeral(0)
      const paymentSum = numeral(0)
      const paymentRealSum = numeral(0)
      const outSum = numeral(0)
      // 订单
      res.data.orderList.forEach(order => {
        orderSum.add(order.sum)
        const exist = list.find(
          x => x.monthStr === moment(order.createTime).format('YYYY年MM月')
        )
        if (exist) {
          exist.orderList.push(order)
          exist.orderSum.add(order.sum)
        } else {
          list.push({
            monthStr: moment(order.createTime).format('YYYY年MM月'),
            month: Number(moment(order.createTime).format('YYYYMM')),
            orderList: [order],
            orderSum: numeral(order.sum),
            paymentList: [],
            paymentSum: numeral(0),
            paymentRealSum: numeral(0),
            outList: [],
            outSum: numeral(0)
          })
        }
      })
      // 付款
      res.data.paymentList.filter(x => x.status === '审核通过').forEach(payment => {
        paymentSum.add(payment.money)
        paymentRealSum.add(payment.realMoney)
        const exist = list.find(
          x => x.monthStr === moment(payment.createTime).format('YYYY年MM月')
        )
        if (exist) {
          exist.paymentList.push(payment)
          exist.paymentSum.add(payment.money)
          exist.paymentRealSum.add(payment.realMoney)
        } else {
          list.push({
            monthStr: moment(payment.createTime).format('YYYY年MM月'),
            month: Number(moment(payment.createTime).format('YYYYMM')),
            orderList: [],
            orderSum: numeral(0),
            paymentList: [payment],
            paymentSum: numeral(payment.money),
            paymentRealSum: numeral(payment.realMoney),
            outList: [],
            outSum: numeral(0)
          })
        }
      })
      // 开支
      res.data.outList.filter(x => x.status === '审核通过').forEach(out => {
        outSum.add(out.sum)
        const exist = list.find(
          x => x.monthStr === moment(out.createTime).format('YYYY年MM月')
        )
        if (exist) {
          exist.outList.push(out)
          exist.outSum.add(out.sum)
        } else {
          list.push({
            monthStr: moment(out.createTime).format('YYYY年MM月'),
            month: Number(moment(out.createTime).format('YYYYMM')),
            orderList: [],
            orderSum: numeral(0),
            paymentList: [],
            paymentSum: numeral(0),
            paymentRealSum: numeral(0),
            outList: [out],
            outSum: numeral(out.sum)
          })
        }
      })
      list.push({
        monthStr: '合计',
        month: 999999,
        orderSum,
        paymentSum,
        paymentRealSum,
        outSum
      })
      this.setState({
        tableList: list.sort((a, b) => a.month - b.month),
        loading: false
      })
    })
  }
  componentDidMount() {
    this.getList()
  }
  render() {
    const { date, tableList } = this.state
    return (
      <div>
        {' '}
        <Card title="选择起始时间" size="small" className={styles.formCard}>
          <Form layout="inline">
            <Form.Item>
              <RangePicker
                ranges={{
                  去年: [
                    moment()
                      .add(-1, 'year')
                      .startOf('year'),
                    moment()
                      .add(-1, 'year')
                      .endOf('year')
                  ],
                  上月: [
                    moment()
                      .add(-1, 'months')
                      .startOf('month'),
                    moment()
                      .add(-1, 'months')
                      .endOf('month')
                  ],
                  本月: [moment().startOf('month'), moment().endOf('month')],
                  本年: [moment().startOf('year'), moment().endOf('year')]
                }}
                value={date}
                onChange={dates => {
                  this.setState({
                    date: dates
                  })
                }}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                icon="search"
                onClick={() => {
                  this.getList()
                }}
              >
                搜索
              </Button>
            </Form.Item>
          </Form>
        </Card>
        <Table
          style={{ marginTop: '30px' }}
          columns={this.columns}
          pagination={false}
          dataSource={tableList}
          size="small"
          bordered={false}
          rowKey="month"
        />
      </div>
    )
  }
}

export default Reporter
