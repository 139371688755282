import React, { PureComponent } from 'react'
import { Modal, Carousel, Icon } from 'antd'
import ReactDOM from 'react-dom'
import styles from './style.module.less'

interface IProps {
  imgs: Array<{
    src: string
    title: string
  }>
  current: number
}

let startX = 0
let startY = 0
let isMoving = false
class ImageView extends PureComponent<IProps> {
  constructor(props: IProps) {
    super(props)
    this.state.pageIndex = props.current
  }
  state = {
    visible: true,
    zoom: 1,
    pageIndex: 0,
    rotate: 0,
    relativeX: 0,
    relativeY: 0
  }
  slider: Carousel = null
  static show = (
    imgs: Array<{
      src: string
      title: string
    }>,
    current: number = 0
  ) => {
    let imgViewContainer = document.getElementById('imageView-container')
    if (!imgViewContainer) {
      imgViewContainer = document.createElement('div')
      imgViewContainer.id = 'imageView-container'
      document.body.appendChild(imgViewContainer)
    }
    const reactElement = ReactDOM.createPortal(
      <ImageView imgs={imgs} current={current} />,
      document.body,
      new Date().getTime().toString()
    )
    ReactDOM.render(reactElement, imgViewContainer)
  }
  dragStart(e) {
    isMoving = true
    startX = e.pageX
    startY = e.pageY
  }
  draging(e) {
    if (isMoving) {
      this.setState({
        relativeX: e.pageX - startX,
        relativeY: e.pageY - startY
      })
    }
  }
  dragEnd(e) {
    isMoving = false
    startX = 0
    startY = 0
  }
  componentDidMount() {
    setTimeout(() => {
      this.slider.goTo(this.props.current)
    }, 100)
  }
  render() {
    const { imgs, current } = this.props
    const {
      visible,
      zoom,
      pageIndex,
      rotate,
      relativeX,
      relativeY
    } = this.state
    return (
      <Modal
        title="查看图片"
        width="840px"
        visible={visible}
        className={styles.imageView}
        onCancel={() => {
          this.setState({
            visible: false
          })
        }}
        bodyStyle={{
          height: 'calc(100vh - 300px)',
          position: 'relative'
        }}
      >
        <Carousel
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
            overflow: 'hidden'
          }}
          dots={false}
          ref={slider => (this.slider = slider)}
          beforeChange={(from, to) => {
            this.setState({
              zoom: 1,
              pageIndex: to,
              rotate: 0
            })
            isMoving = false
            startX = 0
            startY = 0
          }}
        >
          {imgs.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  height: '100%',
                  width: '100%',
                  position: 'relative'
                }}
                onWheel={e => {
                  if (e.deltaY < 0) {
                    if (zoom < 2) {
                      const temp = zoom + 0.1
                      this.setState({
                        zoom: temp
                      })
                    }
                  } else {
                    if (zoom > 0.2) {
                      const temp = zoom - 0.1
                      this.setState({
                        zoom: temp
                      })
                    }
                  }
                }}
                onMouseDown={e => {
                  this.dragStart(e)
                }}
                onMouseMove={e => {
                  this.draging(e)
                }}
                onMouseUp={e => {
                  this.dragEnd(e)
                }}
              >
                <div
                  style={{
                    position: 'relative',
                    height: 'calc(100vh - 300px)',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  {pageIndex === index && (
                    <img
                      alt={item.src}
                      src={item.src}
                      style={{
                        position: 'relative',
                        maxWidth: '760px',
                        maxHeight: 'calc(100vh - 300px)',
                        display: 'block',
                        margin: 'auto',
                        transform: `scale(${zoom}) rotate(${rotate}deg)`,
                        transition: 'all .5s',
                        left: `${relativeX}px`,
                        top: `${relativeY}px`,
                        cursor: 'move'
                      }}
                    />
                  )}
                  <div
                    style={{
                      position: 'absolute',
                      bottom: '32px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      lineHeight: '40px',
                      userSelect: 'none'
                    }}
                  >
                    <Icon
                      type="undo"
                      className={styles.rotate}
                      style={{
                        marginRight: '4px'
                      }}
                      onClick={() => {
                        this.setState({
                          rotate: this.state.rotate - 90
                        })
                      }}
                    />
                    <Icon
                      type="redo"
                      className={styles.rotate}
                      style={{
                        marginLeft: '4px'
                      }}
                      onClick={() => {
                        this.setState({
                          rotate: this.state.rotate + 90
                        })
                      }}
                    />
                  </div>
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      width: '100%',
                      height: '32px',
                      lineHeight: '32px',
                      textAlign: 'center',
                      background: 'rgba(0,0,0,0.6)',
                      color: '#fff',
                      fontSize: '18px',
                      userSelect: 'none'
                    }}
                  >
                    {item.title}
                  </div>
                </div>
              </div>
            )
          })}
        </Carousel>
        {imgs.length > 1 && (
          <div>
            <Icon
              type="left"
              className={styles.left}
              onClick={() => {
                this.slider.prev()
              }}
            />
            <Icon
              type="right"
              className={styles.right}
              onClick={() => {
                this.slider.next()
              }}
            />
          </div>
        )}
      </Modal>
    )
  }
}

export default ImageView
