import loadable from '@loadable/component'

import Link from './pages/link'
import Menu from './pages/menu'
import Role from './pages/role'
import User from './pages/user'
import Index from './pages/index'
import Order from './pages/order'
import Payment from './pages/payment'
import PaymentDetail from './pages/paymentDetail'
import Product from './pages/product'
// import NewOrder from './pages/order/newOrder'
import Customer from './pages/customer'
import Address from './pages/address'
import ViewOrder from './pages/order/viewOrder'
// import Account from './pages/customer/account'
// import OrderEdit from './pages/order/edit'
import Clerk from './pages/clerk'
import Express from './pages/express'
// import Unpaid from './pages/order/unpaid'
import OrderDetail from './pages/order/orderDetail'
import Sender from './pages/sender'
import Out from './pages/out'
import OutCategory from './pages/outCategory'
// import OutAnalysis from './pages/out/analysis'
// import OrderAnalysis from './pages/order/analysis'
import Reporter from './pages/out/reporter'
// import BankReceipt from './pages/utils/receipt'
import Design from './pages/utils/design'
import FileFix from './pages/utils/fileFix'
import InvoicePage from './pages/invoice'
import CompanyPage from './pages/company'
import InInvoicePage from './pages/inInvoice'
import CustomerAddress from './pages/utils/address'
import Permission from './pages/permission'
import Checkout from './pages/checkout'

const Account = loadable(() => import('./pages/customer/account'))
const Unpaid = loadable(() => import('./pages/order/unpaid'))
const BankReceipt = loadable(() => import('./pages/utils/receipt'))
const OutAnalysis = loadable(() => import('./pages/out/analysis'))
const OrderAnalysis = loadable(() => import('./pages/order/analysis'))
const OrderEdit = loadable(() => import('./pages/order/edit'))
const NewOrder = loadable(() => import('./pages/order/newOrder'))

export default [
  {
    path: '/',
    component: Index,
    requireAuth: true,
    cache: true
  },
  {
    path: '/user',
    component: User,
    requireAuth: true,
    cache: true
  },
  {
    path: '/role',
    component: Role,
    requireAuth: true,
    cache: true
  },
  {
    path: '/menu',
    component: Menu,
    requireAuth: true,
    cache: true
  },
  {
    path: '/link',
    component: Link,
    requireAuth: true,
    cache: true
  },
  {
    path: '/order',
    component: Order,
    requireAuth: true,
    cache: true
  },
  {
    path: '/payment',
    component: Payment,
    requireAuth: true,
    cache: true
  },
  {
    path: '/paymentDetail',
    component: PaymentDetail,
    requireAuth: true,
    cache: true
  },
  {
    path: '/product',
    component: Product,
    requireAuth: true,
    cache: true
  },
  {
    path: '/newOrder',
    component: NewOrder,
    requireAuth: true,
    cache: true
  },
  {
    path: '/customer',
    component: Customer,
    requireAuth: true,
    cache: true
  },
  {
    path: '/address',
    component: Address,
    requireAuth: true,
    cache: true
  },
  {
    path: '/viewOrder',
    component: ViewOrder,
    requireAuth: true,
    cache: true
  },
  {
    path: '/account',
    component: Account,
    requireAuth: true,
    cache: true
  },
  {
    path: '/orderEdit/:id',
    component: OrderEdit,
    requireAuth: true,
    cache: false
  },
  {
    path: '/clerk',
    component: Clerk,
    requireAuth: true,
    cache: true
  },
  {
    path: '/express',
    component: Express,
    requireAuth: true,
    cache: true
  },
  {
    path: '/unpaid',
    component: Unpaid,
    requireAuth: true,
    cache: true
  },
  {
    path: '/orderDetail/:id',
    component: OrderDetail,
    requireAuth: true,
    cache: true
  },
  {
    path: '/sender',
    component: Sender,
    requireAuth: true,
    cache: true
  },
  {
    path: '/out',
    component: Out,
    requireAuth: true,
    cache: true
  },
  {
    path: '/outCategory',
    component: OutCategory,
    requireAuth: true,
    cache: true
  },
  {
    path: '/outAnalysis',
    component: OutAnalysis,
    requireAuth: true,
    cache: true
  },
  {
    path: '/orderAnalysis',
    component: OrderAnalysis,
    requireAuth: true,
    cache: true
  },
  {
    path: '/reporter',
    component: Reporter,
    requireAuth: true,
    cache: true
  },
  {
    path: '/utils/receipt',
    component: BankReceipt,
    requireAuth: true,
    cache: true
  },
  {
    path: '/utils/design',
    component: Design,
    requireAuth: true,
    cache: true
  },
  {
    path: '/utils/fileFix',
    component: FileFix,
    requireAuth: true,
    cache: true
  },
  {
    path: '/invoice/list',
    component: InvoicePage,
    requireAuth: true,
    cache: true
  },
  {
    path: '/invoice/company',
    component: CompanyPage,
    requireAuth: true,
    cache: true
  },
  {
    path: '/invoice/inInvoice',
    component: InInvoicePage,
    requireAuth: true,
    cache: true
  },
  {
    path: '/utils/address',
    component: CustomerAddress,
    requireAuth: true,
    cache: true
  },
  {
    path: '/permission',
    component: Permission,
    requireAuth: true,
    cache: true
  },
  {
    path: '/checkout',
    component: Checkout,
    requireAuth: true,
    cache: true
  }
]
