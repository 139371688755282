import { Backend, IApi } from './backend'
import { AxiosResponse } from 'axios'
import { IRequest } from './index'
import IInInvoice from './../models/inInvoice'

export default class InInvoice extends Backend {
  static getTableData(request: {
    pageSize: number
    page: number
    startTime: Date
    endTime: Date
    companyId: number
  }): Promise<AxiosResponse<{ rows: IInInvoice[]; count: number }>> {
    const api: IApi = {
      url: '/api/inInvoice/tableData',
      method: 'get',
      data: { request }
    }
    return this.request<{ rows: IInInvoice[]; count: number }>(api)
  }
  static createOrUpdate(data: any): Promise<AxiosResponse<IInInvoice>> {
    const api: IApi = {
      url: '/api/inInvoice/createOrUpdate',
      method: 'post',
      data
    }
    return this.request<IInInvoice>(api)
  }
  static delete(id: number): Promise<AxiosResponse> {
    const api: IApi = {
      url: `/api/inInvoice/${id}`,
      method: 'delete'
    }
    return this.request(api)
  }
}
