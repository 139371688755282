import React, { PureComponent } from 'react'
import styles from './style.module.less'
import {
  Form,
  Input,
  Button,
  Select,
  Spin,
  Tooltip,
  message,
  Modal
} from 'antd'
import axios from 'axios'
import ReactPictureViewer from 'react-picture-viewer'
import moment from 'moment'
import numeral from 'numeral'
import ImageView from './../../components/imageView'
import api from './../../api'
import IUser from './../../models/user'
import IOrder from './../../models/order'
import { createHashHistory } from 'history'
import { connect } from 'react-redux'
import { INav } from './../../reducers/nav'
import { openLocalFile } from './../../utils/localFile'
import copy from 'copy-to-clipboard'
import PrintModal from './printModal'
import TrackModal from './../express/track'
import IExpress from '../../models/express'
import { LazyImage } from './../../components/lazyImage'
const { confirm } = Modal

const history = createHashHistory()
// 一格ID
const YGID = 152

interface IState {
  customerList: IUser[]
  selectedCustomer: IUser
  list: Array<{
    date: string
    orders: IOrder[]
  }>
  count: number
  sum: number
  paid: number
  listLoading: boolean
  search: string
  yearList: number[]
  year: number
  month: number
  printModalOpen: boolean
  printModalItem: IOrder
  trackModalOpen: boolean
  trackModalItem: IExpress
  cdrFilePreviewOpen: boolean
  cdrFilePreviewOpen2: boolean
  cdrFilePreview: string
}

interface IProps {
  pushNav: (data: INav) => void
  user: IUser
}

class ViewOrder extends PureComponent<IProps> {
  state: IState = {
    customerList: [],
    selectedCustomer: {} as IUser,
    list: [] as Array<{
      date: string
      orders: IOrder[]
    }>,
    count: 0,
    sum: 0,
    paid: 0,
    listLoading: false,
    search: '',
    yearList: [],
    year: moment().year(),
    month: moment().month(),
    printModalOpen: false,
    printModalItem: {} as IOrder,
    trackModalOpen: false,
    trackModalItem: {} as IExpress,
    cdrFilePreviewOpen: false,
    cdrFilePreviewOpen2: false,
    cdrFilePreview: ''
  }
  scrollDom: HTMLDivElement = null
  getCustomerList() {
    api.meta.getCustomerList().then(res => {
      this.setState({
        customerList: res.data
      })
    })
  }
  componentDidMount() {
    this.getCustomerList()
    this.getViewList()
    this.yearInit()
  }
  dateChange(date: moment.Moment) {
    this.setState({
      month: date,
      monthFormat: 'YYYY-MM',
      dateRange: 'month'
    })
  }
  getViewList() {
    this.setState({
      listLoading: true,
      list: []
    })
    const data = {
      year: this.state.year,
      month: this.state.month,
      customerId: this.state.selectedCustomer
        ? this.state.selectedCustomer.id
        : 0
    }
    api.order.getViewList(data).then(res => {
      this.setState({
        list: res.data.list,
        listLoading: false,
        count: res.data.count,
        sum: res.data.sum,
        paid: res.data.paid
      })
    })
  }
  // 输入订单号检索
  filter(e) {
    this.setState({
      search: e.target.value
    })
  }
  yearInit() {
    const arr = []
    for (let i = 2015; i <= moment().year(); i++) {
      arr.push(i)
    }
    this.setState({
      yearList: arr
    })
  }
  // 打印 查看快递单
  express(order: IOrder) {
    if (order.express) {
      this.setState({
        trackModalOpen: true,
        trackModalItem: order.express
      })
    } else {
      this.setState({
        printModalOpen: true,
        printModalItem: order
      })
    }
  }
  // 删除订单
  deleteOrder(item, order: IOrder) {
    confirm({
      title: '确定删除？',
      content: `确定${order.customer.realName}的总金额${order.sum}元的${order.orderId}订单？`,
      onOk: () => {
        api.order.delete(order.id).then(res => {
          if (res) {
            message.success('删除成功')
            const list = this.state.list
            const deleteItem = list.find(x => x.date === item.date)
            deleteItem.orders.splice(
              deleteItem.orders.findIndex(x => x.id === order.id),
              1
            )
            this.setState({
              list,
              count: this.state.count - 1,
              sum: numeral(this.state.sum)
                .subtract(order.sum)
                .value(),
              paid: numeral(this.state.paid)
                .subtract(order.paid)
                .value()
            })
          } else {
            message.error('删除失败')
          }
        })
      }
    })
  }
  // 预览cdr文件
  previewCdr(path: string) {
    api.order.getSvg(`${path}.svg`).then(res => {
      if (res.data) {
        axios
          .get(res.data)
          .then(() => {
            this.setState(
              {
                cdrFilePreviewOpen: true,
                cdrFilePreview: res.data
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    cdrFilePreviewOpen2: true
                  })
                }, 500 + 10)
              }
            )
          })
          .catch(() => {
            message.error('无预览文件')
          })
      }
    })
  }
  handleCdrPreviewMaskClick = () => {
    this.setState({
      cdrFilePreviewOpen: false,
      cdrFilePreviewOpen2: false,
      cdrFilePreview: ''
    })
  }
  render() {
    const {
      customerList,
      list,
      count,
      sum,
      paid,
      listLoading,
      search,
      yearList,
      year,
      month,
      selectedCustomer,
      printModalOpen,
      printModalItem,
      trackModalOpen,
      trackModalItem
    } = this.state
    return (
      <div className={styles.viewOrder}>
        <Form layout="inline" className={styles.form}>
          <Form.Item>
            <Button
              type="primary"
              icon="backward"
              onClick={() => {
                if (month === 0) {
                  this.setState(
                    {
                      year: this.state.year - 1,
                      month: 11
                    },
                    () => {
                      this.getViewList()
                    }
                  )
                } else if (month === 99) {
                  this.setState(
                    {
                      year: this.state.year - 1
                    },
                    () => {
                      this.getViewList()
                    }
                  )
                } else {
                  this.setState(
                    {
                      month: this.state.month - 1
                    },
                    () => {
                      this.getViewList()
                    }
                  )
                }
              }}
            >
              上一月
            </Button>
          </Form.Item>
          <Form.Item>
            <Select
              value={year}
              onChange={value => {
                this.setState({
                  year: value
                })
              }}
              style={{
                width: '100px'
              }}
            >
              {yearList.map(item => {
                return (
                  <Select.Option value={item} key={item}>
                    {item}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Select
              value={month}
              onChange={value => {
                this.setState({
                  month: value
                })
              }}
              style={{
                width: '100px'
              }}
            >
              <Select.Option value={0}>1</Select.Option>
              <Select.Option value={1}>2</Select.Option>
              <Select.Option value={2}>3</Select.Option>
              <Select.Option value={3}>4</Select.Option>
              <Select.Option value={4}>5</Select.Option>
              <Select.Option value={5}>6</Select.Option>
              <Select.Option value={6}>7</Select.Option>
              <Select.Option value={7}>8</Select.Option>
              <Select.Option value={8}>9</Select.Option>
              <Select.Option value={9}>10</Select.Option>
              <Select.Option value={10}>11</Select.Option>
              <Select.Option value={11}>12</Select.Option>
              <Select.Option value={99}>全年</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              icon="forward"
              onClick={() => {
                if (month === 11) {
                  this.setState(
                    {
                      year: this.state.year + 1,
                      month: 0
                    },
                    () => {
                      this.getViewList()
                    }
                  )
                } else if (month === 99) {
                  this.setState(
                    {
                      year: this.state.year + 1
                    },
                    () => {
                      this.getViewList()
                    }
                  )
                } else {
                  this.setState(
                    {
                      month: this.state.month + 1
                    },
                    () => {
                      this.getViewList()
                    }
                  )
                }
              }}
            >
              下一月
            </Button>
          </Form.Item>
          {this.props.user.type !== '客户' && (
            <Form.Item>
              <Select
                allowClear={true}
                showSearch={true}
                optionFilterProp="children"
                value={selectedCustomer.id}
                onChange={value => {
                  this.setState({
                    selectedCustomer: customerList.find(
                      x => x.id === value
                    ) || {
                      id: 0
                    }
                  })
                }}
                style={{
                  width: '300px'
                }}
                placeholder="请选择客户"
              >
                <Select.Option value={0}>全部</Select.Option>
                {customerList.map(item => (
                  <Select.Option value={item.id} key={item.id}>
                    {item.realName} - {item.company}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item>
            <Button
              type="primary"
              icon="search"
              onClick={() => {
                this.getViewList()
              }}
            >
              查找
            </Button>
          </Form.Item>
          <Form.Item className={styles.currentMonth}>
            <Button
              type="primary"
              icon="search"
              onClick={() => {
                this.setState(
                  {
                    selectedCustomer: { id: 0 },
                    year: moment().year(),
                    month: moment().month()
                  },
                  () => {
                    this.getViewList()
                  }
                )
              }}
            >
              查看本月
            </Button>
          </Form.Item>
          <Form.Item className={styles.filter}>
            <Input
              placeholder="订单号"
              onChange={e => {
                this.filter(e)
              }}
            />
          </Form.Item>
        </Form>
        <div
          className={styles.info}
        >{`共${count}个订单  总金额${sum}元，已付${paid}元，未付${numeral(sum)
          .subtract(paid)
          .value()}元`}</div>
        <div className={styles.orderList} ref={dom => (this.scrollDom = dom)}>
          {listLoading && <Spin size="large" className={styles.spin} />}
          {list.map(item => {
            return (
              <div className={styles.dayItem} key={item.date}>
                <div className={styles.title}>
                  {item.date} 共{item.orders.length}个订单，
                  {item.orders
                    .map(x => x.sum)
                    .reduce(
                      (a, b) =>
                        numeral(a)
                          .add(b)
                          .value(),
                      0
                    )}
                  元
                </div>
                {item.orders.map(order => {
                  if (
                    order.orderId
                      .toString()
                      .slice(-3)
                      .indexOf(search) > -1
                  ) {
                    return (
                      <div className={styles.orderItem} key={order.id}>
                        <div className={styles.title}>
                          <span>
                            {`${order.orderId} - ${order.customer.realName}`}{' '}
                            金额：
                            {order.sum}元 收货人：
                            <Tooltip
                              placement="topLeft"
                              title={
                                order.address
                                  ? `${order.address.name},${order.address.phone},${order.address.province}${order.address.city}${order.address.area}${order.address.detail}`
                                  : '无'
                              }
                            >
                              <a
                                onClick={() => {
                                  copy(
                                    order.address
                                      ? `${order.address.name},${order.address.phone},${order.address.province}${order.address.city}${order.address.area}${order.address.detail}`
                                      : '无'
                                  )
                                  message.success('复制成功')
                                }}
                              >
                                {order.address ? order.address.name : '无'}
                              </a>
                            </Tooltip>
                          </span>
                          <div className={styles.btns}>
                            {this.props.user.type !== '客户' &&
                              order.customer.id !== YGID && (
                                <Button
                                  size="small"
                                  type="primary"
                                  icon="container"
                                  className={styles.btn}
                                  onClick={() => {
                                    this.express(order)
                                  }}
                                >
                                  {order.express ? '查看快递单' : '打印快递单'}
                                </Button>
                              )}
                            {this.props.user.type === '客户' && order.express && (
                              <Button
                                size="small"
                                type="primary"
                                icon="container"
                                className={styles.btn}
                                onClick={() => {
                                  this.express(order)
                                }}
                              >
                                查看快递单
                              </Button>
                            )}
                            <Button
                              size="small"
                              type="primary"
                              icon="file"
                              className={styles.btn}
                              onClick={() => {
                                openLocalFile(order.cdrFile)
                              }}
                            >
                              文件
                            </Button>
                            {/* <Button
                              size="small"
                              type="primary"
                              icon="file-image"
                              className={styles.btn}
                              onClick={() => {
                                this.previewCdr(order.cdrFile)
                              }}
                            >
                              预览文件
                            </Button> */}
                            <Button
                              size="small"
                              type="primary"
                              icon="file-search"
                              className={styles.btn}
                              onClick={() => {
                                this.props.pushNav({
                                  title: `查看${order.orderId.toString()}`,
                                  path: `/orderDetail/${order.id}`
                                })
                                history.push(`/orderDetail/${order.id}`)
                              }}
                            >
                              查看
                            </Button>
                            <Button
                              size="small"
                              type="primary"
                              icon="printer"
                              className={styles.btn}
                              onClick={() => {
                                window.open(order.pdfFile, '_blank')
                              }}
                            >
                              打印
                            </Button>
                            {this.props.user.type !== '客户' && (
                              <Button
                                size="small"
                                type="primary"
                                icon="edit"
                                className={styles.btn}
                                disabled={order.paid > 0}
                                onClick={() => {
                                  this.props.pushNav({
                                    title: `修改${order.orderId.toString()}`,
                                    path: `/orderEdit/${order.id}`
                                  })
                                  history.push(`/orderEdit/${order.id}`)
                                }}
                              >
                                修改
                              </Button>
                            )}
                            {this.props.user.type !== '客户' && (
                              <Button
                                size="small"
                                type="primary"
                                icon="delete"
                                className={styles.btn}
                                disabled={order.paid > 0}
                                onClick={() => {
                                  this.deleteOrder(item, order)
                                }}
                              >
                                删除
                              </Button>
                            )}
                          </div>
                        </div>
                        <div className={styles.productList}>
                          {order.products.map((product, index) => {
                            return (
                              <div
                                key={product.id}
                                className={styles.productItem}
                              >
                                <div style={{ position: 'relative' }}>
                                  <LazyImage
                                    src={`${product.pic}!product`}
                                    className={styles.pic}
                                    onClick={() => {
                                      ImageView.show(
                                        order.products.map(pro => {
                                          return {
                                            src: pro.pic,
                                            title: pro.name
                                          }
                                        }),
                                        index
                                      )
                                    }}
                                    scrollDom={this.scrollDom}
                                  />
                                  {product.isComplete && (
                                    <img
                                      className={styles.complete}
                                      src={require('./../../assets/imgs/complete.svg')}
                                    />
                                  )}
                                </div>
                                <div>
                                  {product.name} x {product.count}
                                  {product.unit}
                                </div>
                                <div>{product.specification}</div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )
                  } else {
                    return <div key={order.id} />
                  }
                })}
              </div>
            )
          })}
        </div>
        {printModalOpen && (
          <PrintModal
            onClose={() => {
              this.setState({
                printModalOpen: false
              })
            }}
            complete={() => {
              this.setState({
                printModalOpen: false
              })
              this.getViewList()
            }}
            address={printModalItem.address}
            order={printModalItem}
          />
        )}
        {trackModalOpen && (
          <TrackModal
            onClose={() => {
              this.setState({ trackModalOpen: false })
            }}
            express={trackModalItem}
          />
        )}
        <div
          className={`${styles.cdrFilePreview} ${
            this.state.cdrFilePreviewOpen ? styles.show : ''
          }`}
        >
          {this.state.cdrFilePreviewOpen2 && (
            <ReactPictureViewer
              width="100%"
              height="100%"
              contain
              className={styles.reactPicturePreview}
            >
              <img
                src={this.state.cdrFilePreview}
                alt="cdrfile"
                draggable={false}
              />
            </ReactPictureViewer>
          )}
          <div
            className={styles.mask}
            onClick={this.handleCdrPreviewMaskClick}
          />
          <div
            className={styles.close}
            onClick={this.handleCdrPreviewMaskClick}
          >
            &times;
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: { User: IUser }) => {
  return { user: state.User }
}

const mapDispatchToProps = dispatch => {
  return {
    pushNav: (data: INav) => {
      dispatch({ type: 'PUSH_NAV_2', data })
      dispatch({ type: 'SET_ACTIVE_KEY', data: data.path })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewOrder)
