import React, { PureComponent } from 'react'
import styles from './style.module.less'
import { Spin, Icon, message } from 'antd'
import api from './../../api'
import ImageView from './../imageView'

interface IProps {
  fail: (msg: string) => void
  success: (data: string[]) => void
  path: string
  count?: number
  upload?: (file) => Promise<string>
  defaultUrls?: string[]
}

interface IState {
  isUploading: boolean
  isUploaded: boolean
  url: string[]
}

class MultipleImgUpload extends PureComponent<IProps> {
  state: IState = {
    isUploading: false,
    isUploaded: false,
    url: []
  }
  componentDidMount() {
    const { defaultUrls } = this.props
    if (!!defaultUrls) {
      this.setState({ url: defaultUrls })
    }
  }
  upload = e => {
    e.preventDefault()
    const { url } = this.state
    const { count = 9 } = this.props
    if (url.length >= count) {
      message.error(`仅支持上传${count}张图片，请删除后再上传`)
      return
    }
    if (e.clipboardData.types[0] === 'Files') {
      const file = e.clipboardData.items[0].getAsFile()
      if (file.type.indexOf('image') < 0) {
        this.props.fail('文件选择错误')
        return
      }
      this.fileUpload(file)
    }
  }
  onDrop(e) {
    e.preventDefault()
    const { url } = this.state
    const { count = 9 } = this.props
    if (url.length >= count) {
      message.error(`仅支持上传${count}张图片，请删除后再上传`)
      return
    }
    const file = e.dataTransfer.files[0]
    if (file.type.indexOf('image') < 0) {
      this.props.fail('文件选择错误')
      return
    }
    this.fileUpload(file)
  }
  fileUpload(file) {
    const { upload } = this.props
    this.setState({
      isUploading: true
    })
    if (!!upload) {
      upload(file)
        .then(res => {
          this.setState(
            {
              isUploaded: true,
              url: [...this.state.url, res],
              isUploading: false
            },
            () => {
              this.props.success(this.state.url)
            }
          )
        })
        .catch(() => {
          this.setState({
            isUploading: false
          })
        })
    } else {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('path', this.props.path)
      api.file
        .imgUpload(formData)
        .then(res => {
          this.setState(
            {
              isUploaded: true,
              url: [...this.state.url, res.data.url],
              isUploading: false
            },
            () => {
              this.props.success(this.state.url)
            }
          )
        })
        .catch(err => {
          this.setState({
            isUploading: false
          })
        })
    }
  }
  render() {
    const { isUploaded, url, isUploading } = this.state
    const { count = 9 } = this.props
    return (
      <div className={styles.multipleImgUpload}>
        {url.length > 0 && (
          <div className={styles.imgList}>
            {url.map((item, index) => {
              return (
                <div key={index} className={styles.imgWrapper}>
                  <img src={item} className={styles.img} />
                  <div className={styles.handler}>
                    <Icon
                      type="eye"
                      className={styles.btn}
                      style={{ marginRight: '6px' }}
                      onClick={() => {
                        ImageView.show(
                          url.map(x => {
                            return { src: x, title: '' }
                          })
                        )
                      }}
                    />
                    <Icon
                      type="delete"
                      className={styles.btn}
                      style={{ marginLeft: '6px' }}
                      onClick={() => {
                        url.splice(index, 1)
                        this.setState(
                          {
                            url: [...url]
                          },
                          () => {
                            this.props.success(url)
                          }
                        )
                      }}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        )}
        <div
          className={styles.imgUpload}
          onPaste={this.upload}
          contentEditable={url.length < count}
          suppressContentEditableWarning={true}
          onDragOver={e => {
            e.preventDefault()
          }}
          onDrop={e => {
            this.onDrop(e)
          }}
        >
          {/* {isUploaded && (
            <div className={styles.imgWrapper}>
              <img src={url} className={styles.img} />
              <span
                className={styles.close}
                onClick={() => {
                  this.setState({
                    isUploaded: false,
                    url: ''
                  })
                  this.props.success({ url: '' })
                }}
              >
                &times;
              </span>
            </div>
          )} */}
          {isUploading && <Spin size="large" />}
          {!isUploading && <span>请将图片粘贴或拖动在此处</span>}
        </div>
      </div>
    )
  }
}

export default MultipleImgUpload
