import { Backend, IApi } from './backend'
import { AxiosResponse } from 'axios'
import IRole from './../models/role'
import IMenu from './../models/menu'
import ILink from './../models/link'
import IUser from './../models/user'
import IAddress from './../models/address'
import IExpress from './../models/express'
import IOutCategory from './../models/outCategory'
import IOrder from './../models/order'
import IPayment from './../models/payment'
import IOut from './../models/out'
import ICompany from '../models/company'

export default class Meta extends Backend {
  static getRoleList(): Promise<AxiosResponse<IRole[]>> {
    const api: IApi = {
      url: '/api/meta/getRoleList',
      method: 'get'
    }
    return this.request<IRole[]>(api)
  }

  static getParentMenuList(): Promise<AxiosResponse<IMenu[]>> {
    const api: IApi = {
      url: '/api/meta/getParentMenuList',
      method: 'get'
    }
    return this.request<IMenu[]>(api)
  }

  static getMenuList(): Promise<AxiosResponse<IMenu[]>> {
    const api: IApi = {
      url: '/api/meta/getMenuList',
      method: 'get'
    }
    return this.request<IMenu[]>(api)
  }

  static getLinkList(): Promise<AxiosResponse<ILink[]>> {
    const api: IApi = {
      url: '/api/meta/getLinkList',
      method: 'get'
    }
    return this.request<ILink[]>(api)
  }

  static getCustomerList(): Promise<AxiosResponse<IUser[]>> {
    const api: IApi = {
      url: '/api/meta/getCustomerList',
      method: 'get'
    }
    return this.request<IUser[]>(api)
  }

  static getAddressList(): Promise<AxiosResponse<IAddress[]>> {
    const api: IApi = {
      url: '/api/meta/getAddressList',
      method: 'get'
    }
    return this.request<IAddress[]>(api)
  }

  static getLatestExpressList(): Promise<AxiosResponse<IExpress[]>> {
    const api: IApi = {
      url: '/api/meta/getLatestExpressList',
      method: 'get'
    }
    return this.request<IExpress[]>(api)
  }

  static getLv2MenuList(): Promise<AxiosResponse<IMenu[]>> {
    const api: IApi = {
      url: '/api/meta/getLv2MenuList',
      method: 'get'
    }
    return this.request<IMenu[]>(api)
  }

  static getAllOutCategory(): Promise<AxiosResponse<IOutCategory[]>> {
    const api: IApi = {
      url: '/api/meta/getAllOutCategory',
      method: 'get'
    }
    return this.request<IOutCategory[]>(api)
  }

  static reporter(
    startTime: Date,
    endTime: Date
  ): Promise<
    AxiosResponse<{
      orderList: IOrder[]
      paymentList: IPayment[]
      outList: IOut[]
    }>
  > {
    const api: IApi = {
      url: '/api/meta/reporter',
      method: 'get',
      data: { startTime, endTime }
    }
    return this.request<{
      orderList: IOrder[]
      paymentList: IPayment[]
      outList: IOut[]
    }>(api)
  }

  static getCompanyList(): Promise<AxiosResponse<ICompany[]>> {
    const api: IApi = {
      url: '/api/meta/getCompanyList',
      method: 'get'
    }
    return this.request<ICompany[]>(api)
  }

  static getClerk(): Promise<AxiosResponse<IUser[]>> {
    const api: IApi = {
      url: '/api/meta/getClerk',
      method: 'get'
    }
    return this.request<IUser[]>(api)
  }
}
