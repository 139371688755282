import { Backend, IApi } from './backend'
import { AxiosResponse } from 'axios'
import { IRequest } from './index'
import IOut from './../models/out'
import IOutVerify from '../models/outVerify'

export default class Out extends Backend {
  static getTableData(
    request: IRequest
  ): Promise<AxiosResponse<{ rows: IOut[]; count: number }>> {
    const api: IApi = {
      url: '/api/out/tableData',
      method: 'get',
      data: { request }
    }
    return this.request<{ rows: IOut[]; count: number }>(api)
  }
  static createOrUpdate(data: any): Promise<AxiosResponse<IOut>> {
    const api: IApi = {
      url: '/api/out/createOrUpdate',
      method: 'post',
      data
    }
    return this.request<IOut>(api)
  }
  static delete(id: number): Promise<AxiosResponse> {
    const api: IApi = {
      url: `/api/out/${id}`,
      method: 'delete'
    }
    return this.request(api)
  }

  static analysis(
    startTime: Date,
    endTime: Date
  ): Promise<AxiosResponse<IOut[]>> {
    const api: IApi = {
      url: '/api/out/analysis',
      method: 'get',
      data: { startTime, endTime }
    }
    return this.request<IOut[]>(api)
  }

  static getMyVerifies(): Promise<AxiosResponse<IOutVerify[]>> {
    const api: IApi = {
      url: `/api/out/getMyVerifies`,
      method: 'get'
    }
    return this.request<IOutVerify[]>(api)
  }

  static verifyAccept(data: IOutVerify): Promise<AxiosResponse<IOutVerify>> {
    const api: IApi = {
      url: `/api/out/verifyAccept`,
      method: 'post',
      data
    }
    return this.request<IOutVerify>(api)
  }

  static verifyRefuse(data: IOutVerify): Promise<AxiosResponse<IOutVerify>> {
    const api: IApi = {
      url: `/api/out/verifyRefuse`,
      method: 'post',
      data
    }
    return this.request<IOutVerify>(api)
  }
}
