import React, { PureComponent } from 'react'
import { Form as AntdForm, Input, Select, Switch, message } from 'antd'
import { FormComponentProps } from 'antd/es/form'
import numeral from 'numeral'
import MultipleImgUpload from './../../components/upload/multipleImgUpload'
import api from './../../api'
import styles from './style.module.less'
import IOut from './../../models/out'
import IOutCategory from './../../models/outCategory'

interface IReciveProps extends FormComponentProps {
  onRef: (form: Form) => void
  complete: (out: IOut) => void
  validateFail: () => void
  data: IOut
}

interface IState {
  outCategoryList: IOutCategory[]
  selectedOutCategory: IOutCategory
}

class Form extends PureComponent<IReciveProps, any> {
  constructor(props: IReciveProps) {
    super(props)
  }
  state: IState = {
    outCategoryList: [],
    selectedOutCategory: {} as IOutCategory
  }
  submit = () => {
    this.props.form.validateFields((err: any, values: any) => {
      if (err) {
        this.props.validateFail()
      } else {
        api.out
          .createOrUpdate(values)
          .then(res => {
            if (res.status === 200) {
              this.props.complete(res.data)
            }
          })
          .catch(() => {
            this.props.validateFail()
          })
      }
    })
  }
  getOutCategoryList() {
    api.meta.getAllOutCategory().then(res => {
      this.setState({
        outCategoryList: res.data
      })
    })
  }
  componentDidMount() {
    this.props.onRef(this)
    this.getOutCategoryList()
  }
  render() {
    const { outCategoryList, selectedOutCategory } = this.state
    const { getFieldDecorator } = this.props.form
    return (
      <AntdForm
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        labelAlign="right"
        className={styles.form}
      >
        <AntdForm.Item label="" className={styles.displayNone}>
          {getFieldDecorator('id', {
            rules: [{ required: false, message: '' }],
            initialValue: this.props.data.id
          })(<Input placeholder="" />)}
        </AntdForm.Item>
        <AntdForm.Item label="名称" className={styles.displayNormal}>
          {getFieldDecorator('name', {
            rules: [{ required: true, message: '名称' }],
            initialValue: this.props.data.name
          })(<Input placeholder="名称" />)}
        </AntdForm.Item>
        <AntdForm.Item label="单价" className={styles.displayNormal}>
          {getFieldDecorator('price', {
            rules: [{ required: true, message: '单价' }],
            initialValue: this.props.data.price
          })(<Input placeholder="单价" />)}
        </AntdForm.Item>
        <AntdForm.Item label="数量" className={styles.displayNormal}>
          {getFieldDecorator('count', {
            rules: [{ required: true, message: '数量' }],
            initialValue: this.props.data.count || 1
          })(<Input placeholder="数量" />)}
        </AntdForm.Item>
        <AntdForm.Item label="总价" className={styles.displayNormal}>
          {getFieldDecorator('sum', {
            rules: [{ required: true, message: '总价' }],
            initialValue: this.props.data.sum
          })(<Input placeholder="总价" />)}
        </AntdForm.Item>
        <AntdForm.Item
          label="分类"
          className={styles.displayNormal}
          extra={selectedOutCategory.desc}
        >
          {getFieldDecorator('outCategoryId', {
            rules: [{ required: true, message: '分类' }],
            initialValue: this.props.data.outCategoryId
          })(
            <Select
              placeholder="分类"
              onChange={value => {
                this.setState({
                  selectedOutCategory: this.state.outCategoryList.find(
                    x => x.id === value
                  )
                })
              }}
            >
              {outCategoryList.map(item => {
                return (
                  <Select.Option value={item.id} key={item.id}>
                    {item.name}
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </AntdForm.Item>
        <AntdForm.Item label="图片" className={styles.displayNormal}>
          {getFieldDecorator('pic', {
            rules: [{ required: false, message: '图片' }],
            initialValue: this.props.data.pic
          })(<Input placeholder="图片" disabled={true} />)}
          <MultipleImgUpload
            path="out"
            fail={msg => {
              message.warning(msg)
            }}
            success={url => {
              this.props.form.setFieldsValue({
                pic: url
              })
            }}
            defaultUrls={!!this.props.data.pic ? this.props.data.pic : []}
          />
        </AntdForm.Item>
        {/* <AntdForm.Item label="语音" className={styles.displayNormal}>
          {getFieldDecorator('voice', {
            rules: [{ required: false, message: '语音' }],
            initialValue: this.props.data.voice
          })(<Input placeholder="语音" />)}
        </AntdForm.Item> */}
        <AntdForm.Item label="备注" className={styles.displayNormal}>
          {getFieldDecorator('remark', {
            rules: [{ required: false, message: '备注' }],
            initialValue: this.props.data.remark
          })(<Input placeholder="备注" />)}
        </AntdForm.Item>
      </AntdForm>
    )
  }
}

export default AntdForm.create<IReciveProps>({
  onValuesChange(props, changedValues, allValues) {
    if (changedValues.price || changedValues.count) {
      props.form.setFieldsValue({
        sum: numeral(allValues.price)
          .multiply(allValues.count)
          .value()
      })
    }
  }
})(Form)
