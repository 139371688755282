import { Backend, IApi } from './backend'
import { AxiosResponse } from 'axios'
import { IRequest } from './index'
import IInvoice from './../models/invoice'

export default class Invoice extends Backend {
  static getTableData(request: {
    pageSize: number
    page: number
    startTime: Date
    endTime: Date
    customerId: number
    companyId: number
  }): Promise<AxiosResponse<{ rows: IInvoice[]; count: number }>> {
    const api: IApi = {
      url: '/api/invoice/tableData',
      method: 'get',
      data: { request }
    }
    return this.request<{ rows: IInvoice[]; count: number }>(api)
  }
  static createOrUpdate(data: any): Promise<AxiosResponse<IInvoice>> {
    const api: IApi = {
      url: '/api/invoice/createOrUpdate',
      method: 'post',
      data
    }
    return this.request<IInvoice>(api)
  }
  static delete(id: number): Promise<AxiosResponse> {
    const api: IApi = {
      url: `/api/invoice/${id}`,
      method: 'delete'
    }
    return this.request(api)
  }

  static picUpload(
    data: FormData
  ): Promise<
    AxiosResponse<{
      url: string
      invoiceData: {
        words_result: {
          PurchaserName: string
          PurchaserRegisterNum: string
          InvoiceDate: string
          TotalAmount: string
          TotalTax: string
          AmountInFiguers: string
          InvoiceCode: string
          InvoiceNum: string
          CommodityName: Array<{ row: string; word: string }>
          CommodityType: Array<{ row: string; word: string }>
          CommodityUnit: Array<{ row: string; word: string }>
          CommodityNum: Array<{ row: string; word: string }>
          CommodityPrice: Array<{ row: string; word: string }>
          CommodityAmount: Array<{ row: string; word: string }>
          CommodityTaxRate: Array<{ row: string; word: string }>
          CommodityTax: Array<{ row: string; word: string }>
        }
        words_result_num: number
      }
    }>
  > {
    const api: IApi = {
      url: '/api/invoice/picUpload',
      method: 'post',
      data
    }
    return this.request(api)
  }
}
