import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { message } from 'antd'
import { createHashHistory } from 'history'
import sessionStorage from './../utils/sessionStorage'

const history = createHashHistory()

export class Backend {
  static backend: AxiosInstance = axios.create({
    headers: {
      'X-Real-IP': '10.0.2.123',
      authorization: sessionStorage.getString('authorization')
    }
  })

  static login(authorization: string) {
    this.backend = axios.create({
      headers: { 'X-Real-IP': '10.0.2.123', authorization }
    })
  }

  static request<T>(api: IApi): Promise<AxiosResponse<T>> {
    if (api.method === 'get') {
      api.params = api.data
    }
    return new Promise((resolve, reject) => {
      this.backend(api)
        .then(result => {
          resolve(result)
        })
        .catch(err => {
          const { status, data, config } = err.response
          // 未登录
          if (status === 401) {
            message.warning('未登录')
            history.push('/login')
          } else if (status === 403) {
            // 无权限
            message.warning(`${config.url} 无权限`)
          } else {
            const msg = data.message || data.msg || err.message
            if (msg) {
              message.error(msg)
            }
          }
          reject(err)
        })
    })
  }
}

export interface IApi {
  url: string
  data?: object
  params?: object | null
  method: 'get' | 'post' | 'put' | 'delete' | 'patch'
}
