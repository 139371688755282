import React, { PureComponent } from 'react'
import { Form as AntdForm, Input, Select, Switch } from 'antd'
import { FormComponentProps } from 'antd/es/form'
import api from './../../api'
import styles from './style.module.less'
import IPaymentDetail from './../../models/paymentDetail'

interface IReciveProps extends FormComponentProps {
  onRef: (form: Form) => void
  complete: (paymentDetail: IPaymentDetail) => void
  validateFail: () => void
  data: IPaymentDetail
}

class Form extends PureComponent<IReciveProps, any> {
  constructor(props: IReciveProps) {
    super(props)
  }
  submit = () => {
    this.props.form.validateFields((err: any, values: any) => {
      if (err) {
        this.props.validateFail()
      } else {
        api.paymentDetail
          .createOrUpdate(values)
          .then(res => {
            if (res.status === 200) {
              this.props.complete(res.data)
            }
          })
          .catch(() => {
            this.props.validateFail()
          })
      }
    })
  }
  componentDidMount() {
    this.props.onRef(this)
  }
  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <AntdForm
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        labelAlign="right"
        className={styles.form}
      >
        <AntdForm.Item label="" className={styles.displayNone}>
          {getFieldDecorator('id', {
            rules: [{ required: false, message: '' }],
            initialValue: this.props.data['id']
          })(<Input placeholder="" />)}
        </AntdForm.Item>
        <AntdForm.Item label="抵扣金额" className={styles.displayNormal}>
          {getFieldDecorator('money', {
            rules: [{ required: true, message: '抵扣金额' }],
            initialValue: this.props.data['money']
          })(<Input placeholder="抵扣金额" />)}
        </AntdForm.Item>
        <AntdForm.Item label="" className={styles.displayNormal}>
          {getFieldDecorator('paymentId', {
            rules: [{ required: false, message: '' }],
            initialValue: this.props.data['paymentId']
          })(
            <Select placeholder="">
              {this.state.paymentList.map((item, index) => {
                return (
                  <Select.Option value={item.id} key={index}>
                    {item.name}
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </AntdForm.Item>
        <AntdForm.Item label="" className={styles.displayNormal}>
          {getFieldDecorator('orderId', {
            rules: [{ required: false, message: '' }],
            initialValue: this.props.data['orderId']
          })(
            <Select placeholder="">
              {this.state.orderList.map((item, index) => {
                return (
                  <Select.Option value={item.id} key={index}>
                    {item.name}
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </AntdForm.Item>
      </AntdForm>
    )
  }
}

export default AntdForm.create<IReciveProps>()(Form)
