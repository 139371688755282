import React, { PureComponent } from 'react'
import { Form as AntdForm, Input } from 'antd'
import { FormComponentProps } from 'antd/es/form'
import api from './../../api'
import styles from './style.module.less'
import IRole from './../../models/role'

interface IReciveProps extends FormComponentProps {
  onRef: (form: Form) => void
  complete: (role: IRole) => void
  validateFail: () => void
  data: IRole
}

class Form extends PureComponent<IReciveProps, any> {
  constructor(props: IReciveProps) {
    super(props)
  }
  submit = () => {
    this.props.form.validateFields((err: any, values: any) => {
      if (err) {
        this.props.validateFail()
      } else {
        api.role
          .createOrUpdate(values)
          .then(res => {
            if (res.status === 200) {
              this.props.complete(res.data)
            }
          })
          .catch(() => {
            this.props.validateFail()
          })
      }
    })
  }
  componentDidMount() {
    this.props.onRef(this)
  }
  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <AntdForm
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        labelAlign="right"
        className={styles.form}
      >
        <AntdForm.Item label="" className={styles.displayNone}>
          {getFieldDecorator('id', {
            rules: [{ required: false, message: 'id' }],
            initialValue: this.props.data['id']
          })(<Input placeholder="" />)}
        </AntdForm.Item>
        <AntdForm.Item label="名称" className={styles.displayNormal}>
          {getFieldDecorator('name', {
            rules: [{ required: true, message: 'name' }],
            initialValue: this.props.data['name']
          })(<Input placeholder="名称" />)}
        </AntdForm.Item>
      </AntdForm>
    )
  }
}

export default AntdForm.create<IReciveProps>()(Form)
