import { combineReducers } from 'redux'
import user from './user'
import menu from './menu'
import nav from './nav'

export default combineReducers({
  ...user,
  ...menu,
  ...nav
})
