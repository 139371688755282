import React, { useState, useEffect } from 'react'
import './index.less'

const CMYK: React.FC<{
  initial?: string
  onChange: (str: string) => void
}> = props => {
  const { initial, onChange } = props
  const [c, setC] = useState(0)
  const [m, setM] = useState(0)
  const [y, setY] = useState(0)
  const [k, setK] = useState(0)
  const handleSetC = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value }
    } = e
    let num = Number(value)
    if (!isNaN(num)) {
      num = Math.floor(num)
      num = num > 100 ? 100 : num
      num = num < 0 ? 0 : num
      setC(num)
    }
  }
  const handleSetM = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value }
    } = e
    let num = Number(value)
    if (!isNaN(num)) {
      num = Math.floor(num)
      num = num > 100 ? 100 : num
      num = num < 0 ? 0 : num
      setM(num)
    }
  }
  const handleSetY = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value }
    } = e
    let num = Number(value)
    if (!isNaN(num)) {
      num = Math.floor(num)
      num = num > 100 ? 100 : num
      num = num < 0 ? 0 : num
      setY(num)
    }
  }
  const handleSetK = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value }
    } = e
    let num = Number(value)
    if (!isNaN(num)) {
      num = Math.floor(num)
      num = num > 100 ? 100 : num
      num = num < 0 ? 0 : num
      setK(num)
    }
  }
  useEffect(() => {
    onChange(`${c},${m},${y},${k}`)
  }, [c, m, y, k])
  useEffect(() => {
    if (initial) {
      const arr = initial.split(',')
      if (arr.length === 4) {
        setC(Number(arr[0]))
        setM(Number(arr[1]))
        setY(Number(arr[2]))
        setK(Number(arr[3]))
      }
    }
  }, [])
  return (
    <div className="cmyk-input">
      <div className="cmyk-input-label">C</div>
      <input
        type="text"
        className="cmyk-input-input"
        value={c}
        onChange={handleSetC}
      />
      <div className="cmyk-input-label">M</div>
      <input
        type="text"
        className="cmyk-input-input"
        value={m}
        onChange={handleSetM}
      />
      <div className="cmyk-input-label">Y</div>
      <input
        type="text"
        className="cmyk-input-input"
        value={y}
        onChange={handleSetY}
      />
      <div className="cmyk-input-label">K</div>
      <input
        type="text"
        className="cmyk-input-input"
        value={k}
        onChange={handleSetK}
      />
    </div>
  )
}

export default CMYK
