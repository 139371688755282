import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Card, Table, Button, message } from 'antd'
import { connect } from 'react-redux'
import IUser from '../../models/user'
import Prompt from '../../components/prompt'
import moment from 'moment'
import api from '../../api'
import ImageView from '../../components/imageView'
import './index.less'
import IOutVerify from '../../models/outVerify'
import IPaymentVerify from '../../models/paymentVerify'
import OutModal from './../out/modal'

const Index = (props: { user: IUser }) => {
  const [scrollY, setScrollY] = useState(300)
  const updateScrollY = useCallback(() => {
    setScrollY((window.innerHeight - 150) / 2 - 180)
  }, [])
  useEffect(() => {
    updateScrollY()
    window.addEventListener('resize', () => {
      updateScrollY()
    })
    // eslint-disable-next-line
  }, [])
  const [outList, setOutList] = useState<IOutVerify[]>([])
  const [paymentList, setPaymentList] = useState<IPaymentVerify[]>([])
  const [outModalVisible, setOutModalVisible] = useState(false)
  const getList = useCallback(() => {
    api.out.getMyVerifies().then(res => {
      setOutList(res.data)
    })
    api.payment.getMyVerifies().then(res => {
      setPaymentList(res.data)
    })
  }, [])
  useEffect(() => {
    getList()
    // eslint-disable-next-line
  }, [])
  const handleAcceptOut = useCallback(
    (item: IOutVerify) => {
      api.out.verifyAccept(item).then(() => {
        message.success('通过成功')
        getList()
      })
    },
    [getList]
  )
  const handleRefuseOut = useCallback(
    (item: IOutVerify) => {
      Prompt.show('请输入拒绝原因', '拒绝原因', '请输入').then(result => {
        api.out.verifyRefuse({ ...item, remark: result }).then(() => {
          message.success('拒绝成功')
          getList()
        })
      })
    },
    [getList]
  )

  const handleAcceptPayment = useCallback(
    (item: IPaymentVerify) => {
      api.payment.verifyAccept(item).then(() => {
        message.success('通过成功')
        getList()
      })
    },
    [getList]
  )
  const handleRefusePayment = useCallback(
    (item: IPaymentVerify) => {
      Prompt.show('请输入拒绝原因', '拒绝原因', '请输入').then(result => {
        api.payment.verifyRefuse({ ...item, remark: result }).then(() => {
          message.success('拒绝成功')
          getList()
        })
      })
    },
    [getList]
  )

  const handleImageView = useCallback((pic: string[]) => {
    ImageView.show(pic.map(item => ({ src: item, title: '' })))
  }, [])
  const columns1 = useRef([
    {
      title: '序号',
      key: 'index',
      render(text: string, record: IOutVerify, index: number) {
        return <span>{index + 1}</span>
      }
    },
    {
      title: '时间',
      key: 'createTime',
      dataIndex: 'createTime',
      render(text: string) {
        return moment(text).format('MM-DD HH:mm')
      }
    },
    {
      title: '提交人',
      key: 'user',
      dataIndex: 'out.user.realName'
    },
    {
      title: '明细',
      key: 'out',
      align: 'center' as 'center',
      render(text: string, record: IOutVerify) {
        return (
          <div className="detail">
            <div>{record.out.name}</div>
            <div>
              {`${record.out.price} * ${record.out.count} = ${record.out.sum}`}
            </div>
            {record.out.pic && (
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  handleImageView(record.out.pic)
                }}
              >
                查看图片
              </Button>
            )}
          </div>
        )
      }
    },
    {
      title: '操作',
      key: 'action',
      align: 'center' as 'center',
      render(text: string, record: IOutVerify) {
        return (
          <>
            <Button
              type="primary"
              onClick={() => {
                handleAcceptOut(record)
              }}
            >
              通过
            </Button>
            <Button
              type="danger"
              onClick={() => {
                handleRefuseOut(record)
              }}
            >
              拒绝
            </Button>
          </>
        )
      }
    }
  ])
  const columns2 = useRef([
    {
      title: '序号',
      key: 'index',
      render(text: string, record: IPaymentVerify, index: number) {
        return <span>{index + 1}</span>
      }
    },
    {
      title: '时间',
      key: 'createTime',
      dataIndex: 'createTime',
      render(text: string) {
        return moment(text).format('MM-DD HH:mm')
      }
    },
    {
      title: '提交人',
      key: 'user',
      dataIndex: 'payment.clerk.realName'
    },
    {
      title: '客户',
      key: 'customer',
      dataIndex: 'payment.customer.realName'
    },
    {
      title: '付款金额',
      key: 'out',
      align: 'center' as 'center',
      render(text: string, record: IPaymentVerify) {
        return (
          <div className="detail">
            <div>记账金额：{record.payment.money}</div>
            <div>付款金额：{record.payment.realMoney}</div>
            {record.payment.pic && (
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  handleImageView(record.payment.pic)
                }}
              >
                查看图片
              </Button>
            )}
          </div>
        )
      }
    },
    {
      title: '操作',
      key: 'action',
      align: 'center' as 'center',
      render(text: string, record: IPaymentVerify) {
        return (
          <>
            <Button
              type="primary"
              onClick={() => {
                handleAcceptPayment(record)
              }}
            >
              通过
            </Button>
            <Button
              type="danger"
              onClick={() => {
                handleRefusePayment(record)
              }}
            >
              拒绝
            </Button>
          </>
        )
      }
    }
  ])
  if (props.user.type === '客户') {
    return <div>欢迎登录，{props.user.realName}!</div>
  }
  return (
    <div className="index">
      <Card
        title="支出审核"
        className="income"
        extra={
          <Button
            type="primary"
            onClick={() => {
              setOutModalVisible(true)
            }}
          >
            新增支出
          </Button>
        }
        bodyStyle={{ height: 0, flex: 1 }}
      >
        <Table
          columns={columns1.current}
          dataSource={outList}
          size="small"
          rowKey="id"
          scroll={{ y: scrollY }}
          className="table"
        />
      </Card>
      <Card title="收入审核" className="out">
        <Table
          columns={columns2.current}
          dataSource={paymentList}
          scroll={{ y: scrollY }}
          size="small"
          rowKey="id"
        />
      </Card>
      {outModalVisible && (
        <OutModal
          title="新增支出"
          onClose={() => {
            setOutModalVisible(false)
          }}
          complete={() => {
            setOutModalVisible(false)
            message.success('新增成功')
          }}
        />
      )}
    </div>
  )
}

export default connect((state: { User: IUser }) => ({ user: state.User }))(
  Index
)
