import React from 'react'
import { Popover, Form, Select, Tag } from 'antd'
import CMYK from './../CMYK'
import { BianGuiList, BianGui } from './../../config'

const BianguiItem: React.FC<{
  bianguiItem: BianGui
  onChange: (item: BianGui) => void
}> = props => {
  const { bianguiItem, onChange } = props
  const hangBianguiChange = (name: string) => {
    const find = BianGuiList.find(x => x.name === name)
    if (find) {
      onChange(find)
    }
  }
  const handBianguiColorChange = (color: string) => {
    bianguiItem.color = color
    onChange(bianguiItem)
  }
  return (
    <Popover
      title="左边轨"
      trigger="click"
      content={
        <Form>
          <Form.Item label="选择边轨类型">
            <Select value={bianguiItem.name} onChange={hangBianguiChange}>
              {BianGuiList.map((item, index) => {
                return (
                  <Select.Option value={item.name} key={index}>
                    {item.name}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item label="选择颜色">
            <CMYK
              initial={bianguiItem.color}
              onChange={handBianguiColorChange}
            />
          </Form.Item>
        </Form>
      }
    >
      <Tag color="blue" className="handle-tag">
        {bianguiItem.name}
      </Tag>
    </Popover>
  )
}

export default BianguiItem
