import React, { PureComponent } from 'react'
import {
  Table,
  Divider,
  Form,
  Input,
  Col,
  Button,
  message,
  Modal,
  Row
} from 'antd'
import copy from 'copy-to-clipboard'
import DataModal from './modal'
import api from './../../api'
import styles from './style.module.less'
import IAddress from '../../models/address'
import PrintModal from './printModal'
const { confirm } = Modal

class AddressPage extends PureComponent {
  state = {
    loading: true,
    list: [],
    form: {
      pageSize: 15,
      page: 1,
      keyword: ''
    },
    total: 0,
    modalOpen: false,
    modalTitle: '',
    updateItem: {} as IAddress,
    printModalOpen: false,
    printItem: {} as IAddress,
    highLight: {
      name: '',
      phone: ''
    },
    detail: ''
  }
  columns = [
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      align: 'center' as 'center',
      render: text => {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html:
                text &&
                text.replace(
                  this.state.highLight.name,
                  `<span class="${styles.highLight}">${this.state.highLight.name}</span>`
                )
            }}
          />
        )
      }
    },
    {
      title: '省',
      dataIndex: 'province',
      key: 'province',
      align: 'center' as 'center'
    },
    {
      title: '市',
      dataIndex: 'city',
      key: 'city',
      align: 'center' as 'center'
    },
    {
      title: '区',
      dataIndex: 'area',
      key: 'area',
      align: 'center' as 'center'
    },
    {
      title: '详细地址',
      dataIndex: 'detail',
      key: 'detail',
      align: 'center' as 'center'
    },
    {
      title: '电话',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center' as 'center',
      render: text => {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html:
                text &&
                text.replace(
                  this.state.highLight.phone,
                  `<span class="${styles.highLight}">${this.state.highLight.phone}</span>`
                )
            }}
          />
        )
      }
    },
    {
      title: '操作',
      key: 'action',
      align: 'center' as 'center',
      width: 240,
      render: (text: any, record: IAddress) => (
        <span>
          <a
            onClick={() => {
              this.setState({
                printModalOpen: true,
                printItem: record
              })
            }}
          >
            打印快递单
          </a>
          <Divider type="vertical" />
          <a
            onClick={() => {
              copy(
                `${record.name},${record.phone},${record.province}${record.city}${record.area}${record.detail}`
              )
              message.success('复制成功')
            }}
          >
            复制
          </a>
          <Divider type="vertical" />
          <a
            onClick={() => {
              this.update(record)
            }}
          >
            编辑
          </a>
          <Divider type="vertical" />
          <a
            onClick={() => {
              this.delete(record)
            }}
          >
            删除
          </a>
        </span>
      )
    }
  ]
  update = (record: any) => {
    this.setState({
      updateItem: record,
      modalOpen: true
    })
  }
  delete = (record: any) => {
    confirm({
      title: '确定删除？',
      content: '确定删除该项目？',
      onOk: () => {
        api.address.delete(Number(record.id)).then(res => {
          if (res.status === 200) {
            message.success('删除成功')
            this.getList()
          }
        })
      },
      onCancel() {}
    })
  }
  getList() {
    this.setState({
      loading: true
    })
    api.address.getTableData(this.state.form).then(res => {
      if (res.status === 200) {
        this.setState({
          list: res.data.rows,
          total: res.data.count,
          loading: false
        })
      }
    })
  }
  createClick = () => {
    this.setState({
      updateItem: {},
      modalTitle: '新建',
      modalOpen: true
    })
  }
  searchClick = () => {
    this.refresh()
  }
  refresh() {
    this.getList()
  }
  componentDidMount() {
    this.getList()
  }
  detailSearch = () => {
    const { detail } = this.state
    api.address.analysis(detail).then(result => {
      const { data } = result
      this.setState({
        form: {
          page: this.state.form.page,
          pageSize: this.state.form.pageSize,
          keyword: `${data.name},${data.phone}`
        },
        highLight: {
          name: data.name,
          phone: data.phone
        }
      })
      this.refresh()
    })
  }
  render() {
    const { printModalOpen, printItem } = this.state
    return (
      <div className={styles.index}>
        <Button
          type="primary"
          loading={this.state.loading}
          icon="sync"
          className={styles.refreshBtn}
          onClick={() => {
            this.refresh()
          }}
        >
          刷新
        </Button>
        <Form
          className={styles.form}
          labelCol={{
            span: 6
          }}
          wrapperCol={{
            span: 16
          }}
          layout="horizontal"
        >
          <Row>
            <Col span={16}>
              <Form.Item label="搜索姓名">
                <Input
                  placeholder="请输入姓名"
                  onChange={e => {
                    this.setState({
                      form: {
                        page: this.state.form.page,
                        pageSize: this.state.form.pageSize,
                        keyword: e.target.value
                      },
                      highLight: {
                        name: e.target.value,
                        phone: e.target.value
                      }
                    })
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Button
                type="primary"
                htmlType="submit"
                icon="search"
                onClick={this.searchClick}
              >
                搜索
              </Button>
            </Col>
          </Row>

          <Row>
            <Col span={16}>
              <Form.Item label="搜索详细地址">
                <Input
                  placeholder="格式:姓名,电话,详细地址"
                  onChange={e => {
                    this.setState({
                      detail: e.target.value
                    })
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Button
                type="primary"
                htmlType="submit"
                icon="search"
                onClick={this.detailSearch}
              >
                搜索
              </Button>
            </Col>
          </Row>
        </Form>

        {/* <Col span={24} className={styles.actionBtn}></Col> */}
        <Col span={24} className={styles.addBtn}>
          <Button type="primary" icon="plus" onClick={this.createClick}>
            新建
          </Button>
        </Col>
        <Table
          bordered
          className={styles.table}
          columns={this.columns}
          dataSource={this.state.list}
          size="middle"
          rowKey="id"
          loading={this.state.loading}
          pagination={{
            position: 'bottom',
            pageSize: this.state.form.pageSize,
            total: this.state.total,
            pageSizeOptions: ['15', '30', '50'],
            current: this.state.form.page,
            showSizeChanger: true,
            onChange: (page, pageSize) => {
              const { form } = this.state
              form.page = page
              form.pageSize = pageSize ? pageSize : 1
              this.setState({
                form
              })
              this.getList()
            },
            showTotal: (total, range) => {
              return `共${total}条数据，当前显示${range}`
            },
            onShowSizeChange: (current, size) => {
              const { form } = this.state
              form.pageSize = size
              this.setState({
                form
              })
              this.getList()
            }
          }}
        />
        {this.state.modalOpen && (
          <DataModal
            title={this.state.modalTitle}
            onClose={() => {
              this.setState({ modalOpen: false })
            }}
            complete={() => {
              this.setState({ modalOpen: false })
              this.getList()
            }}
            updateItem={this.state.updateItem}
          />
        )}
        {printModalOpen && (
          <PrintModal
            address={printItem}
            onClose={() => {
              this.setState({ printModalOpen: false })
            }}
            complete={() => {
              this.setState({ printModalOpen: false })
            }}
          />
        )}
      </div>
    )
  }
}

export default AddressPage
