import React, { PureComponent } from 'react'
import { Form as AntdForm, Input, Select, Switch } from 'antd'
import { FormComponentProps } from 'antd/es/form'
import api from './../../api'
import styles from './style.module.less'
import IMenu from './../../models/menu'

interface IReciveProps extends FormComponentProps {
  onRef: (form: Form) => void
  complete: (menu: IMenu) => void
  validateFail: () => void
  data: IMenu
}

interface IState {
  parentList: Array<{
    id: number
    name: string
  }>
}

class Form extends PureComponent<IReciveProps, any> {
  constructor(props: IReciveProps) {
    super(props)
    this.state = {
      parentList: []
    }
  }
  state: IState
  submit = () => {
    this.props.form.validateFields((err: any, values: any) => {
      if (err) {
        this.props.validateFail()
      } else {
        api.menu
          .createOrUpdate(values)
          .then(res => {
            if (res.status === 200) {
              this.props.complete(res.data)
            }
          })
          .catch(() => {
            this.props.validateFail()
          })
      }
    })
  }
  componentDidMount() {
    this.props.onRef(this)
    this.getParentList()
  }
  getParentList() {
    api.meta.getParentMenuList().then(res => {
      if (res.status === 200) {
        this.setState({
          parentList: res.data
        })
      }
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <AntdForm
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        labelAlign="right"
        className={styles.form}
      >
        <AntdForm.Item label="" className={styles.displayNone}>
          {getFieldDecorator('id', {
            rules: [{ required: false, message: 'id' }],
            initialValue: this.props.data['id']
          })(<Input placeholder="" />)}
        </AntdForm.Item>
        <AntdForm.Item label="名称" className={styles.displayNormal}>
          {getFieldDecorator('name', {
            rules: [{ required: true, message: 'name' }],
            initialValue: this.props.data['name']
          })(<Input placeholder="名称" />)}
        </AntdForm.Item>
        <AntdForm.Item label="path" className={styles.displayNormal}>
          {getFieldDecorator('path', {
            rules: [{ required: false, message: 'path' }],
            initialValue: this.props.data['path']
          })(<Input placeholder="path" />)}
        </AntdForm.Item>
        <AntdForm.Item label="级别" className={styles.displayNormal}>
          {getFieldDecorator('level', {
            rules: [{ required: true, message: 'level' }],
            initialValue: this.props.data['level']
          })(
            <Select placeholder="级别">
              <Select.Option value="一级">一级</Select.Option>
              <Select.Option value="二级">二级</Select.Option>
            </Select>
          )}
        </AntdForm.Item>
        <AntdForm.Item label="父级" className={styles.displayNormal}>
          {getFieldDecorator('parentId', {
            rules: [{ required: false, message: 'parentId' }],
            initialValue: this.props.data['parentId']
          })(
            <Select placeholder="">
              {this.state.parentList.map((item, index) => {
                return (
                  <Select.Option value={item.id} key={index}>
                    {item.name}
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </AntdForm.Item>
        <AntdForm.Item label="是否展示" className={styles.displayNormal}>
          {getFieldDecorator('isDisplay', {
            rules: [{ required: false, message: '是否展示' }],
            initialValue: this.props.data['isDisplay'],
            valuePropName: 'checked'
          })(<Switch />)}
        </AntdForm.Item>
        <AntdForm.Item label="排序" className={styles.displayNormal}>
          {getFieldDecorator('sort', {
            rules: [{ required: false, message: 'sort' }],
            initialValue: this.props.data['sort']
          })(<Input placeholder="排序" />)}
        </AntdForm.Item>
        <AntdForm.Item label="图标" className={styles.displayNormal}>
          {getFieldDecorator('icon', {
            rules: [{ required: false, message: '图标' }],
            initialValue: this.props.data['icon']
          })(<Input placeholder="图标" />)}
        </AntdForm.Item>
      </AntdForm>
    )
  }
}

export default AntdForm.create<IReciveProps>()(Form)
