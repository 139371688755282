import React, { useState } from 'react'
import {
  Popover,
  Tag,
  Form,
  Select,
  Button,
  InputNumber,
  Popconfirm
} from 'antd'
import parseColor from 'parse-color'

import {
  MianBanList,
  CaoBanList,
  MianBan,
  CaoBan,
  BianGui,
  ZhongBianGui
} from './../../config'
import './index.less'
import MianbanItem from './components/MianbanItem'

export interface IColumnProps {
  firstChild: CaoBan | MianBan
  children: Array<CaoBan | MianBan | ZhongBianGui>
}

const ColumnItem: React.FC<{
  item: IColumnProps
  internalScale: number
  bianguiLeft: BianGui
  bianguiRight: BianGui
  onChange: (item: IColumnProps) => void
  onCopy: (arr: IColumnProps[]) => void
  column: IColumnProps[]
  onDelete: () => void
  internalWidth: number
}> = props => {
  const [copyCount, setCopyCount] = useState(1)
  const {
    item,
    internalScale,
    bianguiLeft,
    onChange,
    bianguiRight,
    onCopy,
    onDelete,
    internalWidth
  } = props
  const handleItemChange = (name: string) => {
    const find = [...MianBanList, ...CaoBanList].find(x => x.name === name)
    if (find) {
      item.firstChild = find
      item.firstChild.height = find.height
      item.firstChild.width = internalWidth
      item.children = [item.firstChild]
    }
    onChange(item)
  }
  const handleCopy = () => {
    const arr = new Array(copyCount).fill(1).map(a => {
      return { ...item }
    })
    onCopy(arr)
    setCopyCount(1)
  }
  const handleAdd = (
    index: number,
    mianbanItem: MianBan | CaoBan | ZhongBianGui,
    changed: MianBan | CaoBan | ZhongBianGui,
    add: MianBan | CaoBan | ZhongBianGui
  ) => {
    mianbanItem = changed
    item.children.splice(index + 1, 0, { ...add })
    onChange(item)
  }
  const handleChange = (
    current: CaoBan | MianBan | ZhongBianGui,
    changed: CaoBan | MianBan | ZhongBianGui
  ) => {
    current = { ...changed }
    onChange({ ...item })
  }
  const handleDelete = () => {
    onDelete()
  }
  return (
    <div
      className="column-item"
      style={{
        height: `${item.firstChild.height}px`,
        width: `${internalWidth * internalScale}px`,
        lineHeight: `${item.firstChild.height}px`
      }}
    >
      <div className="mianban-wrapper">
        {item.children.map((mianbanItem, mianbanIndex) => {
          return (
            <MianbanItem
              mianbanItem={mianbanItem}
              key={mianbanIndex}
              internalScale={internalScale}
              onAdd={(changed, add) => {
                handleAdd(mianbanIndex, mianbanItem, changed, add)
              }}
              onChange={changed => {
                handleChange(mianbanItem, changed)
              }}
            />
          )
        })}
      </div>
      <div
        className="tag"
        style={{
          left: `${-210 - bianguiLeft.width}px`
        }}
      >
        {item.children.length > 1 ? (
          <Tag className="complex handle-tag" color="blue">
            <div>{item.firstChild.height}</div>
            <div>{item.children.map(x => x.width).join(' + ')}</div>
          </Tag>
        ) : (
          <Popover
            title="设置面板"
            trigger="click"
            content={
              <Form>
                <Form.Item label="选择面板类型">
                  <Select
                    value={item.firstChild.name}
                    onChange={handleItemChange}
                    disabled={item.children.length > 1}
                  >
                    {[...MianBanList, ...CaoBanList].map((x, selectIndex) => {
                      return (
                        <Select.Option value={x.name} key={selectIndex}>
                          {x.name}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Form>
            }
          >
            <Tag color="magenta" className="handle-tag">
              {item.firstChild.name}
            </Tag>
          </Popover>
        )}
      </div>
      <div
        className="copy-btns"
        style={{
          right: `${-210 - bianguiRight.width}px`
        }}
      >
        <Popover
          title="复制数量"
          trigger="click"
          content={
            <Form>
              <Form.Item label="复制数量">
                <InputNumber value={copyCount} onChange={setCopyCount} />
              </Form.Item>
              <Form.Item>
                <Button size="small" onClick={handleCopy}>
                  确定复制
                </Button>
              </Form.Item>
            </Form>
          }
        >
          <Button type="primary" size="small">
            复制
          </Button>
        </Popover>
        <Popconfirm title={`确认删除？`} onConfirm={handleDelete}>
          <Button type="primary" size="small">
            删除
          </Button>
        </Popconfirm>
      </div>
    </div>
  )
}

export default ColumnItem
